import React, { memo, useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion';
import MqttService from '../../../core/services/MqttService';
import { Avatar, AvatarGroup } from '@mui/material';
import { colorPalletDark, colorPalletLight } from '../../../styles/chatColorPallet';
import { formatDateToAmPm } from '../../../utils/DateFormating';
import ProgressCircle from '../../../utils/ProgressCircle';
import { darkModeState } from '../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { createMessagingMQTT } from '../../../core/services/MqttMessaging';
import toast from 'react-hot-toast';
import { removeMessage } from '../../../utils/MessageDataSorting';
import { formatFileSize } from '../../../utils/FileFormating';
import SenderCompressedImageInMessageComponent from './SenderCompressedImageInMessageComponent';
import SenderEmojisOnlyMessageComponent from './SenderEmojisOnlyMessageComponent';
import SenderMessageOnlyComponent from './SenderMessageOnlyComponent';
import SenderFileMessageComponent from './SenderFileMessageComponent';
import SenderFileIsUploading from './SenderFileIsUploading';
import SenderVoiceMessageComponent from './SenderVoiceMessageComponent';

function SenderMessageComponent({message, userInfo, contextMenu, styles, participants, showImage, onRightClick, isGroup, showName, scrollToMessage, emojiTextSize, messages, setMessages, handleDownloadFile, isLoadingFile, updateEmoji, handleUserColor, handleUserBGColor,shouldRoundTopCorner,shouldRoundBottomCorner}) {
    const isDark = useRecoilValue(darkModeState);
    const [messageStatus, setMessageStatus] = useState('loading'); // Initial status is loading

    useEffect(() => {
        

        if(message && !message.file_uuid && message.message_is_only_emoji_count && message.message_is_only_emoji_count > 0){
            setMessageStatus('only_emojis')
        }else if(message && message.isFileUploading ){
            setMessageStatus('message_is_file_uploading')
        }else if(message && message.file_uuid && message.file_is_voice ){
            setMessageStatus('message_is_voice')
        }else if(message && message.file_uuid && !message.isFileUploading  && message.file_is_compressed){
            setMessageStatus('message_is_uncompressed_image')
        }else if(message && message.file_uuid && !message.isFileUploading  && !message.file_is_compressed){
            setMessageStatus('message_is_file')
        }else if(message && !message.file_uuid && !message.isFileUploading && message.message_text && message.message_text.length > 0){
            setMessageStatus('only_message')
        }

    },[message])




    const handleTailCss = (emojiCount) => {
        if(emojiCount && emojiCount > 0){
            return{
                color:'transparent'
            }
        }else {
            return{
                color: isDark ? '#075985':'#0284c7',
            }

        }
    }

    const renderMessage = () => {
        switch (messageStatus) {
          case 'loading':
            return <div/>;
          case 'message_is_uncompressed_image':
            return <SenderCompressedImageInMessageComponent message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} handleDownloadFile={handleDownloadFile}/>;
          case 'message_is_file':
            return <SenderFileMessageComponent message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage} showName={showName} handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} handleUserColor={handleUserColor} handleDownloadFile={handleDownloadFile} isLoadingFile={isLoadingFile} />;
          case 'message_is_voice':
            return <SenderVoiceMessageComponent message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage} showName={showName} handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} handleUserColor={handleUserColor} handleDownloadFile={handleDownloadFile} isLoadingFile={isLoadingFile} />;
          case 'only_message':
            return <SenderMessageOnlyComponent message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage} showName={showName} handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} handleUserColor={handleUserColor} shouldRoundTopCorner={shouldRoundTopCorner} shouldRoundBottomCorner={shouldRoundBottomCorner}/>;
          case 'only_emojis':
            return <SenderEmojisOnlyMessageComponent message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} emojiTextSize={emojiTextSize}/>;
          case 'message_is_file_uploading':
            return <SenderFileIsUploading messages={messages} setMessages={setMessages} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} emojiTextSize={emojiTextSize}/>;
          default:
            return <div />;
        }
    }

  return (
    <div 
            style={styles}
            className={`w-full flex items-end relative justify-end
            ${contextMenu && contextMenu.message && contextMenu.message.message_uuid == message.message_uuid && 'bg-slate-500/20 rounded-xl'}
            `} 
            onContextMenu={(e) => onRightClick(e, message,message.user_username, userInfo.user_username )}
        >
            {renderMessage()}
        </div>
  )
}

export default SenderMessageComponent