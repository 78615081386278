import { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { getData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';



const EmojiPicker = ({ onEmojiSelect, allEmojis, closeEverything,  setCloseEverything}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [emojiSearchText, setEmojiSearchText] = useState('');
  const [filteredEmojis, setFilteredEmojis] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [loadingEmojis, setLoadingEmojis] = useState(false);
  const isDark = useRecoilValue(darkModeState);
  // const emojiDivRef = useRef(null);

  useEffect(()=> {
    if(closeEverything){
      setCloseEverything(false)
      setShowPicker(false)
    }
  }, [closeEverything])
  
  const handleClickOutside = (event) => {
    if (!event.target.closest('.emoji-picker')) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showPicker]);

  const handleEmojiClick = (emoji) => {
    // console.log(emoji)
    // const emojiChar = String.fromCodePoint(parseInt("0x" + emoji.u, 16));
    onEmojiSelect(emoji.emoji_emoji); // Call parent function to insert emoji
    
  };



  const handleInputChange = (e) => {
    setEmojiSearchText(e.target.value)
    setIsTyping(true); // User started typing
    setLoadingEmojis(true)
    if(e.target.value ===  0){
      setLoadingEmojis(false)
    }
  };

  const debounceCommand = (ms) => {
    let timeout;
    return (text) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsTyping(false); // User stopped typing after delay
        // Run your command here
        let searchText = text.toLowerCase();
        if(searchText && searchText.length > 0){

          getData(apiLink + '/v1/emojis?q=' + searchText)
          .then(async response => {
            console.log('response', response)
            const data = await response.json();
            if(response.ok ){
              if(data && data.data){
                setFilteredEmojis(data.data)
                setLoadingEmojis(false)
              }
            }
          })
        }else{
          setLoadingEmojis(false)
        }
      }, ms);
    };
  };

  const delayedCommand = debounceCommand(300); // Debounce for 500ms

  useEffect(() => {
    if (isTyping) {
      delayedCommand(emojiSearchText); // Trigger debounced command with current input
    }
  }, [isTyping, emojiSearchText]);

  const handleMouseLeave = () => {
    // User mouse leaves the popup (excluding child elements)
    setShowPicker(false);
    
  };

  const mouseEntering = () => {
    // console.log('MouseEntering')
    setShowPicker(true)
  }

  const emojiButtonCSS = (show, darkMode) => {
    if(show && darkMode){
      return {
        color:'#2563eb',
      }
    }else if(!show && darkMode){
      return {
        color:'#9ca3af',
      }
    }else if(!show && !darkMode){
      return {
        color:'#4b5563',
      }
    }else if(show && !darkMode){
      return {
        color:'#3b82f6',
      }
    }
  }
  


  return (
    <AnimatePresence mode='wait'>
        <div
        className="emoji-picker relative flex items-center scrollbar-hide"
        onMouseLeave={handleMouseLeave}
        >
        <button 
            className={` items-center z-[999] `}
            // onClick={() => setShowPicker(!showPicker)}
            onMouseEnter={mouseEntering}
            style={emojiButtonCSS(showPicker, isDark)}
            
        >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-7 h-7">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
                </svg>
                {/* <div className='h-5 w-6 bg-blue-500'/> */}
        </button>
        {showPicker && (
            <motion.div
                key='emojisDiv'
                // ref={emojiDivRef}
                // onMouseLeave={handleMouseLeave}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, scale: [1.05,  1] }}
                exit={{ opacity: 0 }}
                className={`absolute right-0 bottom-4 pl-8 pt-8 pb-2 pr-2 scrollbar-hide `}
            >
              <div 
                className={`relative w-[300px] h-[400px] scrollbar-hide 
                  ${isDark ? 'bg-zinc-900/90 shadow-xs-invert':'shadow-iotflows-lg bg-white/90'}
                 backdrop-blur-lg rounded-xl flex flex-col pl-5 pb-4 pt-4 pr-1 `}
                >
                  <div className='absolute top-1 left-0 right-0 flex rounded-full w-full h-8 items-center px-2 '>
                    <div className='pl-1 flex items-center pointer-events-none'>
                        <svg className={`h-4 w-4 ${isDark ? 'text-gray-300':'text-gray-500'} `}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </div>
                    <input className={`bg-transparent  border-0 focus:ring-[0px]
                              ${isDark ? 'text-gray-300 placeholder:text-gray-400':'text-gray-600 placeholder:text-gray-500'} 
                              block w-full text-sm rounded-lg text-ellipsis py-1`}
                        value={emojiSearchText} 
                        onChange={handleInputChange} 
                        type="text" 
                        placeholder="Search Emojis..."
                    />
                    
                    <div className={`${loadingEmojis ? 'opacity-100': 'opacity-0'} `}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`animate-spin h-5 w-5 ${isDark ? 'text-gray-300':'text-gray-500'}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                      </svg>
                    </div>
                  </div>
                  <div className='mt-7 overflow-y-scroll scrollbar-hide'>
                    {emojiSearchText.length > 0 && Object.keys(filteredEmojis).length > 0 &&
                      <div
                      key='filteredList'
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }} className='flex flex-wrap pb-2 scrollbar-hide'>
                        {filteredEmojis.map(emoji => (
                          <span key={emoji.emoji_codepoints} onClick={() => handleEmojiClick(emoji)} className='text-2xl p-1 cursor-pointer'>
                              {/* {String.fromCodePoint(parseInt("0x" + emoji.u, 16))} */}
                              <div className={`${emoji.emoji_codepoints == '2764 FE0F' && 'text-red-500'}`}>{emoji.emoji_emoji}</div>
                          </span>
                        ))}
                      </div>
                    }
                  {emojiSearchText.length === 0 && Object.keys(allEmojis).map((emojiSection) => (
                      <div key={emojiSection} className='scrollbar-hide'>
                          <h6 className={`${isDark ? 'text-gray-300':'text-gray-600'} text-sm pb-1 pt-2`}>
                              {emojiSection}
                          </h6>
                          <div className='flex flex-wrap scrollbar-hide'>
                          {allEmojis[emojiSection].map(emoji => (
                              <span key={emoji.emoji_codepoints} onClick={() => handleEmojiClick(emoji)} className='text-2xl p-1 cursor-pointer'>
                                  <div className={`${emoji.emoji_codepoints == '2764 FE0F' && 'text-red-500'}`}>{emoji.emoji_emoji}</div>
                              </span>
                          ))}
                          </div>
                      </div>
                      
                  ))}

                  </div>
              </div>
            </motion.div>
        )}
        </div>
    
  </AnimatePresence>
  );
};

export default EmojiPicker;