import React, { memo, useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion';
import MqttService from '../../../core/services/MqttService';
import { Avatar, AvatarGroup } from '@mui/material';
import { colorPalletDark, colorPalletLight } from '../../../styles/chatColorPallet';
import { formatDateToAmPm } from '../../../utils/DateFormating';
import ProgressCircle from '../../../utils/ProgressCircle';
import { darkModeState } from '../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { createMessagingMQTT } from '../../../core/services/MqttMessaging';
import toast from 'react-hot-toast';
import { removeMessage } from '../../../utils/MessageDataSorting';
import ReceiverCompressedImageInMessageComponent from './ReceiverCompressedImageInMessageComponent';
import ReceiverEmojisOnlyMessageComponent from './ReceiverEmojisOnlyMessageComponent';
import ReceiverMessageOnlyComponent from './ReceiverMessageOnlyComponent';
import ReceiverFileMessageComponent from './ReceiverFileMessageComponent';
import ReceiverVoiceMessageComponent from './ReceiverVoiceMessageComponent';

function ReceiverMessageComponent({message, userInfo, contextMenu, styles, participants, showImage, onRightClick, isGroup, showName, scrollToMessage, emojiTextSize, messages, setMessages, handleDownloadFile, isLoadingFile, updateEmoji, handleUserColor, handleUserBGColor, shouldRoundTopCorner,shouldRoundBottomCorner}) {
  const isDark = useRecoilValue(darkModeState);
  const [messageStatus, setMessageStatus] = useState('loading'); // Initial status is loading

  useEffect(() => {
    if(message && !message.file_uuid && message.message_is_only_emoji_count && message.message_is_only_emoji_count > 0){
      setMessageStatus('only_emojis')
    }else if(message && message.file_uuid && message.file_is_compressed){
        setMessageStatus('message_is_uncompressed_image')
    }else if(message && message.file_uuid && message.file_is_voice ){
      setMessageStatus('message_is_voice')
    }else if(message && message.file_uuid && !message.isFileUploading  && !message.file_is_compressed){
      setMessageStatus('message_is_file')
    }else if(message && !message.file_uuid && message.message_text && message.message_text.length > 0){
        setMessageStatus('only_message')
    }

  },[message])


const handleTailCss = (emojiCount) => {
    if(emojiCount && emojiCount > 0){
        return{
            color:'transparent'
        }
    }else {
      return{
          color: isDark ? '#1e293b':'#f1f5f9',
      }
    }
}



const renderMessage = () => {
    switch (messageStatus) {
      case 'loading':
        return <div/>;
      case 'message_is_uncompressed_image':
        return <ReceiverCompressedImageInMessageComponent message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} handleUserBGColor={handleUserBGColor} handleUserColor={handleUserColor} showName={showName} handleDownloadFile={handleDownloadFile}/>;
      case 'message_is_file':
        return <ReceiverFileMessageComponent message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} handleUserBGColor={handleUserBGColor} handleUserColor={handleUserColor} showName={showName} handleDownloadFile={handleDownloadFile} isLoadingFile={isLoadingFile} />;
      case 'message_is_voice':
        return <ReceiverVoiceMessageComponent message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} handleUserBGColor={handleUserBGColor} handleUserColor={handleUserColor} showName={showName} handleDownloadFile={handleDownloadFile} isLoadingFile={isLoadingFile} />;
      case 'only_message':
        return <ReceiverMessageOnlyComponent message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} handleUserBGColor={handleUserBGColor} handleUserColor={handleUserColor} showName={showName} shouldRoundTopCorner={shouldRoundTopCorner} shouldRoundBottomCorner={shouldRoundBottomCorner}/>;
      case 'only_emojis':
        return <ReceiverEmojisOnlyMessageComponent message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} emojiTextSize={emojiTextSize} handleUserBGColor={handleUserBGColor} />;
      default:
        return <div />;
    }
  };

return (
<div 
        style={styles}
        className={`w-full flex items-end relative justify-start
          ${contextMenu && contextMenu.message && contextMenu.message.message_uuid == message.message_uuid && 'bg-slate-500/20 rounded-xl'}
        `} 
        onContextMenu={(e) => onRightClick(e, message,message.user_username, userInfo.user_username )}
    >
        {renderMessage()}
    </div>
)
}

export default ReceiverMessageComponent