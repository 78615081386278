import { SearchIcon } from '@heroicons/react/outline'
import { CogIcon, MenuIcon } from '@heroicons/react/solid'
import Switch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {darkModeState} from '../atoms/darkModeState'
import {globalSelectedOrganization} from '../atoms/orgStates'
import OrganizationDropdownList from './OrganizationDropdownList';
import ProfileDropdown from './ProfileDropdown';
import NotificationDropdown from './NotificationDropdown';
import MemberRoleButton from './MemberRoleButton';
import { getData, putData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import MembersHeader from '../MyMembers/MembersHeader';
import { sendUrlLinkModalState } from "../atoms/modalStates";
import { motion } from "framer-motion";
import OptimizeImage from './OptimizeImage';
import CustomerFeedback from './CustomerFeedback';


function Header({orgList, user, updateOrganizationsList}) {
    const [isDark, setIsDark] = useRecoilState(darkModeState)
    const [showGODview, setShowGODview] = useState(false)
    const navigate = useNavigate()
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [isSendUrlLinkModalOpen, setIsSendUrlLinkModalOpen] = useRecoilState(sendUrlLinkModalState)
    const [mainSearchText, setMainSearchText] = useState('')
    const [searchData, setSearchData] = useState([])
    const [isMobile, setIsMobile] = useState(false)
    const [removeMobileSearch, setRemoveMobileSearch] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation();


    useEffect(() => {
      let currentIsMobile = searchParams.get('platform')
      let curretPath = location.pathname.split('/')[2]
      if(curretPath === 'members' || curretPath === 'production' || curretPath === 'settings'){
        setRemoveMobileSearch(true)
      }
      if(currentIsMobile === 'mobile'){
        setIsMobile(true)
      }else{
        setIsMobile(false)
      }
    },[])

    useEffect(() => {
      // Determining if user had GOD view access
      getData(apiLink + '/v1/users/iotflows')
      .then(async response => {
        if(response && response.ok ){
          const data = await response.json();
          if(data && data.data.length > 0 && data.data[0].iotflows_member_role_id){
            setShowGODview(true)
          } 
        }
      });

    }, [])

    useEffect(() => {
      if(selectedOrganization && mainSearchText && mainSearchText.length > 0){
        let query = `?q=${mainSearchText}`
        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/search' + query)
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data){
              console.log('search data', data.data)
              setSearchData(data.data)
            } 
          }
        });

      }

    }, [mainSearchText])

    const handleChangeDarkMode = (e) => {
      setIsDark(e.target.checked)
      putData(apiLink + '/v1/users/darkmode', 
        {'user_prefers_dark_mode': e.target.checked})
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              // setLoading(false)
              // getPartList()
              // toast.success('Part has been edited')
              // closeModal()
            }
            else{
              try{
                const data = await response.json();
                // toast.error(data.message)
              }catch (e){
                // toast.error('Something went wrong')
              }
            }
          })

    }

    const handleOpenSendLinkModal = () => {
      setIsSendUrlLinkModalOpen(true)
    }

    const removeSection = (section) => {
      if(section){
        let sections = section.split('/')
        if(sections){
          sections.shift(0)
          if(sections){
            sections.shift(0)
          }
          let string = sections.join(' > ')
          return string
        }
      }
    }

    const handleChangeUrl = (urlPath) => {
      setMainSearchText('')
      navigate(`${urlPath}`)

    }

    
    return (
      <>
      {isMobile && !removeMobileSearch &&
        <div className='flex mx-2 mt-2 grow'>
          <div className='relative rounded-full mx-1 w-full'>
            <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
              <SearchIcon className={`h-4 w-4  ${isDark? 'text-gray-400' : 'text-gray-500'}`} />
            </div>
            <input className={`  ${isDark?'bg-gray-600 focus:bg-gray-700 hover:bg-gray-700 text-gray-300 border-gray-500  focus:ring-gray-500 focus:border-gray-500 placeholder-gray-400':'bg-gray-50 text-gray-600 border-gray-300  focus:ring-gray-300 focus:border-gray-300'}
                              block w-full pl-10 sm:text-xs rounded-full text-ellipsis py-1 
                              `} 
                value={mainSearchText} 
                onChange={(e)=> setMainSearchText(e.target.value)} 
                type="text" 
                placeholder="Search..."
                
            />
            {mainSearchText && mainSearchText.length > 0 && Object.keys(searchData).length > 0 && 
              <div className={`absolute w-full top-8 min-w-[300px] z-50 shadow-iotflows-lg rounded-lg max-h-[400px] overflow-auto scrollbar-hide`}>
                {searchData.map(category => (
                  <div key={category.category} className={`bg-white dark:bg-slate-900 flex flex-col`}>
                    <h6 className={`flex text-gray-500 dark:text-gray-400 text-sm px-2 py-1`} >
                      {category.category}
                    </h6>
                    {category.data && Object.keys(category.data).length > 0 &&
                      category.data.map((categoryData, index) => (
                        <div key={index} className={` dark:hover:bg-slate-700 hover:bg-slate-200 flex cursor-pointer p-2 rounded items-center`} onClick={() => handleChangeUrl(categoryData.url_path)} >
                          <div className='items-center'>
                              <OptimizeImage size='medium' source={categoryData.image_url} alt={categoryData.name} />
                          </div>
                          <div className='flex flex-col ml-2 justify-between overflow-hidden' >
                              <div className={`text-gray-600 dark:text-gray-300 text-base font-medium `} >
                                {categoryData.name}
                              </div>
                              <div className={` text-gray-500 dark:text-gray-400 text-xs font-light italic truncate `} >
                              {`${categoryData.description}`}
                                {/* {removeSection(categoryData.url_path)} */}
                              </div>
                          </div>
                      </div>
                      ))
                    }
                  </div>
                ))}
                </div>
            }
            {mainSearchText && mainSearchText.length > 0 && Object.keys(searchData).length === 0 &&
              <div className={`absolute w-full top-8 min-w-[400px] z-50 shadow-iotflows-lg rounded-lg max-h-[400px] overflow-auto scrollbar-hide`}>
                <div className={`${isDark?'bg-slate-900 ':'bg-white '} p-10 flex flex-col justify-center itemse-center`}>
                  <div className='mx-auto'>
                    <SearchIcon className='h-10 w-10 text-gray-500 dark:text-gray-400 ' />
                  </div>
                  <p className='text-gray-600 dark:text-gray-400 mx-auto'> 
                    No search results found
                  </p>
                  <p className='text-gray-400 dark:text-gray-500 mx-auto'> 
                    Please try again with a different search query
                  </p>

                </div>
                </div>
            }
          </div>
        </div>
      }
        <div className={`${isMobile && 'hidden'} shadow-sm border-b bg-slate-100 dark:bg-slate-900 dark:border-slate-700 sticky top-0 z-50`}>
          <header className="bg-slate-100 grid grid-cols-10 xl:grid-cols-3 gap-0 md:gap-4 dark:bg-slate-900 items-center py-0.5">
            <div className='flex col-span-1 sm:col-span-2 xl:col-span-1 items-center ml-2 sm:ml-4 ' >
              <OrganizationDropdownList orgList={orgList} showGODview={showGODview} /> 
            </div>

              {/* Search Input field */}
              <div className=' hidden sm:flex sm:col-span-2 xl:col-span-1 mx-2 grow'>
                <div className='relative rounded-full mx-1 w-full'>
                  <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                  <svg className={`h-4 w-4 ${isDark? 'text-gray-300' : 'text-gray-500'}`}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
                  </div>
                  <input className={` ${isDark? 'hover:bg-gray-700 focus:bg-gray-700 bg-gray-600 focus:ring-gray-500 text-gray-300 placeholder:text-gray-400' : 'bg-gray-50 text-gray-600 border-gray-300 focus:ring-gray-300 focus:border-gray-300 placeholder:text-gray-500'}  
                        block w-full pl-10 sm:text-xs rounded-full text-ellipsis py-1 `}
                      value={mainSearchText} 
                      onChange={(e)=> setMainSearchText(e.target.value)} 
                      type="text" 
                      placeholder="Search..."
                      
                  />
                  {mainSearchText && mainSearchText.length > 0 && Object.keys(searchData).length > 0 && 
                    <div className={`absolute w-full top-8 min-w-[400px] z-50 shadow-iotflows-lg rounded-lg max-h-[400px] overflow-auto scrollbar-hide`}>
                      {searchData.map(category => (
                        <div key={category.category} className={`bg-white dark:bg-slate-900 flex flex-col`}>
                          <h6 className={`flex text-gray-500 dark:text-gray-400 text-sm px-2 py-1`} >
                            {category.category}
                          </h6>
                          {category.data && Object.keys(category.data).length > 0 &&
                            category.data.map((categoryData, index) => (
                              <div key={index} className={` dark:hover:bg-slate-700 hover:bg-slate-200 flex cursor-pointer p-2 rounded items-center`} onClick={() => handleChangeUrl(categoryData.url_path)} >
                                 <div className='items-center'>
                                     <OptimizeImage size='medium' source={categoryData.image_url} alt={categoryData.name} />
                                 </div>
                                 <div className='flex flex-col ml-2 justify-between overflow-hidden' >
                                     <div className={`text-gray-600 dark:text-gray-300 text-base font-medium `} >
                                      {categoryData.name}
                                     </div>
                                     <div className={` text-gray-500 dark:text-gray-400 text-xs font-light italic truncate `} >
                                      {`${categoryData.description}`}
                                      {/* {removeSection(categoryData.url_path)} */}
                                     </div>
                                 </div>
                             </div>
                            ))
                          }
                        </div>
                      ))}
                      </div>
                  }
                  {mainSearchText && mainSearchText.length > 0 && Object.keys(searchData).length === 0 &&
                    <div className={`absolute w-full top-8 min-w-[400px] z-50 shadow-iotflows-lg rounded-lg max-h-[400px] overflow-auto scrollbar-hide`}>
                      <div className={`${isDark?'bg-slate-900 ':'bg-white '} p-10 flex flex-col justify-center itemse-center`}>
                        <div className='mx-auto'>
                          <SearchIcon className='h-10 w-10 text-gray-500 dark:text-gray-400 ' />
                        </div>
                        <p className='text-gray-600 dark:text-gray-400 mx-auto'> 
                          No search results found
                        </p>
                        <p className='text-gray-400 dark:text-gray-500 mx-auto'> 
                          Please try again with a different search query
                        </p>

                      </div>
                      </div>
                  }
                </div>
              </div>

              {/* right side items */}
              <div className="col-span-9 sm:col-span-6 xl:col-span-1 ml-auto flex items-center justify-end space-x-4 mr-4">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }} 
                    onClick={handleOpenSendLinkModal}
                    className='rounded-full text-gray-600 dark:text-gray-100'
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125z" />
                    </svg>
                </motion.button>
                {selectedOrganization && <MembersHeader selectedOrganization={selectedOrganization}/>}
                {showGODview && 
                <MemberRoleButton updateOrganizationsList={updateOrganizationsList} />
                }
                  <div className='hidden md:inline-flex'>
                    <Switch
                      size="small"
                      checked={isDark}
                      // onChange={(e) => setIsDark(e.target.checked)}
                      onChange={handleChangeDarkMode}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </div>
                  {/* <div className='flex items-center group'>
                    <QuestionMarkCircleIcon className="navBtn group-hover:scale-110"/>
                    <span className='text-sm font-semibold pl-1 hidden md:inline-flex cursor-pointer group-hover:scale-110 transition-all duration-150 ease-out text-gray-700 dark:text-gray-100'>Help</span>
                  </div> */}
                  {/* <QuestionMarkCircleIcon className="navBtn"/> */}
                  {/* <NotificationDropdown /> */}
                  <CustomerFeedback />
                  {selectedOrganization && 
                    <CogIcon className="navBtn " onClick={() => navigate(`/${selectedOrganization}/settings/organization`)}/>
                  }
                  {/* <MenuIcon className="navBtnMobile "/> */}
                  <ProfileDropdown user={user} /> 

              </div>
          </header>
        </div>
      </>
    )

}

export default Header
