import React from 'react'
import InteractionsComponent from '../utils/InteractionsComponent'
import { formatDateToAmPm } from '../../../utils/DateFormating'
import { Avatar } from '@mui/material'

function ReceiverMessageOnlyComponent({message, scrollToMessage, isGroup,showName, showImage, handleTailCss, participants, userInfo, updateEmoji, handleUserColor, handleUserBGColor, shouldRoundTopCorner,shouldRoundBottomCorner}) {
    const cornersCSS = (topCorner, bottomCorner) => {
        return {
            borderTopLeftRadius: topCorner? '0.25rem': '1rem',
            borderBottomLeftRadius: bottomCorner ? '0.25rem':'1rem',
            borderTopRightRadius: '1rem',
            borderBottomRightRadius: '1rem',
        }
    }
  return (
    <>
        <div 
            className={` pl-4 pr-4 py-1.5 rounded-2xl  max-w-[500px] flex flex-col ml-2 order-2 bg-slate-100 dark:bg-slate-800 text-gray-600 dark:text-gray-300`}
            style={cornersCSS(shouldRoundTopCorner,shouldRoundBottomCorner)}
        >
            {isGroup && showName &&
                <p 
                    style={handleUserColor(message.user_username)}
                    className='font-medium text-base'
                >
                    {participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                </p>
            }
            {message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid &&
                <div className='w-full rounded-xl flex my-1 cursor-pointer text-sm' onClick={() => scrollToMessage(message.message_replied_from.message_uuid)}>
                    <div className={`bg-sky-400 dark:bg-sky-600  w-[3px] min-w-[3px] rounded-l-full`} />
                    <div className={`bg-sky-400/20 dark:bg-sky-600/20  rounded-r-lg pl-2 py-0.5 pr-2 flex flex-col truncate overflow-hidden grow`}>
                        <span className={`text-sky-600 dark:text-sky-500 `}>{`${message.message_replied_from.user_first_name? message.message_replied_from.user_first_name : ''} ${message.message_replied_from.user_last_name ? message.message_replied_from.user_last_name : ''}`}</span>
                        <span className={`text-gray-500 dark:text-gray-400 truncate `} >{message.message_replied_from.message_text}</span>
                    </div>
                </div>
            }
            {message.emoji_interactions && Object.keys(message.emoji_interactions).length > 0 ? (
                    <div className='flex flex-col w-full'>
                        <span className='text-sm'>{message.message_text}</span>
                        <div className='flex jusitfy-between'>
                            <InteractionsComponent message={message} userInfo={userInfo} updateEmoji={updateEmoji} participants={participants} />
                            <span className={`text-gray-500 dark:text-gray-400 text-xs ml-auto mt-auto mb-[-5px] min-w-[60px] italic pl-3 py-0.5`}>
                               {message.message_is_edited && 'edited  '} {formatDateToAmPm(message.message_created_at)}
                            </span>

                        </div>
                    </div>
                ):(
                    <div className='flex flex-wrap '>
                        <span className='text-sm'>{message.message_text}</span> 
                        <span className={`text-gray-500 dark:text-gray-400 text-xs ml-auto mt-auto text-right mb-[-5px] italic pl-3 py-0.5`}>
                        {message.message_is_edited && 'edited  '}{formatDateToAmPm(message.message_created_at)}
                        </span>
                    </div>
                )}
        </div>
        <div className={`${isGroup ? 'w-8 h-8': 'hidden'} order-1 `}>
            {showImage &&
                <Avatar
                    sx={{ 
                        bgcolor: handleUserBGColor(message.user_username), 
                        width: 32, 
                        height: 32 }}
                    alt={participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                    src={participants[message.user_username].user_image_url}
                />
            }
        </div>
            {showImage && isGroup &&
            <div 
                className={`absolute bottom-[-13px] left-[20px] rotate-[80deg] text-slate-100 dark:text-slate-800`}
                style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
            >
                <svg width="30" height="30" viewBox="0 0 20 10">
                    <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" />
                </svg>
            </div>
            }
            {showImage && !isGroup &&
            <div 
                className={`absolute bottom-[-13px] left-[-16px] rotate-[80deg] text-slate-100 dark:text-slate-800`}
                style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
                >
                <svg width="30" height="30" viewBox="0 0 20 10">
                    <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" />
                </svg>
            </div>
            }
    </>
  )
}

export default ReceiverMessageOnlyComponent