import React, { useEffect, useState } from 'react'
import ScrapsBarChart from '../../components/charts/ScrapsBarChart';
import OptimizePartImage from '../../components/OptimizePartImage';
import ProgressGaugeLarge from '../../components/charts/ProgressGaugeLarge';
import RanpakDoubleBarChart from '../../components/charts/RanpakDoubleBarChart';

function RanpakOperationsList({opsList,operation, selectedAsset, dateCategories, currentProgress, runtimeData, averageFeet, totalFeet, showBoxData, timePassed}) {
    const [totalPartsNum, setTotalPartsNum] = useState(0)
    const [totalPartsProgress, setTotalPartsProgress] = useState(0)
    const [totalCurrentPartsGoal, setTotalCurrentPartsGoal] = useState(0)
    const [shiftGoal, setShiftGoal] = useState(0)
    const [hrGoal, setHrGoal] = useState(0)

    useEffect(() => {
        if(operation.overridden_good_count){
            setTotalPartsNum(operation.overridden_good_count)
        }
        if(operation.asset_operation_hourly_goal_count){
            setHrGoal(operation.asset_operation_hourly_goal_count)
        }
        if(currentProgress && operation.overridden_good_count){
            let shiftGoal = 1
            if(operation.asset_operation_shift_goal_count){
                shiftGoal = operation.asset_operation_shift_goal_count
                setShiftGoal(operation.asset_operation_shift_goal_count)
            }
            let progressPercent = (operation.overridden_good_count/shiftGoal) * 100
            setTotalPartsProgress(progressPercent)
    
            
            let currentPartGoal = shiftGoal * currentProgress
            currentPartGoal = Math.floor(currentPartGoal)
            setTotalCurrentPartsGoal(currentPartGoal)

        }
        

    }, [operation, currentProgress])
    

    const addCommasInNumbers = (num) => {
        if(num){
            const out = num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

            // const out = num.toLocaleString();
    
            return out
        }else{
            return '0'
        }
    }

    const formatCycleTime = (value) => {
        if(value){
            if(value.includes('.'))
            {
                value = value.split('.')[0] + '.' + value.split('.')[1].slice(0, 2);
            }
            return value
        }else return 0

    }


    const averageBoxes = (boxCount) => {
        
        let averageBox = (boxCount/timePassed)*3600
        averageBox = averageBox.toFixed(1)

        return averageBox
    }


  return (
    <div className='max-h-[500px] xl:max-h-[260px]  overflow-y-scroll scrollbar-hide px-2 pb-2 overflow-hidden '>
        <div className='mt-1.5 flex flex-col rounded-2xl bg-white dark:bg-slate-900/50 shadow-iotflows-lg overflow-hidden'>
            <div className='flex items-center'>
                <div className='bg-transparent p-1 w-full flex justify-between'>
                    <div className='flex items-center w-full '>
                        <div className='ml-4'>
                            {operation && Object.keys(operation).length > 0 &&
                                <OptimizePartImage alt={operation.part_name} source={operation.part_picture_url} size='xl' />
                            }
                        </div>
                        <div className='text-gray-600 dark:text-gray-300 py-2 pr-2 pl-2 w-[150px]'>
                            <div className='flex flex-col'>
                                <p className='text-left leading-5 truncate text-lg text-gray-600 dark:text-gray-300 font-medium'>{operation.operation_name}</p>
                                <p className='text-left leading-5 truncate text-base text-gray-500 dark:text-gray-400 font-medium'>{operation.operation_description}</p>
                                <p className='text-left truncate text-sm text-gray-500 dark:text-gray-400 font-normal'>{operation.part_name}</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 font-medium' >
                        <div className='flex mx-auto'>
                            <span className='text-xl leading-4 mx-auto text-gray-600 dark:text-gray-300 '>{`${addCommasInNumbers(totalFeet)}`}</span>
                        </div>
                        <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400 '>total feet</div>
                    </div>
                    <div className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 font-medium' >
                        <div className='flex mx-auto'>
                            <span className='text-xl leading-4 text-gray-600 dark:text-gray-300'>{`${addCommasInNumbers(averageFeet)}`}</span>
                        </div>
                        <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'>ft/hour</div>
                    </div>
                    <div className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 font-medium' >
                        <div className='flex mx-auto'>
                            <span className='text-xl leading-4 text-gray-600 dark:text-gray-300'>{`${addCommasInNumbers(operation.overridden_good_count)}`}</span>
                        </div>
                        <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'>total boxes</div>
                    </div>
                    <div className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 font-medium' >
                        <div className='flex mx-auto'>
                            <span className='text-xl leading-4 text-gray-600 dark:text-gray-300'>{`${averageBoxes(operation.overridden_good_count)}`}</span>
                        </div>
                        <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'>boxes/hour</div>
                    </div>
            </div>
            <div className='w-full flex'>
                <div className='flex-1'>
                    <RanpakDoubleBarChart countArray={operation.operations_made} dateCategories={dateCategories} height='150px' countArrayFeet={runtimeData} showBoxData={showBoxData}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RanpakOperationsList