import { useLocation, useNavigate } from 'react-router-dom';
import ProgressGaugeLarge from "../components/charts/ProgressGaugeLarge";
import BarChartCard from "../components/charts/BarChartCard";
import { motion } from "framer-motion";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid"
import StatusBar from '../components/charts/StatusBar';
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import EditAssetModal from '../components/modals/assetmodals/EditAssetModal';
import DeleteAssetModal from '../components/modals/assetmodals/DeleteAssetModal';
import ArchiveAssetModal from '../components/modals/assetmodals/ArchiveAssetModal';
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'
import OptimizeLargeImage from '../components/OptimizeLargeImage';
import TimeAgo from 'timeago-react';
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import moment from 'moment';
import { LightTooltip } from '../styles/Tooltip';
import { refreshOrganizationApis } from '../atoms/refreshStates'; 
import BarChartList from '../components/charts/BarChartList';
import StatusBarHeight from '../components/charts/StatusBarHeight';
import ProgressGaugeXL from '../components/charts/ProgressGaugeXL';
import { toast } from 'aws-amplify';
import ScrapsBarChart from '../components/charts/ScrapsBarChart';

function CardLargeOeeComponent({asset,index, getOrgAssets, grayOutStatus, mqttMessageNodeUUID, mqttMessageTimestamp, isUserAuthorized, isMobile}) {
    const navigate = useNavigate();
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    // const isOrganizationApisRefreshed = useRecoilValue(refreshOrganizationApis)
    const [isEditAssetModalOpen, setIsEditAssetModalOpen] = useState(false)
    const [isDeleteAssetModalOpen, setIsDeleteAssetModalOpen] = useState(false)
    const [isArchiveAssetModalOpen, setIsArchiveAssetModalOpen] = useState(false)
    const [uptime, setUptime] = useState('0:00h')
    const [downtime, setDowntime] = useState('0:00h')
    const [oeeCalc, setOeeCalc] = useState(0)
    const [oeeString, setOeeString] = useState('')
    const [totalPartsNum, setTotalPartsNum] = useState(0)
    const [totalPartsProgress, setTotalPartsProgress] = useState(0)
    const [barChartHourlyGoal, setBarChartHourlyGoal] = useState(0)
    const [barChartData, setBarChartData] = useState([])
    const [barChartCategories, setBarChartCategories] = useState([])
    const [statusBarData, setStatusBarData] = useState([])
    const [totalPartsGoal, setTotalPartsGoal] = useState(0)
    const [totalCurrentPartsGoal, setTotalCurrentPartsGoal] = useState(0)
    const [currentProgress, setCurrentProgress] = useState(0)
    const location = useLocation();
    const [assetActivity, setAssetActivity] = useState([])
    const [isAssetRunning, setIsAssetRunning] = useState(false)
    const [timePassed, setTimePassed] = useState(0)
    const [justRefreshed, setJustRefreshed] = useState(false)
    const [refreshAfterTimer, setRefreshAfterTimer] = useState(false)
    const [updateAsset, setUpdateAsset] = useState(0)
    const [svgState, setSvgState] = useState(null)
    const [timePassedDate, setTimePassedDate] = useState('')
    const [opsList, setOpsList] = useState([])
    const [dateCategories, setDateCategories] = useState([])
    const [shiftGoal, setShiftGoal] = useState(0)
    

    useEffect(() => {
        try {
            if(mqttMessageNodeUUID == asset.node_uuid && mqttMessageTimestamp > 0) {
            // console.log(`AFTER CARDDD I updated MQTT node ${mqttMessageNodeUUID} with node_uuid ${asset.node_uuid} at time ${mqttMessageTimestamp}`)
                if(!justRefreshed){
                    setJustRefreshed(true)
                    let time = new Date().getTime()
                    setUpdateAsset(time)
                    setTimeout(() => {
                        setJustRefreshed(false)
                    }, 10000)
                }else {
                    setRefreshAfterTimer(true)
                }
            }
            
        } catch (error) {
            
        }

    }, [mqttMessageTimestamp])

    useEffect(() => {
        try {
            if(!justRefreshed && refreshAfterTimer){
                // console.log('Refreshing because 5 seconds has gone by')
                setJustRefreshed(true)
                setRefreshAfterTimer(false)
                let time = new Date().getTime()
                setUpdateAsset(time)
                setTimeout(() => {
                    setJustRefreshed(false)
                }, 10000)
            }
            
        } catch (error) {
            
        }

    }, [justRefreshed, refreshAfterTimer])

    useEffect(() => {
        try {
            if(asset && asset.asset_uuid){
                // console.log(`Updated ${asset.asset_custom_identifier}`)
                let currentSearch = location.search
                getData(apiLink + '/v1/assets/'+ asset.asset_uuid + '/total-hourly-count' + currentSearch)
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            // console.log('hourly bar chart dat',asset.asset_uuid, data)
                            // setTotalPartsGoal(data.data.total_asset_shift_goal_count)
                            // if(data.data.total_operation_count){
                            //     setTotalPartsNum(data.data.total_operation_count)
                            //     let shiftGoal = 1
                            //     if(data.data.total_asset_shift_goal_count){
                            //         shiftGoal = data.data.total_asset_shift_goal_count
                            //     }
                            //     let progressPercent = (data.data.total_operation_count/shiftGoal) * 100
                            //     setTotalPartsProgress(progressPercent)
                            // }

                            // // calculating data for hourly bar chart
                            // let totalHours = Object.keys(data.data.total_hourly_count).length
                            // let shiftGoal = 0 
                            // let hourProductionGoal = 0
                            // if(totalHours > 0 && data.data.total_asset_shift_goal_count){
                            //     shiftGoal = data.data.total_asset_shift_goal_count
                            //     hourProductionGoal = (shiftGoal/totalHours).toFixed(1)
                            //     hourProductionGoal = parseFloat(hourProductionGoal)
                            // }
                            // setBarChartHourlyGoal(hourProductionGoal)
                            let countData = []
                            let timeCategories = []
                            let lastDataIndex = Object.keys(data.data.total_hourly_count).length -1
                            data.data.total_hourly_count.map((hourCount, index) => {
                                let time = ''
                                if(index === 0) {
                                    time = formatAMPM(hourCount.hr)
                                    timeCategories.push(time)
                                }else if(index === lastDataIndex){
                                    time = formatAMPM(hourCount.hr)
                                    timeCategories.push(time)
                                }else{
                                    timeCategories.push(time)
                                }

                                let amount = 0
                                if(hourCount.total_operation_count){
                                    amount = hourCount.total_operation_count
                                }
                                let array = [formatAMPM(hourCount.hr), amount]
                                countData.push(array)
                            })
                            // setBarChartData(countData)
                            setBarChartCategories(timeCategories)
                        }
                    }
                })
                // let currentSearch = location.search
                if(currentSearch){
                    currentSearch += '&time_interval_min=60'
                }else{
                    currentSearch = '?time_interval_min=60'
                }
                getData(apiLink + '/v1/assets/'+ asset.asset_uuid + '/operations_made_by_interval' + currentSearch  )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    const data = await response.json();
                    if(response.ok ){
                        if(data && data.data[0] && data.data[0].operations_made && Object.keys(data.data[0].operations_made).length > 0){
                            setOpsList(data.data)
                            if(data.data[0].overridden_good_count){
                                setTotalPartsNum(data.data[0].overridden_good_count)
                            }
                            if(data.data[0].asset_operation_shift_goal_count){
                                setTotalPartsGoal(data.data[0].asset_operation_shift_goal_count)
                            }
                            let goal = 1
                            if(data.data[0].asset_operation_shift_goal_count){
                                goal = data.data[0].asset_operation_shift_goal_count
                                setShiftGoal(data.data[0].asset_operation_shift_goal_count)
                                let progressPercent = (data.data[0].overridden_good_count/goal) * 100
                                setTotalPartsProgress(progressPercent)
                            }
                            let timeCategoriesAll = []
                            let timeCategoriesTrimmed = []
                            let lastDataIndex = Object.keys(data.data[0].operations_made).length -1
                            
                            data.data[0].operations_made.map((hourCount, index) => {
                                let time = ''
                                if(index === 0) {
                                    time = formatAMPM(hourCount.interval_timestamp)
                                    timeCategoriesTrimmed.push(time)
                                }else if(index === lastDataIndex){
                                    time = formatAMPM(hourCount.interval_timestamp)
                                    timeCategoriesTrimmed.push(time)
                                }else{
                                    timeCategoriesTrimmed.push(time)
                                }
    
                                let currentTime = formatAMPM(hourCount.interval_timestamp)
                                timeCategoriesAll.push(currentTime)
    
                            })
                            setDateCategories(timeCategoriesAll)
                            // setBarChartCategories(timeCategoriesAll)
                        }
                    }else{
                        toast.error(data.message)
                    }
                })

                // getData(apiLink + '/v1/assets/'+ asset.asset_uuid + '/shift-utilizations-operations-csv' + currentSearch)
                // .then(async response => {
                //     // JSON data parsed by response.json() call
                //     if(response.ok ){
                //     const data = await response.json();
                //         if(data && data.data) {                            
                //             // convert csv to json for shift_states
                //             var shift_states_json_array = []
                //             if(data.data.shift_states && data.data.shift_states.includes(","))
                //             {
                //                 var lines = data.data.shift_states.split('\n')
                //                 let keys = lines[0].split(',')

                //                 lines.shift()
                //                 lines.map(line => {
                //                     var line = line.split('"').join("")                                      
                //                     let line_values = line.split(',')   
                //                     if(line_values.length == keys.length)
                //                     {                                                  
                //                         var line_json = {}                                    
                //                         for(var a = 0; a < line_values.length; a++)
                //                         {                                                                                                                                                 
                //                             line_json[keys[a]] = line_values[a]
                //                         }                                        
                //                         shift_states_json_array.push(line_json)
                //                     }                                                                                                        
                //                 })                                
                //                 // set the json version to state                                
                //                 setStatusBarData(shift_states_json_array)
                //             }


                            
                //             let uptimeString  = formatTime(data.data.runtime_shift)
                //             let downtimeString  = formatTime(data.data.downtime_shift)

                //             setUptime(uptimeString)
                //             setDowntime(downtimeString)
                            
                //             let totalTime = data.data.runtime_shift + data.data.downtime_shift
                //             let oee = (data.data.runtime_shift/totalTime)*100
                //             if(!oee)oee = 0
                //             let oeeString = oee.toFixed(0) + '%'
                //             setOeeCalc(oee)
                //             setOeeString(oeeString)
                            
                //         }
                        
                //     }
                // })

                getData(apiLink + '/v1/assets/'+ asset.asset_uuid + '/shift-utilizations-operations-svg' + currentSearch)
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data) {              
                            let imageSvg = `data:image/svg+xml;charset=utf-8;base64,` + btoa(data.data.shift_states_svg)
                            // setSvgState(imageSvg)
                            let svgBitMap = await convertSvgToBitmap(imageSvg)

                            if(svgBitMap.length < 10){
                                setSvgState(imageSvg)
                            }else{
                                setSvgState(svgBitMap)
                            }
                            
                            let uptimeString  = formatTime(data.data.runtime_shift)
                            let downtimeString  = formatTime(data.data.downtime_shift)

                            setUptime(uptimeString)
                            setDowntime(downtimeString)
                            
                            let totalTime = data.data.runtime_shift + data.data.downtime_shift
                            let oee = (data.data.runtime_shift/totalTime)*100
                            if(!oee)oee = 0
                            let oeeString = oee.toFixed(0) + '%'
                            setOeeCalc(oee)
                            setOeeString(oeeString)
                            
                        }
                        
                    }
                })


                getData(apiLink + '/v1/assets/'+ asset.asset_uuid + '/activity-state' + currentSearch)
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setAssetActivity(data.data)

                            let timePassed = 0
                            // if(mqttMessageNodeUUID == asset.node_uuid && mqttMessageTimestamp > 0 ) {
                            //     const activityLastOpDate = new Date(data.data.activity_finished_at)
                            //     const lastOpSecs = activityLastOpDate.getTime()
                            //     let lastOp = 0
                            //     let lastOpDate = ''
                                
                            //     let mqttDate = 0 
                            //     if(mqttMessageTimestamp){
                            //         mqttDate = mqttMessageTimestamp
                            //     }
                                
                            //     if(mqttDate > lastOpSecs){
                            //         lastOp = mqttMessageTimestamp
                            //         lastOpDate = mqttMessageTimestamp
                            //     }else{
                            //         lastOp = lastOpSecs
                            //         lastOpDate = data.data.activity_finished_at
                            //     }
                        
                            //     const currenDate = new Date()
                            //     const currentSecs = currenDate.getTime()
                        
                            //     timePassed = (currentSecs - lastOp)/1000
                            //     setTimePassed(timePassed)
                            //     setTimePassedDate(lastOpDate)
                
                            // }else{
                            //     const lastOpDate = new Date(data.data.activity_finished_at)
                            //     const lastOpSecs = lastOpDate.getTime()
                        
                            //     const currenDate = new Date()
                            //     const currentSecs = currenDate.getTime()
                        
                            //     timePassed = (currentSecs - lastOpSecs)/1000
                            //     setTimePassed(timePassed)
                            //     setTimePassedDate(data.data.activity_finished_at)
                            // }
                            
                            const lastOpDate = new Date(data.data.activity_finished_at)
                            const lastOpSecs = lastOpDate.getTime()
                    
                            const currenDate = new Date()
                            const currentSecs = currenDate.getTime()
                    
                            timePassed = (currentSecs - lastOpSecs)/1000
                            setTimePassed(timePassed)
                            setTimePassedDate(data.data.activity_finished_at)
                            if(data.data.state ==='running' || timePassed <= 300){
                                setIsAssetRunning(true)
                            }else{
                                setIsAssetRunning(false)
                            }
                        }
                    }
                })

            }
        } catch (error) {
            
        }

    }, [asset, updateAsset])

    useEffect(() => {
        if(currentProgress && shiftGoal){
            let currentPartGoal = shiftGoal * currentProgress
            currentPartGoal = Math.floor(currentPartGoal)
            setTotalCurrentPartsGoal(currentPartGoal)

        }
    }, [shiftGoal, currentProgress])


    useEffect(() => {
        try {
            if(selectedOrganization && totalPartsGoal){
                getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/shifts/current')
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setCurrentProgress(data.data[0].progress)
                            let currentPartGoal = totalPartsGoal * data.data[0].progress
                            currentPartGoal = Math.floor(currentPartGoal)
                            setTotalCurrentPartsGoal(currentPartGoal)
                            
                        }
                    }
                })
            }
            
        } catch (error) {
            
        }

    }, [totalPartsGoal, updateAsset])

    const convertSvgToBitmap = async (svg) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = svg;
      
            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
      
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
      
              const bitmapDataUrl = canvas.toDataURL('image/png'); // You can change 'image/png' to 'image/jpeg' if needed
              resolve(bitmapDataUrl) 
            //   setBitmap(bitmapDataUrl);
            };
      
            img.onerror = (error) => {
                console.error('Error loading SVG image:', error);
                reject(error)
            };
        })
      };


    const formatTime =(seconds) => {
        let timeString = ''
        if(seconds){
            let totalMin = seconds/60
            let hours = totalMin/60
            hours = Math.floor(hours)
            let remMinutes = (totalMin - (hours*60))
            remMinutes = Math.floor(remMinutes)
            if(hours < 10 && hours >= 0){
                hours = '0' + hours 
            }
            if(remMinutes < 10 && remMinutes >= 0){
                remMinutes = '0' + remMinutes 
            }

            timeString = `${hours}:${remMinutes}h`



            // let formattedUptime  = moment.utc(seconds*1000).format('HH:mm')
            // let timeString = `${formattedUptime}h`
            // return timeString
        }else{
            timeString = `00:00h`
            
        }
        return timeString
    }

    function formatAMPM(date) {
        let event = new Date(date)
        let currentTime = event.toLocaleTimeString('en-US', { hour: '2-digit'})
        currentTime = currentTime.replace(/\s/g, '')
        if(currentTime[0] == '0'){
            currentTime = currentTime.substring(1)
        }

        return currentTime
      }

    
    const handleOpenEditAssetModal = () => {
        setIsEditAssetModalOpen(false)
    }
    const handleOpenDeleteAssetModal = () => {
        setIsDeleteAssetModalOpen(false)
    }
    const handleCloseArchiveAssetModal = () => {
        setIsArchiveAssetModalOpen(false)
    }

    const handleNavigation = () => {
        if(isMobile){
            navigate(`/${selectedOrganization}/assets/selected-asset/${asset.asset_uuid}?platform=mobile`)
        }else{
            navigate(`/${selectedOrganization}/assets/selected-asset/${asset.asset_uuid}`)
        }
    }


  return (
    <div >
        <motion.div  whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.98 }} key={index} className='relative z-0 shadow-iotflows-lg flex-1 rounded-lg overflow-hidden bg-slate-50/50 dark:bg-slate-800 cursor-pointer  '>
            <div className='absolute top-0 right-0 z-50'>
                <Menu as="div" className="relative text-left">
                    <div>
                    <MenuButton 
                        className="inline-flex w-full justify-center text-center rounded-full bg-transparent p-0.5 text-sm font-medium hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 disabled:hover:bg-transparent disabled:dark:hover:bg-transparent"
                        disabled={!isUserAuthorized}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300 " aria-hidden="true" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                        </svg>
                    </MenuButton>
                    </div>
                    <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    >
                    <MenuItems className="absolute right-0 w-40 origin-top-right rounded-md bg-white dark:bg-slate-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <MenuItem>
                                {({ active }) => (
                                <button
                                    className={`${
                                    active ? 'bg-blue-500 dark:bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-300'
                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm disabled:opacity-50`}
                                    onClick={()=> setIsEditAssetModalOpen(true)}
                                    disabled={!isUserAuthorized}
                                >
                                    {active ? (
                                    <EditActiveIcon
                                        className="mr-2 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    ) : (
                                    <EditInactiveIcon
                                        className="mr-2 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    )}
                                    Edit
                                </button>
                                )}
                            </MenuItem>
                            <MenuItem>
                                {({ active }) => (
                                <button
                                    className={`${
                                    active ? 'bg-blue-500 dark:bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-300'
                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm disabled:opacity-50`}
                                    onClick={()=> setIsArchiveAssetModalOpen(true)}
                                    disabled={!isUserAuthorized}
                                >
                                    {active ? (
                                    <ArchiveActiveIcon
                                        className="mr-2 h-5 w-5 text-gray-900 dark:text-gray-300"
                                        aria-hidden="true"
                                    />
                                    ) : (
                                    <ArchiveInactiveIcon
                                        className="mr-2 h-5 w-5 text-gray-900 dark:text-gray-300"
                                        aria-hidden="true"
                                    />
                                    )}
                                    Archive
                                </button>
                                )}
                            </MenuItem>
                        </div>
                        <div className="px-1 py-1 border-t-[0.01em] dark:border-slate-600">
                            {Object.keys(assetActivity).length > 0  ? (
                                <LightTooltip title="This asset can't be deleted because it has been used in production and has operation data attached to it. If you are no longer using this asset, you can archive it instead." >
                                    <div
                                        className={`flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900 dark:text-gray-400`}
                                    >
                                        <DeleteInactiveIcon
                                            className="mr-2 h-5 w-5 text-gray-900 dark:text-gray-500"
                                            aria-hidden="true"
                                        />
                                        <span>Delete</span>
                                    </div>
                                </LightTooltip> 

                            ):(
                            <MenuItem>
                                {({ active }) => (
                                <button
                                    className={`${
                                    active ? 'bg-blue-500 dark:bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-300'
                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm disabled:opacity-50`}
                                    onClick={()=> setIsDeleteAssetModalOpen(true)}
                                    disabled={!isUserAuthorized}
                                >
                                    {active ? (
                                    <DeleteActiveIcon
                                        className="mr-2 h-5 w-5 text-blue-400"
                                        aria-hidden="true"
                                    />
                                    ) : (
                                    <DeleteInactiveIcon
                                        className="mr-2 h-5 w-5 text-blue-400"
                                        aria-hidden="true"
                                    />
                                    )}
                                    Delete
                                </button>
                                )}
                            </MenuItem>
                            )}
                        </div>
                    </MenuItems> 
                    </Transition>
                </Menu>
            </div>
            <div className='flex flex-col' onClick={handleNavigation}>
                    <div className='pt-4 pl-2 overflow-hidden truncate'>
                        <div className='pb-1 text-left truncate text-4xl text-gray-600 dark:text-white font-normal'>{asset.asset_custom_name  ? asset.asset_custom_name :asset.machine_name}</div>
                        <div className='pb-1 text-left truncate text-4xl text-gray-400 dark:text-gray-400 '>{asset.asset_custom_identifier  ? asset.asset_custom_identifier :asset.asset_uuid}</div>
                    </div>
                    <div className='flex flex-col  border-b-0 border-gray-200 dark:border-gray-700 pb-2'>
                        <div className='px-2 2xl:px-4 pt-2 z-[9]'>
                            {grayOutStatus ? (
                                <div className={`border-8 border-gray-300 dark:border-gray-500 rounded-full bg-white z-10 h-28 w-28`}>
                                        <OptimizeLargeImage alt={asset.machine_name} source={asset.asset_custom_picture_url  ? asset.asset_custom_picture_url : asset.machine_picture_url} size='xl' />
                                </div>
                            ):(
                                <div className={`border-8 ${isAssetRunning? 'border-green-600':'border-red-600'} rounded-full bg-white z-10 h-28 w-28`}>
                                        <OptimizeLargeImage alt={asset.machine_name} source={asset.asset_custom_picture_url  ? asset.asset_custom_picture_url : asset.machine_picture_url} size='xl' />
                                </div>
                            )}
                        </div>
                        <div>
                            {assetActivity && Object.keys(assetActivity).length > 0 && !grayOutStatus &&
                                setLastOpTime(asset, assetActivity, timePassed, timePassedDate)
                            } 
                        </div>
                    </div>
            </div>
            {/* <div className='flex justify-around py-2 px-1 border-b-2 border-gray-200 dark:border-gray-700' onClick={handleNavigation}>
                <div className='text-5xl text-green-600 font-medium flex items-center' >
                    {uptime}
                    <ArrowSmUpIcon className='h-8 w-8 2xl:h-9 2xl:w-9 text-green-600' />
                </div>
                <div className='text-5xl text-red-600 font-medium flex items-center sm:pl-2' >
                    {downtime}
                    <ArrowSmDownIcon className='h-8 w-8 2xl:h-9 2xl:w-9 text-red-600' />
                </div>
            </div> */}
            <div className='flex justify-around mt-1' onClick={handleNavigation}>
                <div className=''>
                    <ProgressGaugeXL stringValue={oeeString} unit={'OEE'} numericValue={oeeCalc} thresholdValue={60} />
                </div>
                <div className='flex flex-col justify-around px-1 border-b-0 border-gray-200 dark:border-gray-700' onClick={handleNavigation}>
                    <div className='text-5xl text-green-600 font-medium flex items-center' >
                        {uptime}
                        <ArrowSmUpIcon className='h-8 w-8 2xl:h-9 2xl:w-9 text-green-600' />
                    </div>
                    <div className='text-5xl text-red-600 font-medium flex items-center ' >
                        {downtime}
                        <ArrowSmDownIcon className='h-8 w-8 2xl:h-9 2xl:w-9 text-red-600' />
                    </div>
                </div>
            </div>
            <div onClick={handleNavigation}>
                <StatusBarHeight statusBarData={statusBarData} barChartCategories={barChartCategories} />
            </div>
            {svgState &&
                <div onClick={handleNavigation} >
                        <img src={svgState} className='w-full h-4'/>
                </div>
                }
        </motion.div>
        {isEditAssetModalOpen && 
            <EditAssetModal remoteOpenAssetModal={isEditAssetModalOpen} handleOpenEditAssetModal={handleOpenEditAssetModal} asset={asset} btnType='hideBtn' getOrgAssets={getOrgAssets} />
        }
        {isArchiveAssetModalOpen && 
            <ArchiveAssetModal remoteOpenModal={isArchiveAssetModalOpen} handleCloseArchiveAssetModal={handleCloseArchiveAssetModal} asset={asset} getOrgAssets={getOrgAssets} />
        }
        {isDeleteAssetModalOpen && 
            <DeleteAssetModal remoteOpenModal={isDeleteAssetModalOpen} handleOpenDeleteAssetModal={handleOpenDeleteAssetModal} asset={asset} getOrgAssets={getOrgAssets} />
        }
    </div>
  )
}

export default CardLargeOeeComponent

const setLastOpTime = (asset, assetActivity, timePassed, timePassedDate) => {
    if(asset && Object.keys(asset).length > 0){
       
    
        if(assetActivity.state === 'running'){
            return (
                <div className='h-12 w-[300px] bg-green-600  rounded-tl-full rounded-r-full mt-[-50px] ml-14 mr-14  text-right text-white dark:text-white font-medium text-3xl items-center p-1  '>
                    Running&nbsp;
                </div>
            )
        }else if(assetActivity.state === 'stopped'){
            if(timePassed <= 300){
                return (
                    <div className='h-12 w-[300px] bg-green-600 rounded-tl-full rounded-r-full mt-[-50px] ml-14 mr-14 text-right text-white dark:text-white font-medium text-3xl items-center p-1  '>
                        <TimeAgo datetime={timePassedDate}  />
                    </div>
                )
            }else{
                return (
                    <div className='h-12 w-[300px] bg-red-600 rounded-tl-full rounded-r-full mt-[-50px] ml-14 mr-14 text-right text-white dark:text-white font-medium text-3xl items-center p-1  '>
                        <TimeAgo datetime={timePassedDate}  />
                    </div>
                )

            }
        }

    }

}


function EditInactiveIcon(props) {
    return (
        <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M4 13V16H7L16 7L13 4L4 13Z"
            fill="transparent"
            stroke="#94a3b8"
            strokeWidth="2"
        />
        </svg>
    )
}

function EditActiveIcon(props) {
    return (
        <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M4 13V16H7L16 7L13 4L4 13Z"
            fill="transparent"
            stroke="#a5b4fc"
            strokeWidth="2"
        />
        </svg>
    )
}

function DeleteInactiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill="transparent"
          stroke="#94a3b8"
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke="#94a3b8" strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke="#94a3b8" strokeWidth="2" />
      </svg>
    )
  }
  
  function DeleteActiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill="transparent"
          stroke="#a5b4fc"
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke="#a5b4fc" strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke="#a5b4fc" strokeWidth="2" />
      </svg>
    )
  }
function ArchiveInactiveIcon(props) {
    return (
        <svg 
        {...props} 
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" viewBox="0 0 24 24" 
        strokeWidth={2} 
        stroke="#94a3b8"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
        </svg>
    )
  }
  
  function ArchiveActiveIcon(props) {
    return (
      <svg 
        {...props} 
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" viewBox="0 0 24 24" 
        stroke="#a5b4fc"
        strokeWidth="2"
        >
        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
    </svg>

    )
  }