import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {colorPalletLight, colorPalletDark} from '../../styles/chatColorPallet'
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { formatRelativeTime } from '../../utils/DateFormating';

function GroupChatRow({convo, selectedOrganization, selectedChatId, userInfo, index, chatUnreadDic}) {
    const navigate = useNavigate();
    const isDark = useRecoilValue(darkModeState)
    const [messageHeader, setMessageHeader] = useState([])
    const [color, setColor] = useState('')
    const [unreadCount, setUnreadCount] = useState(0)

    useEffect(() => {
        if(convo && Object.keys(convo).length > 0 && convo.chat_uuid){
            if(chatUnreadDic[convo.chat_uuid]){
                let count = chatUnreadDic[convo.chat_uuid].unread_count
                setUnreadCount(Number(count))
            }

        }

    },[chatUnreadDic, convo])

    useEffect(() => {
        if(isDark){
            let arrayLength = colorPalletDark.length;
            let adjustedIndex = index % arrayLength;
            setColor(colorPalletDark[adjustedIndex])
            

        }else{
            let arrayLength = colorPalletLight.length;
            let adjustedIndex = index % arrayLength;
            setColor(colorPalletLight[adjustedIndex])
        }

    }, [index, isDark])


    const lastMessage = (messages) => {
        try{
            if(messages && Object.keys(messages).length > 0){
                return messages.message_text
            }
        }catch(e){

        }
        
    }

   


  return (
    <div 
        key={convo.chat_uuid} 
        className={`flex ${selectedChatId == convo.chat_uuid ? 'bg-slate-200 dark:bg-slate-700': 'hover:bg-slate-100 dark:hover:bg-slate-800'} items-center truncate cursor-pointer rounded`}
        // className={`flex ${selectedChatId == convo.chat_uuid ? 'bg-slate-200 dark:bg-slate-700': 'hover:bg-slate-100 dark:hover:bg-slate-800'} items-center truncate pl-1 pr-2 py-1 cursor-pointer rounded-xl`}
        onClick={() => navigate(`/${selectedOrganization}/chats?chatId=${convo.chat_uuid}&section=messages`)} 
    >
        <div className={`flex w-full items-center truncate pl-1 pr-2 py-1 cursor-pointer border-b-[0.01em] dark:border-slate-600`}>
            <div className='flex items-center'>
                <Avatar
                    sx={{ width: 48, height: 48, bgcolor: color}}
                    alt={convo.chat_name}
                    src={convo.chat_picture_url || '/brokenLink.png'}
                    >
                </Avatar>
            </div>
            <div className={`flex flex-col truncate w-full`}>
                <div className='flex justify-between items-end'>
                    <div className='pl-2 text-gray-600 dark:text-gray-300 truncate text-sm font-medium'>{convo.chat_name}</div>
                    <div className='pl-2 text-gray-500 dark:text-gray-400 text-xs'>{formatRelativeTime(convo.last_message_created_at)}</div>
                </div>
                <div className='flex justify-between items-end'>
                    <span className='pl-2 text-gray-500 dark:text-gray-400 truncate text-sm'>{lastMessage(convo.last_message)}</span>
                    {(unreadCount && unreadCount != "0" || unreadCount != 0) ? (
                        <div className='bg-slate-100 text-gray-500 dark:bg-slate-600 dark:text-white text-xs text-center my-auto min-w-[24px] rounded-full p-1 ml-auto'>
                        {unreadCount}
                        </div>
                    ):(
                        <div/>
                    )
                    }
                    
                </div>

            </div>
        </div>
    </div>
  )
}

export default GroupChatRow