import React, { useState, useEffect } from 'react'
import '../../styles/PinnedMessages.css';
import {  motion } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';


function PinnedMessages({messages, showSearch}) {
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [activeIndex, setActiveIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [fillHeight, setFillHeight] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams()

    const handleNavToPinnedList = () => {

        let select = searchParams.getAll('select')
        let chatId = searchParams.getAll('chatId')

        setSearchParams({select,chatId, section: 'pinnedMessagesList'})

    }

    
    var sectionHeight = 100 / messages.length;

    const nextMessage = () => {
        if (!isAnimating) {
        setIsAnimating(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => setIsAnimating(false), 300); // Match this with your CSS transition time
        return () => clearTimeout(timer);
    }, [currentIndex]);

    useEffect(() => {
        // console.log('sectionHeight', sectionHeight)
        const newFillHeight = sectionHeight * (currentIndex + 1);
        // console.log('newFillHeight', newFillHeight)
        setFillHeight(sectionHeight);
        // setFillHeight(newFillHeight);
      }, [currentIndex, messages.length]);

    
      

      const heightCSS = (index, currentHeight, currentIndex) => { 
        
        let bottomCalc = 100 - (index + 1) * sectionHeight

        return{
            height: `${currentHeight}%`,
            bottom: `${bottomCalc}%`,
            // transform: `translateY(${offset}px)`,
            // backgroundColor: `${index === currentIndex ? '#0ea5e9' : '#f43f5e'}`,
            backgroundColor: `#0ea5e9`,
            transition: 'opacity 0.3s ease-out',
        }
      }

  return (
    <div className={`absolute top-12 left-0 w-full bg-white dark:bg-slate-900 border-b-[0.01em] border-slate-100 dark:border-slate-600 pt-1 pb-0.5 transition-all duration-500 ease-in-out ${!showSearch ? 'translate-y-0 ' : '-translate-y-full '}`}> 
        <div className="pinned-messages-container ">
        <div className="pinned-messages ">
            {messages.map((message, index) => (
                <div
                    key={index}
                    className={`pinned-message bg-white dark:bg-slate-900 ${index === currentIndex ? 'active' : ''} ${
                        isAnimating ? 'animating' : ''
                    }`}
                    
                >
                    <div onClick={nextMessage} className='highlight-indicator relative'>
                        <div className="absolute w-full left-0 rounded-xl" style={heightCSS(index, fillHeight, currentIndex)}></div>
                    </div>
                    <div
                        onClick={nextMessage} 
                        className="message-content text-gray-500 dark:text-gray-400 flex-1"
                    >
                        {message}
                    </div>
                    <motion.button 
                        whileHover={{scale: 1.05} } 
                        whileTap={{scale:0.95 }}  
                        className='text-gray-500 dark:text-gray-400 ml-auto'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='w-5 h-5'>
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>

                    </motion.button>
                    <motion.button 
                        whileHover={{scale: 1.05} } 
                        whileTap={{scale:0.95 }}  
                        className='text-gray-500 dark:text-gray-400 ml-2'
                        onClick={handleNavToPinnedList}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960" fill="currentColor">
                            <path d="M640-760v280l68 68q6 6 9 13.5t3 15.5v23q0 17-11.5 28.5T680-320H520v234q0 17-11.5 28.5T480-46q-17 0-28.5-11.5T440-86v-234H280q-17 0-28.5-11.5T240-360v-23q0-8 3-15.5t9-13.5l68-68v-280q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760ZM354-400h252l-46-46v-314H400v314l-46 46Zm126 0Z"/>
                        </svg>
                    </motion.button>
                </div>
            ))}
            <div>
                

            </div>
        </div>

    </div>
  </div>
  )
}

export default PinnedMessages