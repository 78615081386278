import { useEffect, useState } from 'react'
import { motion } from "framer-motion";
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Zoom } from '@mui/material';
import { useRecoilValue } from 'recoil';
import EditShiftGoalModal from '../components/modals/partModals/EditShiftGoalModal';
import { apiLink } from '../ApiCalls/ApisVariables';
import { getData } from '../ApiCalls/DataApis';
import { refreshOrganizationApis } from '../atoms/refreshStates';
import { darkModeState } from '../atoms/darkModeState';
import ProgressHalfGauge from '../components/charts/ProgressHalfGauge';
import OptimizeImage from '../components/OptimizeImage';
import ProgressBar from '../components/charts/ProgressBar';
import BarChartList from '../components/charts/BarChartList';
import { LightTooltip, MemberTooltip } from '../styles/Tooltip';
import ScrapsBarChart from '../components/charts/ScrapsBarChart';

function AssetsCollapsibleCard({operation, assetOp, currentShiftData, getOpsList, grayoutSection, dateCategories}) {
    const location = useLocation();
    const isDark = useRecoilValue(darkModeState)
    const isOrganizationApisRefreshed = useRecoilValue(refreshOrganizationApis)
    const [isSubCollapsed, setIsSubCollapsed] = useState(false)
    const [barChartHourlyGoal, setBarChartHourlyGoal] = useState(0)
    const [barChartData, setBarChartData] = useState([])
    const [barChartCategories, setBarChartCategories] = useState([])
    const [avgAssetOpsPerHour, setAvgAssetOpsPerHour] = useState(0)
    const [avgAssetOpsActualPerHour, setAvgAssetActualOpsPerHour] = useState(0)
    const [openEditShiftGoalModal, setOpenEditShiftGoalModal] = useState(false)
    const [assetsCount, setAssetsCount] = useState(0)
    const [assetShiftGoal, setAssetShiftGoal] = useState(0)
    const [assetCycleTime, setAssetCycleTime] = useState(0)
    const [assetActualCycleTime, setAssetActualCycleTime] = useState(0)
    const [totalShiftSecs, setTotalShiftSecs] = useState(0)
    const [opMaxCycleTime, setOpMaxCycleTime] = useState(0)
    const [optimalProgressbarPercent, setOptimalProgressbarPercent] = useState(0)
    const [arrowAvg, setArrowAvg] = useState(3600)

    const handleCloseEditShiftGoalModal = () => {
        setOpenEditShiftGoalModal(false)
    }


    useEffect(() => {
        if(assetOp && assetOp.asset_uuid && operation.operation_uuid){
            // getHourlyCount()

            if(assetOp && assetOp.overridden_good_count){
                setAssetsCount(assetOp.overridden_good_count)
            }
            if(assetOp && assetOp.cycle_time_s){
                setAssetCycleTime(assetOp.cycle_time_s)
            }
            if(currentShiftData && currentShiftData.max_goal_or_cycle_time_s){
                let max = Number(currentShiftData.max_goal_or_cycle_time_s) 
                setOpMaxCycleTime(max)
            }

            if(assetOp.hourly_operations_made && Object.keys(assetOp.hourly_operations_made).length > 0){
                let hourlyGoal = assetOp.asset_operation_hourly_goal_count ? assetOp.asset_operation_hourly_goal_count : 0
                setBarChartHourlyGoal(hourlyGoal)

                let countData = []
                let timeCategories = []
                let lastDataIndex = Object.keys(assetOp.hourly_operations_made).length -1
                assetOp.hourly_operations_made.map((hourCount, index) => {
                    let time = ''
                    if(index === 0) {
                        time = formatAMPM(hourCount.interval_timestamp)
                        timeCategories.push(time)
                    }else if(index === lastDataIndex){
                        time = formatAMPM(hourCount.interval_timestamp)
                        timeCategories.push(time)
                    }else{
                        timeCategories.push(time)
                    }
    
                    let amount = 0
                    if(hourCount.overridden_good_count){
                        amount = hourCount.overridden_good_count
                    }
                    let array = [formatAMPM(hourCount.interval_timestamp), amount]
                    countData.push(array)
                })
                setBarChartData(countData)
                setBarChartCategories(timeCategories)
            }
        }
    }, [assetOp, location, isOrganizationApisRefreshed])

    useEffect(() => {
        if(assetCycleTime && opMaxCycleTime){
            let  optimalProgressbarPercent = Math.round((assetCycleTime / opMaxCycleTime)*100)
            
            setOptimalProgressbarPercent(optimalProgressbarPercent)

            if(assetOp.asset_operation_hourly_goal_count){
                let desiredSecs = (60 / assetOp.asset_operation_hourly_goal_count ) * 60
                let arrow = Math.round((desiredSecs / opMaxCycleTime)*100)
                setArrowAvg(arrow)
            }else{
                setArrowAvg(optimalProgressbarPercent)
            }

        }

    }, [opMaxCycleTime, assetCycleTime])

    


    function formatAMPM(date) {
        let event = new Date(date)
        let currentTime = event.toLocaleTimeString('en-US', { hour: '2-digit'})
        currentTime = currentTime.replace(/\s/g, '')
        if(currentTime[0] == '0'){
            currentTime = currentTime.substring(1)
        }

        return currentTime
      }

    const formatCycleTime = (cycleTimeString) => {
        if(cycleTimeString){
            const parts = cycleTimeString.split('.'); // Split at the decimal point
            const milliseconds = parts[1] || '0'; // Get milliseconds (or default to '0')
            const formattedMilliseconds = milliseconds.slice(0, 2); // Keep only 2 characters
            return `${parts[0]}.${formattedMilliseconds}`; 

        }else{
            return ''
        }
    }

    const handleUpdateLists = () =>{
        // we need to update these lists to properly get new shift goals
        getOpsList()
        // getHourlyCount()
        // getHourlyOpsData()
    }

    // var optimalProgressbarPercent = Math.round((assetCycleTime / opMaxCycleTime)*100)
    // // console.log('assetCycleTime', assetCycleTime)
    // // console.log('opMaxCycleTime', opMaxCycleTime)
    // // console.log('optimalProgressbarPercent', optimalProgressbarPercent)
    // var actualProgressbarPercent = Math.round((assetActualCycleTime / opMaxCycleTime)*100)
    // // var arrowAvg = Math.round((assetCycleTime / opMaxCycleTime)*100)
    // var desiredSecs = (totalShiftSecs / assetShiftGoal )
    // var arrowAvg = Math.round((desiredSecs / opMaxCycleTime)*100)

  return (
    <div className='xl:-ml-11 bg-slate-100 bg-opacity-50 dark:bg-slate-900 dark:bg-opacity-50 '>
        {/* Screens for medium screens or below */}
                                        <div className='flex flex-col ml-10 pr-2 py-8 border-t dark:border-slate-600 xl:hidden'>
                                            <div className='flex z-0 justify-between'>
                                                <div className='flex items-center' >

                                                    {/* Avatar for part image */}
                                                    <div className='p-1'>
                                                        {/* <img src={assetOp.machine_picture_url} alt={assetOp.machine_name} loading={'lazy'} className='h-12 w-12 rounded-full ' /> */}
                                                        <OptimizeImage alt={assetOp.asset_custom_name ? assetOp.asset_custom_name : assetOp.machine_name} source={assetOp.asset_custom_picture_url ? assetOp.asset_custom_picture_url : assetOp.machine_picture_url} size={'large'} />
                                                    </div>
                                                    <div className='flex flex-col justify-center text-left truncate w-[175px]'>
                                                        <div className={`text-lg leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-700 dark:text-gray-300'} p-1 truncate`}>{assetOp.asset_custom_name  ? assetOp.asset_custom_name :assetOp.machine_name}</div>
                                                        <div className={`text-sm leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} p-1 truncate`}>{assetOp.asset_custom_identifier ? assetOp.asset_custom_identifier : assetOp.machine_identifier }</div>
                                                    </div>
                                                </div>
                                                <div className={`hidden sm:flex items-center my-auto `}>
                                                    <div className='flex flex-col justify-center text-center'>
                                                        <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Hourly Goal</div>
                                                        <button 
                                                        onClick={() => setOpenEditShiftGoalModal(true)} 
                                                        className={`text-lg min-w-[70px] text-center font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} py-0 px-2 truncate border border-gray-200 dark:border-gray-600 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700`}
                                                        >{operation.asset_operation_hourly_goal_count}</button>
                                                    </div>
                                                </div>
                                                <div className={`hidden sm:flex items-center my-auto `}>
                                                    <div className='flex flex-col justify-center text-center'>
                                                        <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Quality</div>
                                                        <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} truncate`}>{operation.quality ? `${operation.quality}%`: '100%'}</div>
                                                    </div>
                                                </div>
                                                <div className={`hidden sm:flex items-center my-auto `}>
                                                    <div className='flex flex-col justify-center text-center'>
                                                        <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Bad</div>
                                                        <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} truncate`}>{operation.overridden_bad_count ? `${operation.overridden_bad_count}`: '0'}</div>
                                                    </div>
                                                </div>
                                                <div className={`hidden sm:flex items-center my-auto`}>
                                                    <ProgressHalfGauge number={assetsCount} unit={'made'} currentShiftData={currentShiftData} shiftGoalCount={assetOp.asset_operation_shift_goal_count} grayoutSection={grayoutSection} />
                                                </div>
                                                
                                            </div>
                                            <div className='flex sm:hidden my-1 justify-between'>
                                                {/* <div className={`flex items-center mr-auto my-auto pl-1`}>
                                                    <div className={`text-base font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} p-1 pr-2 truncate`}>{`Hourly Goal:`}</div>
                                                    <button 
                                                        onClick={() => setOpenEditShiftGoalModal(true)} 
                                                        className={`text-base min-w-[70px] text-center font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} py-1 px-2 truncate border border-gray-200 dark:border-gray-600 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700`}
                                                        >
                                                        {assetOp.asset_operation_hourly_goal_count}
                                                    </button>
                                                </div> */}
                                                <div className={`items-center my-auto pl-2 `}>
                                                    <div className='flex flex-col justify-center text-center'>
                                                        <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Hourly Goal</div>
                                                        <button 
                                                            onClick={() => setOpenEditShiftGoalModal(true)} 
                                                            className={`text-base min-w-[70px] text-center font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} py-0 px-2 truncate border border-gray-200 dark:border-gray-600 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700`}
                                                        >
                                                            {assetOp.asset_operation_hourly_goal_count}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className={`items-center my-auto `}>
                                                    <div className='flex flex-col justify-center text-center'>
                                                        <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Quality</div>
                                                        <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} truncate`}>{assetOp.quality ? `${assetOp.quality}%`: '100%'}</div>
                                                    </div>
                                                </div>
                                                <div className={`items-center my-auto `}>
                                                    <div className='flex flex-col justify-center text-center'>
                                                        <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Bad</div>
                                                        <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} truncate`}>{assetOp.overridden_bad_count ? `${assetOp.overridden_bad_count}`: '0'}</div>
                                                    </div>
                                                </div>
                                                <div className={` items-center my-auto`}>
                                                <ProgressHalfGauge number={assetsCount} unit={'made'} currentShiftData={currentShiftData} shiftGoalCount={assetOp.asset_operation_shift_goal_count} grayoutSection={grayoutSection} />
                                                </div>
                                            </div>
                                            {/* Production rate linear bar */}
                                            <div className=' grid grid-cols-4 h-20 mt-1 pl-2'>
                                                <div className='col-span-4 flex items-center relative overflow-x-hidden'>
                                                    <div className='flex absolute top-1 left-0 items-baseline'>
                                                        <p className={`text-sm font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'}`}>{assetOp.cycle_time ? `Cycle Time: ${formatCycleTime(assetOp.cycle_time)}`: ''}</p>
                                                    </div>
                                                    <div className='w-full mt-2'>
                                                        <ProgressBar completed={optimalProgressbarPercent} arrowAvg={arrowAvg} grayoutSection={grayoutSection} isMobile={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Hourly count bar chart */}
                                            <div className={`flex items-center relative h-20 mt-1`}>
                                                <div className='w-full py-1 z-0'>
                                                <ScrapsBarChart height='120px' operation_uuid={operation.operation_uuid} countArray={assetOp.hourly_operations_made} hourlyGoal={barChartHourlyGoal} dateCategories={dateCategories}/>
                                                {/* {barChartCategories && barChartCategories.length > 0 &&
                                                    <BarChartList chartHeight={75} containerHeight={'60px'} barChartData={barChartData} barChartCategories={barChartCategories} barChartHourlyGoal={barChartHourlyGoal} /> 
                                                } */}
                                                </div>
                                            </div>
                                        </div>
        {/* Screens x-large and above */}
        <div className='hidden xl:grid xl:grid-cols-12 xl:gap-2 xl:h-20 '>
            <div className='col-span-3 flex items-center pl-2'>
                {/* Collapse button */}
                {assetOp && assetOp.assets && Object.keys(assetOp.assets).length > 0 ? (
                    <motion.div
                    className="box pl-2 "
                    onClick={() => setIsSubCollapsed(!isSubCollapsed)}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    >
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 transition ease-in-out duration-500 ${isSubCollapsed? '-rotate-180': 'rotate-0'} `} viewBox="0 0 20 20" fill="currentColor"> 
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                </motion.div>
                ): (
                    <button disabled  className={`pl-2 opacity-0`} >
                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 transition ease-in-out duration-500 ${isSubCollapsed? '-rotate-180': 'rotate-0'} `} viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                )}

                {/* Avatar for part image */}
                <div className='p-1'>
                    <OptimizeImage 
                        alt={assetOp.asset_custom_name ? assetOp.asset_custom_name : assetOp.machine_name} 
                        source={assetOp.asset_custom_picture_url ? assetOp.asset_custom_picture_url : assetOp.machine_picture_url} 
                        size={'large'} />
                </div>
                <div className='flex flex-col justify-center text-left truncate'>
                    <div className={`text-base leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-700 dark:text-gray-300'} p-1 truncate`}>{assetOp.asset_custom_name  ? assetOp.asset_custom_name :assetOp.machine_name}</div>
                    <div className={`text-sm leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} p-1 truncate`}>{assetOp.asset_custom_identifier ? assetOp.asset_custom_identifier : assetOp.machine_identifier }</div>
                </div>
            </div>
            <div className={`col-span-1 items-center mx-auto my-auto`}>
                <LightTooltip title="Edit shift production goal for asset operation">
                    <button 
                        onClick={() => setOpenEditShiftGoalModal(true)} 
                        className={`text-base min-w-[60px] text-center font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} py-1 px-1 truncate border border-gray-200 dark:border-gray-600 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700`}
                        >
                        {assetOp.asset_operation_hourly_goal_count ? assetOp.asset_operation_hourly_goal_count : 0}
                    </button>
                </LightTooltip>
            </div>
            <div className={`col-span-1 items-center mx-auto`}>
                <div className='mt-1'>
                    <ProgressHalfGauge number={assetsCount} unit={'made'} currentShiftData={currentShiftData} shiftGoalCount={assetOp.asset_operation_shift_goal_count} grayoutSection={grayoutSection} />
                </div>
            </div>
            <div className='col-span-3 flex items-center relative overflow-hidden'>
                <div className='flex absolute top-2 left-0 items-baseline'>
                    <p className={`text-sm font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'}`}>{assetOp.cycle_time ? `Cycle Time: ${formatCycleTime(assetOp.cycle_time)}`: ''}</p>
                </div>
                <div className='w-full mt-1'>
                    <ProgressBar completed={optimalProgressbarPercent} arrowAvg={arrowAvg} grayoutSection={grayoutSection} />
                </div>
            </div>
            <div className={`col-span-1 items-center mx-auto my-auto`}>
                <div className='flex flex-col items-center'>
                <div className={`text-xl font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'}  truncate`}>{assetOp.quality ? `${assetOp.quality}%`: '100%'}</div>
                <div className={`text-base font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-rose-600 dark:text-rose-300'}  truncate`}>{assetOp.overridden_bad_count ? `${assetOp.overridden_bad_count} Bad`: '0 Bad'}</div>
                </div>
            </div>
            <div className={`col-span-3 flex items-center relative `}>
                <div className='w-full py-1 z-0'>
                <ScrapsBarChart height='90px' operation_uuid={operation.operation_uuid} countArray={assetOp.hourly_operations_made} hourlyGoal={barChartHourlyGoal} dateCategories={dateCategories}/>
                    {/* {barChartCategories && barChartCategories.length > 0 &&
                        <BarChartList chartHeight={75} containerHeight={'60px'} barChartData={barChartData} barChartCategories={barChartCategories} barChartHourlyGoal={barChartHourlyGoal} /> 
                    } */}
                </div>
            </div>
        </div>
        {openEditShiftGoalModal &&
            <EditShiftGoalModal btnType={'hideBtn'} remoteOpenModal={openEditShiftGoalModal} operation={operation} assetOp={assetOp} goal={assetOp.asset_operation_hourly_goal_count} refreshList={handleUpdateLists} handleCloseEditShiftGoalModal={handleCloseEditShiftGoalModal} />
        }
    </div>
  )
}

export default AssetsCollapsibleCard
// import { useEffect, useState } from 'react'
// import { motion } from "framer-motion";
// import moment from 'moment';
// import { useLocation } from 'react-router-dom';
// import { Zoom } from '@mui/material';
// import { useRecoilValue } from 'recoil';
// import EditShiftGoalModal from '../components/modals/partModals/EditShiftGoalModal';
// import { apiLink } from '../ApiCalls/ApisVariables';
// import { getData } from '../ApiCalls/DataApis';
// import { refreshOrganizationApis } from '../atoms/refreshStates';
// import { darkModeState } from '../atoms/darkModeState';
// import ProgressHalfGauge from '../components/charts/ProgressHalfGauge';
// import OptimizeImage from '../components/OptimizeImage';
// import ProgressBar from '../components/charts/ProgressBar';
// import BarChartList from '../components/charts/BarChartList';
// import { LightTooltip, MemberTooltip } from '../styles/Tooltip';

// function AssetsCollapsibleCard({operation, assetOp, currentShiftData, getOpsList, getHourlyOpsData, grayoutSection}) {
//     const location = useLocation();
//     const isDark = useRecoilValue(darkModeState)
//     const isOrganizationApisRefreshed = useRecoilValue(refreshOrganizationApis)
//     const [isSubCollapsed, setIsSubCollapsed] = useState(false)
//     const [barChartHourlyGoal, setBarChartHourlyGoal] = useState(0)
//     const [barChartData, setBarChartData] = useState([])
//     const [barChartCategories, setBarChartCategories] = useState([])
//     const [avgAssetOpsPerHour, setAvgAssetOpsPerHour] = useState(0)
//     const [avgAssetOpsActualPerHour, setAvgAssetActualOpsPerHour] = useState(0)
//     const [openEditShiftGoalModal, setOpenEditShiftGoalModal] = useState(false)
//     const [assetsCount, setAssetsCount] = useState(0)
//     const [assetShiftGoal, setAssetShiftGoal] = useState(0)
//     const [assetCycleTime, setAssetCycleTime] = useState(0)
//     const [assetActualCycleTime, setAssetActualCycleTime] = useState(0)
//     const [totalShiftSecs, setTotalShiftSecs] = useState(0)
//     const [opMaxCycleTime, setOpMaxCycleTime] = useState(0)

//     const handleCloseEditShiftGoalModal = () => {
//         setOpenEditShiftGoalModal(false)
//     }

//     function handleTimeFormat(seconds) {
//         if(seconds){
//             let formatted = moment.utc(seconds*1000).format('HH:mm:ss')
//             return formatted
//         }else{
//             let formatted = moment.utc(0).format('HH:mm:ss')
//             return formatted
//         }
//     }

//     useEffect(() => {
//         if(assetOp && assetOp.asset_uuid && operation.operation_uuid){
//             getHourlyCount()
//         }
//     }, [assetOp, location, isOrganizationApisRefreshed])

    


//     const getHourlyCount = () => {
//         if(assetOp && assetOp.asset_uuid && operation.operation_uuid){
//             let currentSearch = location.search
//             getData(apiLink + '/v1/assets/' + assetOp.asset_uuid + '/operations/' + operation.operation_uuid + '/hourly-count' + currentSearch)
//             .then(async response => {
//                 // JSON data parsed by response.json() call
//                 if(response.ok ){
//                 const data = await response.json();
//                     if(data && data.data){
//                         // console.log('Asset- hourly count', data)
//                         setAssetsCount(data.asset_operation_count)
//                         if(data.asset_operation_shift_goal_count != undefined){
//                             setAssetShiftGoal(data.asset_operation_shift_goal_count)
//                         }
//                         setAssetCycleTime(data.asset_operation_cycle_time_s)
//                         if(data.asset_operation_cycle_per_hour && data.asset_operation_cycle_per_hour.toFixed(1)){
//                             setAvgAssetOpsPerHour(data.asset_operation_cycle_per_hour.toFixed(1))
//                         }else{
//                             setAvgAssetOpsPerHour(0)
//                         }
//                         if(data.asset_operation_actual_cycle_per_hour && data.asset_operation_actual_cycle_per_hour.toFixed(1)){
//                             setAvgAssetActualOpsPerHour(data.asset_operation_actual_cycle_per_hour.toFixed(1))
//                         }else{
//                             setAvgAssetActualOpsPerHour(0)
//                         }

//                         setAssetActualCycleTime(data.asset_operation_actual_cycle_time_s)
                        
//                         setOpMaxCycleTime(data.max_operation_cycle_time_s)
//                         setTotalShiftSecs(data.total_shift_s)
//                         let totalHours = Object.keys(data.data).length
//                         let shiftGoal = 0 
//                         let hourProductionGoal = 0
                        
//                         if(totalHours > 0 && data.asset_operation_shift_goal_count){
//                             shiftGoal = data.asset_operation_shift_goal_count
//                             hourProductionGoal = shiftGoal/totalHours.toFixed(1)
//                             hourProductionGoal = parseFloat(hourProductionGoal)
//                         }
//                         setBarChartHourlyGoal(hourProductionGoal)
//                         let countData = []
//                         let timeCategories = []
//                         let lastDataIndex = Object.keys(data.data).length -1
//                         data.data.map((hourCount, index) => {
//                             let time = ''
//                             if(index === 0) {
//                                 time = formatAMPM(hourCount.hr)
//                                 timeCategories.push(time)
//                             }else if(index === lastDataIndex){
//                                 time = formatAMPM(hourCount.hr)
//                                 timeCategories.push(time)
//                             }else{
//                                 timeCategories.push(time)
//                             }

//                             let amount = 0
//                             if(hourCount.operation_count){
//                                 amount = hourCount.operation_count
//                             }
//                             let array = [formatAMPM(hourCount.hr), amount]
//                             countData.push(array)
//                         })
//                         setBarChartData(countData)
//                         setBarChartCategories(timeCategories)
//                     }
//                 }
//             })
//         }

//     }


//     function formatAMPM(date) {
//         let event = new Date(date)
//         let currentTime = event.toLocaleTimeString('en-US', { hour: '2-digit'})
//         currentTime = currentTime.replace(/\s/g, '')
//         if(currentTime[0] == '0'){
//             currentTime = currentTime.substring(1)
//         }

//         return currentTime
//       }

//     const handleUpdateLists = () =>{
//         // we need to update these lists to properly get new shift goals
//         getOpsList()
//         getHourlyCount()
//         getHourlyOpsData()
//     }

//     var optimalProgressbarPercent = Math.round((assetCycleTime / opMaxCycleTime)*100)
//     // console.log('assetCycleTime', assetCycleTime)
//     // console.log('opMaxCycleTime', opMaxCycleTime)
//     // console.log('optimalProgressbarPercent', optimalProgressbarPercent)
//     var actualProgressbarPercent = Math.round((assetActualCycleTime / opMaxCycleTime)*100)
//     // var arrowAvg = Math.round((assetCycleTime / opMaxCycleTime)*100)
//     var desiredSecs = (totalShiftSecs / assetShiftGoal )
//     var arrowAvg = Math.round((desiredSecs / opMaxCycleTime)*100)

//   return (
//     <div className='lg:-ml-11 bg-slate-100 bg-opacity-50 dark:bg-slate-900 dark:bg-opacity-50 '>
//         {/* Screens for medium screens or below */}
//                                         <div className='flex flex-col ml-10 pr-2 py-8 border-t dark:border-slate-600 lg:hidden'>
//                                             <div className='flex z-0 justify-between'>
//                                                 <div className='flex items-center' >
//                                                     {/* Collapse button */}
//                                                     {/* {subAsset && assetOp.assets && Object.keys(assetOp.assets).length > 0 ? (
//                                                         <motion.div
//                                                         className="box pl-2 "
//                                                         onClick={() => setIsSubCollapsed(!isSubCollapsed)}
//                                                         whileHover={{ scale: 1.1 }}
//                                                         whileTap={{ scale: 0.9 }}
//                                                         transition={{ type: "spring", stiffness: 400, damping: 17 }}
//                                                         >
//                                                     <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 transition ease-in-out duration-500 ${isSubCollapsed? '-rotate-180': 'rotate-0'} `} viewBox="0 0 20 20" fill="currentColor"> 
//                                                         <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
//                                                     </svg>
//                                                     </motion.div>
//                                                     ): (
//                                                         <button disabled  className={`pl-2 opacity-0`} >
//                                                             <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 transition ease-in-out duration-500 ${isSubCollapsed? '-rotate-180': 'rotate-0'} `} viewBox="0 0 20 20" fill="currentColor">
//                                                                 <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
//                                                             </svg>
//                                                         </button>
//                                                     )} */}

//                                                     {/* Avatar for part image */}
//                                                     <div className='p-1'>
//                                                         {/* <img src={assetOp.machine_picture_url} alt={assetOp.machine_name} loading={'lazy'} className='h-12 w-12 rounded-full ' /> */}
//                                                         <OptimizeImage alt={assetOp.asset_custom_name ? assetOp.asset_custom_name : assetOp.machine_name} source={assetOp.asset_custom_picture_url ? assetOp.asset_custom_picture_url : assetOp.machine_picture_url} size={'large'} />
//                                                     </div>
//                                                     <div className='flex flex-col justify-center text-left truncate'>
//                                                         <div className={`text-lg leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-700 dark:text-gray-300'} p-1 truncate`}>{assetOp.asset_custom_name  ? assetOp.asset_custom_name :assetOp.machine_name}</div>
//                                                         <div className={`text-sm leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} p-1 truncate`}>{assetOp.asset_custom_identifier ? assetOp.asset_custom_identifier : assetOp.machine_identifier }</div>
//                                                     </div>
//                                                 </div>
//                                                 <div className={`hidden sm:flex items-center my-auto `}>
//                                                     <div className={`text-lg font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} p-1 pr-2 truncate`}>{`Goal:`}</div>
//                                                     <button 
//                                                         onClick={() => setOpenEditShiftGoalModal(true)} 
//                                                         className={`text-lg min-w-[70px] text-center font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} py-1 px-2 truncate border border-gray-200 dark:border-gray-600 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700`}
//                                                         >
//                                                         {assetShiftGoal}
//                                                     </button>
//                                                 </div>
//                                                 <div className={`hidden sm:flex items-center my-auto`}>
//                                                     <ProgressHalfGauge number={assetsCount} unit={'made'} currentShiftData={currentShiftData} shiftGoalCount={assetShiftGoal} grayoutSection={grayoutSection} />
//                                                 </div>
                                                
//                                             </div>
//                                             <div className='flex sm:hidden my-1 justify-between'>
//                                                 <div className={`flex items-center mr-auto my-auto pl-1`}>
//                                                     <div className={`text-base font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} p-1 pr-2 truncate`}>{`Goal:`}</div>
//                                                     <button 
//                                                         onClick={() => setOpenEditShiftGoalModal(true)} 
//                                                         className={`text-base min-w-[70px] text-center font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} py-1 px-2 truncate border border-gray-200 dark:border-gray-600 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700`}
//                                                         >
//                                                         {assetShiftGoal}
//                                                     </button>
//                                                 </div>
//                                                 <div className={` items-center ml-auto my-auto`}>
//                                                 <ProgressHalfGauge number={assetsCount} unit={'made'} currentShiftData={currentShiftData} shiftGoalCount={assetShiftGoal} grayoutSection={grayoutSection} />
//                                                 </div>
//                                             </div>
//                                             {/* Production rate linear bar */}
//                                             <div className=' grid grid-cols-4 h-20 mt-1 pl-2'>
//                                                 <div className='col-span-4 flex items-center relative overflow-x-hidden'>
//                                                     <div className='flex absolute top-1 left-0 items-baseline'>
//                                                         <p className={`text-base font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'}`}>{handleTimeFormat(assetActualCycleTime)}</p>
//                                                         <p className={`text-sm font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'}`}>&nbsp;(Machining time {handleTimeFormat(assetCycleTime)})</p>
//                                                     </div>
//                                                     <div className='w-full mt-2'>
//                                                         <ProgressBar completed={optimalProgressbarPercent} arrowAvg={arrowAvg} secondCompleted={actualProgressbarPercent} grayoutSection={grayoutSection} isMobile={true} />
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                             {/* Hourly count bar chart */}
//                                             <div className={`flex items-center relative h-20 mt-1`}>
//                                                 <div className='flex absolute top-0 left-2 items-baseline z-10'>
//                                                 <p className={`flex items-baseline text-base font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'}`}>{avgAssetOpsActualPerHour} Ops <span className={`text-sm font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'}`}>&nbsp;/ hr</span></p>
//                                                     <p className={`text-sm font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-400 dark:text-gray-400'}`}>&nbsp;({avgAssetOpsPerHour}/hr/asset)</p>
//                                                 </div>
//                                                 <div className='w-full mt-5 z-0'>
//                                                 {barChartCategories && barChartCategories.length > 0 &&
//                                                     <BarChartList chartHeight={75} containerHeight={'60px'} barChartData={barChartData} barChartCategories={barChartCategories} barChartHourlyGoal={barChartHourlyGoal} /> 
//                                                 }
//                                                 </div>
//                                             </div>
//                                         </div>
//         {/* Screens large and above */}
//         <div className='hidden lg:grid lg:grid-cols-11 lg:gap-2 lg:h-20 '>
//             <div className='col-span-3 flex items-center pl-2'>
//                 {/* Collapse button */}
//                 {assetOp && assetOp.assets && Object.keys(assetOp.assets).length > 0 ? (
//                     <motion.div
//                     className="box pl-2 "
//                     onClick={() => setIsSubCollapsed(!isSubCollapsed)}
//                     whileHover={{ scale: 1.1 }}
//                     whileTap={{ scale: 0.9 }}
//                     transition={{ type: "spring", stiffness: 400, damping: 17 }}
//                     >
//                 <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 transition ease-in-out duration-500 ${isSubCollapsed? '-rotate-180': 'rotate-0'} `} viewBox="0 0 20 20" fill="currentColor"> 
//                     <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
//                 </svg>
//                 </motion.div>
//                 ): (
//                     <button disabled  className={`pl-2 opacity-0`} >
//                         <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 transition ease-in-out duration-500 ${isSubCollapsed? '-rotate-180': 'rotate-0'} `} viewBox="0 0 20 20" fill="currentColor">
//                             <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
//                         </svg>
//                     </button>
//                 )}

//                 {/* Avatar for part image */}
//                 <div className='p-1'>
//                     <OptimizeImage 
//                         alt={assetOp.asset_custom_name ? assetOp.asset_custom_name : assetOp.machine_name} 
//                         source={assetOp.asset_custom_picture_url ? assetOp.asset_custom_picture_url : assetOp.machine_picture_url} 
//                         size={'large'} />
//                 </div>
//                 <div className='flex flex-col justify-center text-left truncate'>
//                     <div className={`text-base leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-700 dark:text-gray-300'} p-1 truncate`}>{assetOp.asset_custom_name  ? assetOp.asset_custom_name :assetOp.machine_name}</div>
//                     <div className={`text-sm leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} p-1 truncate`}>{assetOp.asset_custom_identifier ? assetOp.asset_custom_identifier : assetOp.machine_identifier }</div>
//                 </div>
//             </div>
//             {/* Progress hald gauge */}
//             <div className={`col-span-1 items-center mx-auto`}>
//                 <ProgressHalfGauge number={assetsCount} unit={'made'} currentShiftData={currentShiftData} shiftGoalCount={assetShiftGoal} grayoutSection={grayoutSection} />
//             </div>
//             <div className={`col-span-1 items-center mx-auto my-auto`}>
//                 <LightTooltip title="Edit shift production goal for asset operation">
//                     <button 
//                         onClick={() => setOpenEditShiftGoalModal(true)} 
//                         className={`text-base min-w-[70px] text-center font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} py-1 px-2 truncate border border-gray-200 dark:border-gray-600 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700`}
//                         >
//                         {assetShiftGoal}
//                     </button>
//                 </LightTooltip>
//             </div>
//             {/* Production time linear bar */}
//             <div className='col-span-3 flex items-center relative overflow-hidden'>
//                 <div className='flex absolute top-1 left-0 items-baseline'>
//                     <MemberTooltip TransitionComponent={Zoom}
//                         title={
//                             <div className={`flex flex-col rounded-lg p-2 ${isDark? ' bg-slate-900':' bg-slate-50'}`}>
//                             <div className={`w-full text-center py-1 text-sm ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>Production Time</span>
//                             </div>
//                             <div className={`w-full text-center py-1 text-sm  ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>Time it takes from the start of one operations to another operation. This is useful when wanting to include any downtimes in your operations.</span>
//                             </div>
//                             <div className={`w-full text-center py-1 text-sm italic ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>Every {handleTimeFormat(assetActualCycleTime)} an operation is being completed in this machine</span>
//                             </div>
//                             </div>
//                         }
//                     >
//                         <p className={`text-lg font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'}`}>{handleTimeFormat(assetActualCycleTime)}</p>
//                     </MemberTooltip>
//                     <MemberTooltip TransitionComponent={Zoom}
//                         title={
//                             <div className={`flex flex-col rounded-lg p-2 ${isDark? ' bg-slate-900':' bg-slate-50'}`}>
//                             <div className={`w-full text-center py-1 text-sm ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>Machining Time</span>
//                             </div>
//                             <div className={`w-full text-center py-1 text-sm  ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>The time it takes your machine to perform this operation.</span>
//                             </div>
//                             <div className={`w-full text-center py-1 text-sm italic ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>It takes your machine {handleTimeFormat(assetCycleTime)} to perform this operation</span>
//                             </div>
//                             </div>
//                         }
//                     >
//                         <p className={`lg:text-sm xl:text-base font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'}`}>&nbsp;(Machining time {handleTimeFormat(assetCycleTime)})</p>
//                     </MemberTooltip>
//                 </div>
//                 <div className='w-full mt-2'>
//                     <ProgressBar completed={optimalProgressbarPercent} arrowAvg={arrowAvg} secondCompleted={actualProgressbarPercent} grayoutSection={grayoutSection} />
//                 </div>
//             </div>
//             {/* Hourly count bar chart */}
//             <div className={`col-span-3 flex items-center relative `}>
//                 <div className='flex absolute top-0 left-10 items-baseline z-10'>
//                     <MemberTooltip TransitionComponent={Zoom}
//                         title={
//                             <div className={`flex flex-col rounded-lg p-2 ${isDark? ' bg-slate-900':' bg-slate-50'}`}>
//                             <div className={`w-full text-center py-1 text-sm ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>Operation production rate</span>
//                             </div>
//                             <div className={`w-full text-center py-1 text-sm  ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>The amount of operations being performed per hour</span>
//                             </div>
//                             <div className={`w-full text-center py-1 text-sm italic ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>On average {avgAssetOpsActualPerHour} operations are being performed per hour</span>
//                             </div>
//                             </div>
//                         }
//                     >
//                         <p className={`flex items-baseline text-base font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'}`}>{avgAssetOpsActualPerHour} Ops <span className={`text-sm font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'}`}>&nbsp;/ hr</span></p>
//                     </MemberTooltip>
//                     <MemberTooltip TransitionComponent={Zoom}
//                         title={
//                             <div className={`flex flex-col rounded-lg p-2 ${isDark? ' bg-slate-900':' bg-slate-50'}`}>
//                             <div className={`w-full text-center py-1 text-sm ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>Asset production rate</span>
//                             </div>
//                             <div className={`w-full text-center py-1 text-sm  ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>The average amount of operations being performed for asset.</span>
//                             </div>
//                             <div className={`w-full text-center py-1 text-sm italic ${isDark? ' text-gray-400':'text-gray-700'} `}>
//                                 <span>On average your asset performs {avgAssetOpsPerHour} operations per hour </span>
//                             </div>
//                             </div>
//                         }
//                     >
//                         <p className={`text-sm font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-400 dark:text-gray-400'}`}>&nbsp;({avgAssetOpsPerHour}/hr/asset)</p>
//                     </MemberTooltip>
//                 </div>
//                 <div className='w-full mt-3 z-0'>
//                     {barChartCategories && barChartCategories.length > 0 &&
//                         <BarChartList chartHeight={75} containerHeight={'60px'} barChartData={barChartData} barChartCategories={barChartCategories} barChartHourlyGoal={barChartHourlyGoal} /> 
//                     }
//                 </div>
//             </div>
//         </div>
//         {openEditShiftGoalModal &&
//             <EditShiftGoalModal btnType={'hideBtn'} remoteOpenModal={openEditShiftGoalModal} operation={operation} assetOp={assetOp} goal={assetShiftGoal} refreshList={handleUpdateLists} handleCloseEditShiftGoalModal={handleCloseEditShiftGoalModal} />
//         }
//     </div>
//   )
// }

// export default AssetsCollapsibleCard