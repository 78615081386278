import { Dialog, Transition, DialogTitle, DialogPanel, TransitionChild } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import { getData, putData } from '../../../ApiCalls/DataApis';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import {LightTooltip} from '../../../styles/Tooltip'
import OptimizeImage from '../../OptimizeImage';
import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'


function AutoClassifyOperationsModal({btnType, remoteOpen, assetUuid, asset}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const navigate = useNavigate();
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [loading, setLoading] = useState(false)
    // const [operationsList, setOperationsList] = useState([])
    const [discreteOpsList, setDiscreteOpsList] = useState([])
    const [continousOpsList, setContinousOpsList] = useState([])
    // const [checkedOpsAutoClassify, setCheckedOpsAutoClassify] = useState([])
    const [checkedDiscreteOpsAutoClassify, setCheckedDiscreteOpsAutoClassify] = useState([])
    const [checkedContinousOpsAutoClassify, setCheckedContinousOpsAutoClassify] = useState([])
    const [noDetection, setNoDetection] = useState(null)
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)



    useEffect(() => {
      if(remoteOpen){
        setIsOpen(true)
      }
    },[remoteOpen])

    useEffect(() => {
      try {
        if(selectedOrganization && !isOpen){
          getData(apiLink + '/v1/assets/' + assetUuid + '/auto-classify-operations')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data && data.data){
                // console.log('auto-classify-operations',data.data)
                let discrete = []
                let continous = []
                data.data.map(op => {
                  if(op.operation_operation_detection_algorithm_id === 100 || op.operation_operation_detection_algorithm_id === 300 ){
                    discrete.push(op)
                  }else if(op.operation_operation_detection_algorithm_id === 200){
                    continous.push(op)
                  }else{
                    // console.log('Operation has NO Algorithm Detection ', op)
                  }
                })
                setDiscreteOpsList(discrete)
                setContinousOpsList(continous)
            }
            }
          })

        }
        
      } catch (error) {
        console.log(error.message)
      }

    },[selectedOrganization])

    useEffect(() => {
          if(discreteOpsList && Object.keys(discreteOpsList).length > 0 && asset && Object.keys(asset).length > 0){
  
              let updatedCheckedState = discreteOpsList.map((item, index) =>
              item.is_auto_classifying ? true : false
              );
  
              setCheckedDiscreteOpsAutoClassify(updatedCheckedState)

          }else {
            setCheckedDiscreteOpsAutoClassify(new Array(Object.keys(discreteOpsList).length).fill(false))
          }
  
      }, [discreteOpsList, asset])

    useEffect(() => {
          if(continousOpsList && Object.keys(continousOpsList).length > 0 && asset && Object.keys(asset).length > 0){
            
  
              let updatedCheckedState = continousOpsList.map((item, index) =>
                item.is_auto_classifying ? true : false
              );
              let allFalseResult = updatedCheckedState.every(element => !element)
              if(allFalseResult){
                setNoDetection(true)
              }else{
                setSelectedTabIndex(1)
              }
              setCheckedContinousOpsAutoClassify(updatedCheckedState)

          }else {
            setCheckedContinousOpsAutoClassify(new Array(Object.keys(continousOpsList).length).fill(false))
            setNoDetection(true)
          }
  
      }, [continousOpsList, asset])
    

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  

  const submitCurrentOperation = () => {
    try {
      if(!loading){
        setLoading(true)
        let selectedOps = []
        discreteOpsList.map((operation, index) => {
          if(checkedDiscreteOpsAutoClassify[index]){
            selectedOps.push({
              'operation_uuid':operation.operation_uuid
            })
          }
        })
        putData(apiLink + '/v1/assets/' + assetUuid + '/auto-classify-operations', 
        {'operations': selectedOps})
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              setLoading(false)
              toast.success('Discrete Auto-classification Set')
              setCheckedContinousOpsAutoClassify(new Array(Object.keys(continousOpsList).length).fill(false))
              closeModal()
            }
            else{
              try{
                setLoading(false)
                toast.error(data.message)
              }catch (e){
                toast.error(e.message)
                setLoading(false)
              }
            }
          })
      }
    } catch (error) {
      
    }
  }

  const handleChangeDiscreteAutoClassify = (position) => {
    let updatedCheckedState = checkedDiscreteOpsAutoClassify.map((item, index) =>
    index === position ? !item : item
    );
    setCheckedDiscreteOpsAutoClassify(updatedCheckedState)
    
  }


  const handleRadioButton = (uuid) => {
    try {
      if(!loading){
        setLoading(true)
        if(!uuid){
          setNoDetection(true)
          setCheckedContinousOpsAutoClassify(new Array(Object.keys(continousOpsList).length).fill(false))
        }else{
          let updatedCheckedState = continousOpsList.map((item) =>
            item.operation_uuid == uuid ? true : false
          );
          setCheckedContinousOpsAutoClassify(updatedCheckedState)
        }
        
        let selectedOps = []
        selectedOps.push({
          'operation_uuid':uuid
        })
        // console.log('selectedOps', selectedOps)
        putData(apiLink + '/v1/assets/' + assetUuid + '/auto-classify-operations', 
        {'operations': selectedOps})
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              setLoading(false)
              toast.success('Continuous Auto-Classification Set')
              if(!uuid){
                setNoDetection(true)
                setCheckedContinousOpsAutoClassify(new Array(Object.keys(continousOpsList).length).fill(false))
              }else{
                let updatedCheckedState = continousOpsList.map((item) =>
                  item.operation_uuid == uuid ? true : false
                );
                setCheckedContinousOpsAutoClassify(updatedCheckedState)
              }
              closeModal()
            }
            else{
              try{
                setLoading(false)
                toast.error(data.message)
                let updatedCheckedState = continousOpsList.map((item, index) =>
                item.is_auto_classifying ? true : false
                );
                
                setCheckedContinousOpsAutoClassify(updatedCheckedState)
              }catch (e){
                toast.error(e.message)
                setLoading(false)
                let updatedCheckedState = continousOpsList.map((item, index) =>
                item.is_auto_classifying ? true : false
                );
                
                setCheckedContinousOpsAutoClassify(updatedCheckedState)
              }
            }
          })
      }
    } catch (error) {
      
    }
  }


  return (
    <>
      <div className="flex items-center justify-center">
      {btnType ==='standardBtn' &&
        <LightTooltip title="Set operations that will be auto-classfied with IoTFlows machine learning">
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
              {/* <PlusSmIcon className='btnMainIcon' /> */}
              {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='btnMainIcon '>
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg> */}
              <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960"  fill="#fff" stroke="none"   className='btnMainIcon' >
                <path d="M495-361.174v-205.739h116.435l-30-30H495v-175q0-14.644 9.696-24.474 9.695-9.831 24.369-9.831h115.131q9.084 0 17.096 4.718 8.012 4.717 12.491 12.435L758.5-637.391h-71.869l-43.283-81.674H552.87v30h72.41l43.015 81.674H774.5l74.87 130.261q-27.196-13.435-56.517-20.153Q763.532-504 732.63-504q-76.195 0-139.413 39.076Q530-425.848 495-361.174Zm-64.065 207.152H316.043q-9.084 0-17.096-4.597-8.012-4.598-12.491-12.555L201.5-322.609h72.01l43.381 81.674h90.239v-30h-72.171l-43.254-81.674H185.5l-63-110.478q-2.478-4.478-3.598-8.405-1.12-3.927-1.12-8.474 0-2.82 4.718-17.186l63-110.239h106.205l43.254-81.674h72.171v-30h-90.145l-43.529 81.674H201.5l84.956-151.674q4.479-7.718 12.491-12.435 8.012-4.718 17.096-4.718h114.892q14.674 0 24.369 9.831 9.696 9.83 9.696 24.474v175h-86.435l-30 30H465v134.065H364.848l-39.812-81.435h-98.145l-30 30h108l40.944 81.435H465v127.935q-.804 11.087-1.587 20.755-.783 9.669-.783 21.158 0 11.283 1.381 22.685 1.38 11.402 3.141 22.685-1.043 14.587-11.576 24.097-10.533 9.511-24.641 9.511Zm301.695 35.696 115.153-115.152-23.153-22.674-75.565 75.565v-169.087h-32.63v169.087l-75.805-75.565-22.673 22.674L732.63-118.326Zm0 74.326q-77.76 0-133.88-55.5Q542.63-155 542.63-233q0-79 56.12-135t133.88-56q78 0 134 56t56 135q0 78-56 133.5t-134 55.5Z"/>
              </svg>
              <span className='btnMainText pl-0.5'>Auto-Detect</span>
          </motion.button>
        </LightTooltip>
        }
      {btnType ==='tableBtn' &&
        <LightTooltip title="Set the upcoming operation being performed on this machine">
          <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
              <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
              <span className=' text-blue-600'>Change</span>
          </motion.button>
        </LightTooltip>
        }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-xl overflow-scroll transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'} scrollbar-hide`}>
                  <div className={`flex justify-between border-b-[1px] ${isDark?'border-gray-700':'border-gray-200'}`}>
                    <h3
                      className={`p-4 text-lg font-medium leading-6  ${isDark?'text-gray-100 ':'text-gray-700'}`}
                    >
                      Select Auto-Detect Operations
                    </h3>
                      <motion.button  
                          whileHover={{ scale: 1.05 }} 
                          whileTap={{ scale: 0.95 }}  
                          className='text-white items-center bg-blue-600 hover:bg-blue-700 rounded-full px-4 my-3 ml-2 mr-4' 
                          onClick={() => navigate(`/${selectedOrganization}/production?select=parts_list`)} 
                      >
                          <span className='btnMainText'>Add/Edit Parts</span>
                      </motion.button>

                  </div>
                    {loading && 
                          <div >
                              <LinearProgress color="primary" sx={{height:2}}/>
                          </div>
                        }
                  <AnimatePresence mode='wait'>
                    <TabGroup selectedIndex={selectedTabIndex} onChange={setSelectedTabIndex}>
                      <TabList className='grid grid-cols-2 gap-0 mt-4 mb-2 text-sm '>
                        <Tab className={`${isDark? 'text-gray-300 bg-slate-900/50':'text-gray-600 bg-slate-100'} data-[selected]:bg-blue-600 data-[selected]:text-white data-[hover]:opacity-90 px-5 py-1 rounded-l-full ml-8`}>Discrete</Tab>
                        <Tab className={`${isDark? 'text-gray-300 bg-slate-900/50':'text-gray-600 bg-slate-100'} data-[selected]:bg-blue-600 data-[selected]:text-white data-[hover]:opacity-90 px-5 py-1 rounded-r-full mr-8`}>Continuous</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                        <motion.div
                          key='operationsDiscreteList'
                          initial={{ y: 10, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className='flex flex-col'
                          >
                            <div className='px-4 mt-2 h-[500px] overflow-y-scroll scrollbar-hide mx-4'>
                              {/* <p className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-sm font-medium mb-1`}>Discrete Operations List</p>  */}
                              <p className={`${isDark?'text-amber-300/80 ':' text-amber-600 '} text-xs font-normal mb-1 italic`}>*Selecting Discrete operations will disable any Continous operation previously selected</p> 
                              {discreteOpsList.map((operation,index) => (
                                <div key={operation.operation_uuid} onClick={() => handleChangeDiscreteAutoClassify(index)} className={`border-b-[0.01em] ${isDark? `border-slate-700 `:``} cursor-pointer rounded-md py-2 px-2 flex `} >
                                    <div className={`${isDark?'text-white':'text-gray-600'} text-sm items-center pr-2 flex`} >
                                      <div>
                                        <input id={`operation-checkbox-${index}`} type="checkbox" checked={checkedDiscreteOpsAutoClassify[index]} onChange={() => handleChangeDiscreteAutoClassify(index)}  className="checkboxBtn"/>
                                      </div>
                                      <div className='w-8 ml-2'>
                                          <OptimizeImage alt={operation.part_name} source={operation.part_picture_url} size='small' />
                                      </div>
                                      <div className='pl-2 flex flex-col'>
                                        <span className=''>{operation.operation_name ? `${operation.operation_name}`: ''} {operation.operation_description ? ` - ${operation.operation_description}`: ''}</span>
                                        <span className={`${isDark?'text-gray-400':'text-gray-500'}`}>{operation.part_name ? `${operation.part_name}`: ''}</span>
                                      </div>
                                    </div>
                                </div>

                              ))}
                            </div>
                            <form >
                              <div className={`flex p-4 px-5 items-center mt-1 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                                <button
                                  type="button"
                                  className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                  onClick={closeModal}
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  className={`relative ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                  onClick={submitCurrentOperation}
                                  disabled={loading}
                                >
                                  Auto-Detect
                                  {loading && 
                                      <div >
                                          <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                          </svg>
                                      </div>
                                    }
                                </button>
                              </div>
                            </form>
                          
                          </motion.div>
                        </TabPanel>
                        <TabPanel>
                            <motion.div
                              key='operationsContinousList'
                              initial={{ y: 10, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              exit={{ y: -10, opacity: 0 }}
                              transition={{ duration: 0.2 }}
                              className='flex flex-col'
                              >
                                <div className='px-4 mt-2 h-[500px] overflow-y-scroll scrollbar-hide mx-4'>
                                  {/* <p className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-sm font-medium mb-1`}>Continuous Operations List</p>  */}
                                  <p className={`${isDark?'text-amber-300/80 ':' text-amber-600 '} text-xs font-normal mb-1 italic`}>*Selecting a Continous operation will disable any Discrete operations previously selected</p> 
                                    <div onClick={() => handleRadioButton('')}  className={`cursor-pointer border-b-[0.01em] ${isDark? `border-slate-700 `:``} rounded-md py-2 px-2 flex `} >
                                        <div className={`${isDark?'text-white':'text-gray-600'} text-sm items-center pr-2 flex`} >
                                          <div>
                                            <input 
                                              className={`${isDark? 'bg-slate-700 focus:ring-blue-600 ring-offset-gray-800 border-slate-500':'bg-slate-100 border-gray-300 focus:ring-blue-500'} w-4 h-4 text-blue-500 focus:ring-2 `}
                                              type="radio"
                                              name="radio-group" // Ensures exclusive selection
                                              value={''} // Use item.value if available
                                              checked={noDetection} 
                                              onChange={() => handleRadioButton('')}
                                              disabled={loading}
                                              />
                                              
                                          </div>
                                          <div className='pl-2 flex flex-col'>
                                            <span className=''>{`No Continous Detection`}</span>
                                          </div>
                                        </div>
                                    </div>
                                  {continousOpsList.map((operation,index) => (
                                    <div key={operation.operation_uuid} onClick={() => handleRadioButton(operation.operation_uuid)}  className={`cursor-pointer border-b-[0.01em] ${isDark? `border-slate-700 `:``} rounded-md py-2 px-2 flex `} >
                                        <div className={`${isDark?'text-white':'text-gray-600'} text-sm items-center pr-2 flex`} >
                                          <div>
                                            <input 
                                              className={`${isDark? 'bg-slate-700 focus:ring-blue-600 ring-offset-gray-800 border-slate-500':'bg-slate-100 border-gray-300 focus:ring-blue-500'} w-4 h-4 text-blue-500 focus:ring-2 `}
                                              type="radio"
                                              name="radio-group" // Ensures exclusive selection
                                              value={operation.operation_uuid} // Use item.value if available
                                              checked={checkedContinousOpsAutoClassify[index]} 
                                              onChange={() => handleRadioButton(operation.operation_uuid)}
                                              disabled={loading}
                                              />
                                              
                                          </div>
                                          <div className='w-8 ml-3'>
                                              <OptimizeImage alt={operation.part_name} source={operation.part_picture_url} size='small' />
                                          </div>
                                          <div className='pl-2 flex flex-col'>
                                            <span className=''>{operation.operation_name ? `${operation.operation_name}`: ''} {operation.operation_description ? ` - ${operation.operation_description}`: ''}</span>
                                            <span className={`${isDark?'text-gray-400':'text-gray-500'}`}>{operation.part_name ? `${operation.part_name}`: ''}</span>
                                          </div>
                                        </div>
                                    </div>

                                  ))}
                                </div>
                                <form >
                              <div className={`flex p-4 px-5 items-center mt-1 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                                <button
                                  type="button"
                                  className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                  onClick={closeModal}
                                >
                                  Close
                                </button>
                              </div>
                            </form>
                          
                          </motion.div>
                        </TabPanel>
                      </TabPanels>
                    </TabGroup>

                </AnimatePresence>

                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AutoClassifyOperationsModal