import React, { useEffect, useRef, useState } from 'react'
import ChatList from './chatComponents/ChatList'
import ChatDetails from './chatComponents/ChatDetails'
import { motion, AnimatePresence } from "framer-motion";
import { getData } from '../ApiCalls/DataApis';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import { apiLink } from '../ApiCalls/ApisVariables';
import MqttService from '../core/services/MqttService'; 
import {Resizable} from 'react-resizable';
import '../styles/react-resizable.css'
import { useAsyncError } from 'react-router-dom';



function ChatsPage({mqttMessage, userInfo}) {
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
  const [chatList, setChatList] = useState([])
  const [chatMqttMessage, setChatMqttMessage] = useState([])
  const [interactionsEmojis, setInteractionsEmojis] = useState([])
  const [allEmojis, setAllEmojis] = useState([])
  const [chatUnreadDic, setChatUnreadDic] = useState({})
  const [globalSearchMessage, setGlobalSearchMessage] = useState([])

  const [leftColumnWidth, setLeftColumnWidth] = useState(300);
  const minWidth = 300;
  const maxWidth = 500;

  const handleResize = (event, { size }) => {
    const newWidth = Math.max(minWidth, Math.min(maxWidth, size.width));
    setLeftColumnWidth(newWidth);
  };

  

  

  useEffect(()=>{
      if(selectedOrganization){
  
        getChatList()

        getData(apiLink + '/v1/interaction_default_emojis')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data && data.data){
                setInteractionsEmojis(data.data)
              }
            }
          })

        getData(apiLink + '/v1/emojis')
          .then(async response => {
            const data = await response.json();
            if(response.ok ){
              if(data && data.data){
                setAllEmojis(data.data)
              }
            }
          })
        
      }
    

  }, [selectedOrganization, MqttService, MqttService.isConnected])



const getChatList = () => {
    if(selectedOrganization){

        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/chats')
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            if(data && data.data){
              console.log('setChatList', data.data)
              setChatList(data.data)
              let dic = {}
              data.data.map(chat => {
                dic[chat.chat_uuid] = {'unread_count' : chat.unread_count}
              })
              console.log('dic', dic)
              setChatUnreadDic(dic)
            }
          }
        })
      }

}


// I'm using this useEffect to see what mqtt messages are coming in and update the chatList depengin on the message
useEffect(() => {
  if(mqttMessage && chatList && Object.keys(chatList).length > 0){
    if(mqttMessage.chatroom_type === "chat"){
      setChatMqttMessage(mqttMessage)
      const targetUuid = mqttMessage.message.chat_uuid
      // console.log('targetUuid', targetUuid)
      if(targetUuid){
        let list = chatList
        const targetIndex = chatList.findIndex(
          (chat) => chat.chat_uuid === targetUuid
        ); // Find index of the target object
        // console.log('targetIndex', targetIndex)
        
        if (targetIndex !== -1) { // Check if target exists
          const targetObject = list.splice(targetIndex, 1)[0]; // Remove target object
          // console.log('targetIndex', targetIndex)
          // console.log('targetObject', targetObject) 
          if(targetObject.last_message){
            targetObject.last_message.message_text = mqttMessage.message.message_text
            targetObject.last_message.message_created_at = mqttMessage.message.message_created_at
          }else{
            targetObject.last_message = {
              message_text: mqttMessage.message.message_text,
              message_created_at: mqttMessage.message.message_created_at
            }
          }
          list.unshift(targetObject); // Prepend target object to the beginning
          // console.log('list', list)
          setChatList([...list])
        }else{ // Chat doesn't exist so refresh entire list ot make sure it is showing up for the user
          getChatList()
        }
      }
    }
    
  }

}, [mqttMessage])

  


  return (
    <div className='h-screen' style={{ display: 'grid', gridTemplateColumns: `${leftColumnWidth}px auto` }} >
        <Resizable
          width={leftColumnWidth}
          height={200} // I could add 100% since its not a number, put 200 to see what happens
          // minWidth={minWidth}
          // maxWidth={maxWidth}
          // axis='x'
          onResize={handleResize}
        >
          <div >
            <ChatList chatList={chatList} userInfo={userInfo} chatUnreadDic={chatUnreadDic} setGlobalSearchMessage={setGlobalSearchMessage} />
          </div>

        </Resizable>
        <div className='flex-1'>
          <AnimatePresence mode='wait'>
              <motion.div
                key='ChatDetailsSection'
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <ChatDetails 
                  chatList={chatList}     getChatList={getChatList}               userInfo={userInfo}           mqttMessage={chatMqttMessage} 
                  allEmojis={allEmojis}   interactionsEmojis={interactionsEmojis} chatUnreadDic={chatUnreadDic} setChatUnreadDic={setChatUnreadDic} 
                  leftColumnWidth={leftColumnWidth}   globalSearchMessage={globalSearchMessage}    setGlobalSearchMessage={setGlobalSearchMessage}
                />
              </motion.div>
          </AnimatePresence>
        </div>
    </div>
  )
}

export default ChatsPage