import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Column from './Column';


const boardData = {
  columns: [
    {
     key:'todo',
     value: {
      id:'todo',
      todos: [
        {
          id:'3412343csx',
          status:'todo',
          title: 'Take the dogs out for a walk'
        },
      ]
     }
    },
    {
     key:'inprogress',
     value: {
      id:'inprogress',
      todos: [
        {
          id:'542143csx',
          status:'inprogress',
          title: 'Finish project'
        },
        {
          id:'341zxs43csx',
          status:'inprogress',
          title: 'Submit project'
        },
      ]
     }
    },
    {
      key:'done',
      value: {
       id:'done',
       todos: []
      }
     },

  ],
}

const boardDatase = [
  {
    title:'Take the dogs out for a walk',
    status:'todo',
    image:null,
    id:'3412343csx',
  },
  {
    title: 'Finish project',
    status:'inprogress',
    image:null,
    id:'542143csx',
  },
  {
    title:'Fix machine ',
    status:'inprogress',
    image:null,
    id:'341zxs43csx',
  },
]


var columns = null 
function DashboardsPage() {
  const [board, setBoard] = useState(null)

  useEffect(() => {
    const todos = boardDatase

    columns = todos.reduce((acc, todo) => {
      if(!acc.get(todo.status)){
        acc.set(todo.status, {
          id: todo.status,
          todos: []
        })
      }

      acc.get(todo.status).todos.push({
        id: todo.id,
        title: todo.title,
        status: todo.status,
        ...(todo.image && { image: JSON.parse(todo.image) })
      })

      return acc
    }, new Map())

    // Add columns if there they have any empty todos
    const columnTypes = ['todo', 'inprogress', 'done']
    for(const columnType of columnTypes){
      if(!columns.get(columnType)) {
        columns.set(columnType, {
          id: columnType,
          todos: [],
        });
      }
    }

    // sort column by columTypes
    const sortedColumns = new Map(
      Array.from(columns.entries()).sort((a, b) => (
        (a, b) => columnTypes.indexOf(a[0]) - columnTypes.indexOf(b[0])
      ))
    )

    let tempBoard = {
      columns: sortedColumns
    }

    setBoard(tempBoard)

    
  },[])
  
  const handleOnDragEnd = (result) => {
    const {destination, source, type } = result;
    console.log('destination', destination)
    console.log('source', source)
    console.log('type', type)
    if (!destination) return;

    // Handle column drag
    if (type === 'column') {
      const entries = Array.from(board.columns.entries());
      const [removed] = entries.splice(source.index, 1);
      entries.splice(destination.index, 0, removed);
      const rearrangedColumns = new Map(entries)

      let tempBoard = {
        columns: rearrangedColumns
      }

      setBoard(tempBoard)
    }
    if(type === 'card'){
      // This step is needed as the indexes are stored as numbers 0,1,2 etc. instead of id's with DND Library
      const columns = Array.from(board.columns);
      const startColIndex = columns[Number(source.droppableId)];
      const finishColIndex = columns[Number(destination.droppableId)];
  
      // rebuilding the start and finish columns
      const startCol = {
        id: startColIndex[0],
        todos: startColIndex[1].todos
      };
  
      const finishCol = {
        id: finishColIndex[0],
        todos: finishColIndex[1].todos,
      };
  
      if(!startCol || !finishCol) return;
  
      // if you drop it in the exact same position then do nothing
      if (source.index === destination.index && startCol === finishCol) return;
  
      const newTodos = startCol.todos;
      const [todoMoved] = newTodos.splice(source.index, 1);
  
      if(startCol.id === finishCol.id) {
        // same column task drag
        newTodos.splice(destination.index, 0, todoMoved);
        const newCol = {
          id: startCol.id,
          todos:newTodos,
        };
        const newColumns = new Map(board.columns);
        newColumns.set(startCol.id, newCol);
        let tempBoard = {
          columns: newColumns
        }
        setBoard(tempBoard)
      }else {
        // dragging to another column
        const finishTodos = Array.from(finishCol.todos);
        finishTodos.splice(destination.index, 0, todoMoved);
  
        const newColumns = new Map(board.columns);
        const newCol = {
          id: startCol.id,
          todos: newTodos,
        };
  
        newColumns.set(startCol.id, newCol);
        newColumns.set(finishCol.id, {
          id: finishCol.id,
          todos: finishTodos,
        })
  
        let tempBoard = {
          columns: newColumns
        }
        setBoard(tempBoard)
      }
    }

  }
  console.log('board', board)
  

  return (
    <div>
        <div className='pl-6 pr-3 py-1 max-h-[calc(100vh-5rem)] overflow-scroll scrollbar-hide'>
        {board && 
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='board' direction='horizontal' type='column'>
              {(provided) => (
                <div
                className='grid grid-cols-1 md:grid-cols-3 gap-5  mx-auto'
                {...provided.droppableProps}
                ref={provided.innerRef}
                >
                  {
                  Array.from(board.columns.entries()).map(([id, column], index) => (
                    <Column
                      key={id}
                      id={id}
                      todos={column.todos}
                      index={index}
                    />
                  ))}
                </div>
              )}

            </Droppable>
          </DragDropContext>
        }
        </div>
    </div>
  )
}

export default DashboardsPage