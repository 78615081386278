import React from 'react';

function DisplayText({ text }) {
    const formattedMessage = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

  const formattedText = formattedMessage.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return <div>{formattedText}</div>;
}

export default DisplayText;