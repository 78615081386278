import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon, PencilIcon } from "@heroicons/react/solid"
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import { getData, putData } from '../../../ApiCalls/DataApis';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import {LightTooltip} from '../../../styles/Tooltip'
import AddCategoryButton from './AddCategoryButton';
import DowntimeCategoryButton from './DowntimeCategoryButton';
import moment from "moment"
import AssetDowntimeCard from '../../AssetDowntimeCard';
import AssetActivityCard from '../../AssetActivityCard';
import AssetUnclassifiedActivityCard from '../../AssetUnclassifiedActivityCard';

function StatusSectionPopupModal({btnType, remoteOpen, activity, refreshActivityList, operationsList, downtimeCategories, refreshDowntimeCategories, handleCloseModal}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [loading, setLoading] = useState(false)
    const [downtimeList, setDowntimeList] = useState([])
    const [selectedDowntimeCategory, setSelectedDowntimeCategory] = useState([])
    const [additionalNotes, setAdditionalNotes] = useState('')
    const [numberOfDowntimes, setNumberOfDowntimes] = useState(null)
    const [downtimeSplitTimestamp, setDowntimeSplitTimestamp] = useState(null)
    const [startMilliSeconds, setStartMilliSeconds] = useState(null)
    const [endMilliSeconds, setEndMilliSeconds] = useState(null)
    const [currentMilliSeconds, setCurrentMilliSeconds] = useState(null)
    

    useEffect(() => {
      if(remoteOpen){
        setIsOpen(true)
      }
    },[remoteOpen])

    function closeModal() {
        setIsOpen(false)
        handleCloseModal()
    }

    function openModal() {
        setIsOpen(true)
    }

    // useEffect(() => {
    //     if(selectedDowntime){
    //         var startMilliSeconds = Date.parse(selectedDowntime.utilization_start_timestamp);
    //         var endMilliSeconds = Date.parse(selectedDowntime.utilization_end_timestamp);
    //         setStartMilliSeconds(startMilliSeconds)
    //         setEndMilliSeconds(endMilliSeconds)
    //         setCurrentMilliSeconds(endMilliSeconds)

    //     }

    // }, [selectedDowntime])

  

  const submitSplit = () => {
    try {
      if(!loading  ){
        // setLoading(true)
        // toast(
        //     "Processing. This could take up to ~20 seconds",
        //     {
        //       duration: 6000,
        //     }
        //   );
        // putData(apiLink + '/v1/utilizations/' + selectedDowntime.utilization_id + '/split' , 
        // {'utilization_split_timestamp': currentMilliSeconds})
        //   .then(async response => {
        //     const data = await response.json();
        //     if(response.ok){
        //       // const data = await response.json();
        //       refreshActivityList()
        //       setLoading(false)
        //       closeModal()
        //       toast.success('Downtime split')
        //     }
        //     else{
        //       try{
        //         const data = await response.json();
        //         setLoading(false)
        //         toast.error(data.message)
        //       }catch (e){
        //         toast.error('Something went wrong')
        //         setLoading(false)
        //       }
        //     }
        //   })
      }
    } catch (error) {
      
    }
  }

//   const formatDate = (end) => {
//     if(end){
//         var endDate = new Date(end)
//         var endHours = endDate.getHours();
//         var endMinutes = endDate.getMinutes();
//         var endAmPm = endHours >= 12 ? 'pm' : 'am';
//         endHours = endHours % 12;
//         endHours = endHours ? endHours : 12; // the hour '0' should be '12'
//         endMinutes = endMinutes < 10 ? '0'+endMinutes : endMinutes;
//         var endStrTime = endHours + ':' + endMinutes + ' ' + endAmPm;
//         var shortEndDate = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2)
//         // var endTime = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2) + " " + endStrTime;
//         // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
//         return endStrTime;
//         // return shortEndDate + "  " + endStrTime;
//     }
//   }

//   const formatTime = (seconds) => {
//     if(seconds){
//       let formatted = moment.utc(seconds*1000).format('HH:mm:ss')
//       return formatted
//     }else{
//         let formatted = moment.utc(0).format('HH:mm:ss')
//         return formatted
//     }
//   }

//   const handleChangeInput = (e) => {
//     let valueNumber = Number(e.target.value)
//     setCurrentMilliSeconds(valueNumber)
//   }

const formatDate = (end) => {
  if(end){
      // var startDate = new Date(start)
      // var startHours = startDate.getHours();
      // var startMinutes = startDate.getMinutes();
      // var startAmPm = startHours >= 12 ? 'pm' : 'am';
      // startHours = startHours % 12;
      // startHours = startHours ? startHours : 12; // the hour '0' should be '12'
      // startMinutes = startMinutes < 10 ? '0'+startMinutes : startMinutes;
      // var startStrTime = startHours + ':' + startMinutes + ' ' + startAmPm;
      // var shortStartDate = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2)

      var endDate = new Date(end)
      var endHours = endDate.getHours();
      var endMinutes = endDate.getMinutes();
      var endAmPm = endHours >= 12 ? 'pm' : 'am';
      endHours = endHours % 12;
      endHours = endHours ? endHours : 12; // the hour '0' should be '12'
      endMinutes = endMinutes < 10 ? '0'+endMinutes : endMinutes;
      var endStrTime = endHours + ':' + endMinutes + '' + endAmPm;
      var shortEndDate = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2)
      // var endTime = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2) + " " + endStrTime;
      // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
      return endStrTime;
      // return shortEndDate + "  " + endStrTime;

      // if(shortStartDate == shortEndDate){
      //     return shortStartDate + "  " + startStrTime +  " - " + endStrTime;
      // }else{
      //     return shortStartDate + " " + startStrTime + " - " + shortEndDate + " " + endStrTime;
      // }
  }
}
  

  return (
    <>
      <div className="flex items-center justify-center">
      {btnType ==='standardBtn' &&
        // <LightTooltip title="Categorize downtime">
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className={`flex relative border-[1px] ${isDark ? 'text-gray-300  border-gray-400 hover:text-gray-100 hover:border-gray-300':'text-gray-500 border-gray-400 hover:text-gray-600 '}  items-center bg-transparent  rounded py-1 pl-1 pr-3 w-max`} onClick={openModal}>
              {/* <PlusSmIcon className='btnMainIcon' /> */}
              {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='btnMainIcon '>
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg> */}
              <span className='text-sm pl-2'></span>
          </motion.button>
        // </LightTooltip>
        }
        {btnType ==='inlineIconBtn' &&
          <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={openModal}>
            <svg className={`w-5 h-5 rotate-90`} stroke='none' fill='currentColor'  viewBox="0 0 24 24" >
                <path d="m9.78 11.16-1.42 1.42c-.68-.69-1.34-1.58-1.79-2.94l1.94-.49c.32.89.77 1.5 1.27 2.01zm.37-6.01-2.8-2.8c-.2-.2-.51-.2-.71 0l-2.79 2.8c-.31.31-.09.85.36.85h1.81c.02.81.08 1.54.19 2.17l1.94-.49C8.08 7.2 8.03 6.63 8.02 6H9.8c.44 0 .66-.54.35-.85zm10 0-2.79-2.79c-.2-.2-.51-.2-.71 0l-2.79 2.79c-.32.31-.1.85.35.85h1.78c-.1 3.68-1.28 4.75-2.54 5.88-.5.44-1.01.92-1.45 1.55-.34-.49-.73-.88-1.13-1.24L9.46 13.6c.93.85 1.54 1.54 1.54 3.4v4c0 .55.45 1 1 1s1-.45 1-1v-4c0-2.02.71-2.66 1.79-3.63 1.38-1.24 3.08-2.78 3.2-7.37h1.8c.45 0 .67-.54.36-.85z"></path>
            </svg>
          </button>
          }
      {btnType ==='tableBtn' &&
        <LightTooltip title="Classify asset downtime">
          <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
              <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
              <span className=' text-blue-600'>{``}</span>
          </motion.button>
        </LightTooltip>
        }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-2xl overflow-scroll scrollbar-hide transform rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <AnimatePresence mode='wait'>
                      <motion.div
                        key='classifySelectedDowtime'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='my-2 max-h-[800px] overflow-y-scroll'
                      >
                        <h6 className={`mb-1 px-4 py-2  text-base font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}>
                        {`${activity.utilization_state_identifier} ${formatDate(activity.utilization_start_timestamp)} - ${formatDate(activity.utilization_end_timestamp)}`} 
                        </h6>
                        <div className='mx-4 py-2'>
                            {assetActivityStatus(activity, refreshActivityList, operationsList, downtimeCategories, refreshDowntimeCategories, handleCloseModal)}
                        </div>

                        <div className={`flex p-3 px-5 items-center mt-1 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                        <button
                          type="button"
                          className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        {/* <button
                          type="submit"
                          className={`relative ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-600':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                          onClick={submitSplit}
                          disabled={loading }
                        >
                          Split 
                          {loading && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                            }
                        </button> */}
                      </div>
                      
                      </motion.div>

                </AnimatePresence>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default StatusSectionPopupModal



const assetActivityStatus = (activity, refreshActivityList, operationsList, downtimeCategories, refreshDowntimeCategories, handleCloseModal) => {
    // console.log('activity', activity)
    if(activity.utilization_state_id_modified == '2' || activity.utilization_state_id_modified == '5'){
        return (
            <div key={activity.utilization_id}>
                <AssetDowntimeCard downtimeItem={activity} refreshActivityList={refreshActivityList} operationsList={operationsList} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories} handleCloseModal={handleCloseModal} />
            </div>
        )

    }else if(activity.utilization_state_id_modified == '3'){
        return (
            <div key={activity.utilization_id}>
                <AssetUnclassifiedActivityCard activityItem={activity} refreshActivityList={refreshActivityList} operationsList={operationsList} handleCloseModal={handleCloseModal} />
            </div>
        )

    }else if(activity.utilization_state_id_modified == '4'){
        return (
            <div key={activity.utilization_id}>
                <AssetActivityCard activityItem={activity} refreshActivityList={refreshActivityList} operationsList={operationsList} handleCloseModal={handleCloseModal} />
            </div>
        )

    }

}