import React from 'react'
import InteractionsComponent from '../utils/InteractionsComponent'
import { formatDateToAmPm } from '../../../utils/DateFormating'

function SenderEmojisOnlyMessageComponent({message, scrollToMessage, emojiTextSize, userInfo, updateEmoji, participants}) {
    
  return (
    <div 
        className={` pl-4 pr-4 py-1.5 rounded-2xl max-w-[500px] flex flex-wrap items-center mr-2 order-1 bg-transparent`}
            // ${message.user_username === userInfo.user_username ? 'mr-2 order-1':'ml-2 order-2'} 
        // style={handleMessageBgCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
    >
        {message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid &&
            <div  className=' bg-sky-600 dark:bg-sky-800 w-full rounded-t-2xl rounded flex mt-1 cursor-pointer text-sm px-3 py-2' onClick={() => scrollToMessage(message.message_replied_from.message_uuid)}>
                <div className={`bg-white dark:bg-slate-200 w-[3px] min-w-[3px] rounded-l-full`} />
                <div className={`bg-white/20 dark:bg-slate-200/20  rounded-r-lg pl-2 py-0.5 pr-2 flex flex-col truncate overflow-hidden grow`}>
                    <span className={`text-white dark:text-slate-100 `}>{`${message.message_replied_from.user_first_name? message.message_replied_from.user_first_name : ''} ${message.message_replied_from.user_last_name ? message.message_replied_from.user_last_name : ''}`}</span>
                    <span className={`text-gray-200 dark:text-gray-200 truncate `} >{message.message_replied_from.message_text}</span>
                </div>
            </div>
        }
        <div className='flex flex-col w-full'>
            <span style={{fontSize: emojiTextSize}} className='text-end'>{message.message_text}</span>
            <span className={`text-gray-500 dark:text-gray-300 text-xs ml-auto mt-auto text-right italic pl-3  pr-2 text-nowrap`}>
            {message.message_is_edited && 'edited  '}{formatDateToAmPm(message.message_created_at)}
            </span>
                <div className='flex pt-1 justify-end'>
                    <InteractionsComponent message={message} userInfo={userInfo} updateEmoji={updateEmoji} participants={participants} />
                </div>
            
        </div>
    </div>
  )
}

export default SenderEmojisOnlyMessageComponent