import React, { useEffect, useState } from 'react'
import ScrapsBarChart from '../../components/charts/ScrapsBarChart';
import OptimizePartImage from '../../components/OptimizePartImage';
import { LightTooltip } from '../../styles/Tooltip';
import ProgressGaugeLarge from '../../components/charts/ProgressGaugeLarge';
import EditShiftGoalModal from '../../components/modals/partModals/EditShiftGoalModal';

function AssetOperationRow({operation,getOpsList,  selectedAsset, dateCategories, currentProgress}) {
    const [totalPartsNum, setTotalPartsNum] = useState(0)
    const [totalPartsProgress, setTotalPartsProgress] = useState(0)
    const [totalCurrentPartsGoal, setTotalCurrentPartsGoal] = useState(0)
    const [shiftGoal, setShiftGoal] = useState(0)
    const [hrGoal, setHrGoal] = useState(0)
    const [openEditShiftGoalModal, setOpenEditShiftGoalModal] = useState(false)

    useEffect(() => {
        if(operation.overridden_good_count){
            setTotalPartsNum(operation.overridden_good_count)
        }
        if(operation.asset_operation_hourly_goal_count){
            setHrGoal(operation.asset_operation_hourly_goal_count)
        }
        if(currentProgress && operation.overridden_good_count){
            let shiftGoal = 1
            if(operation.asset_operation_shift_goal_count){
                shiftGoal = operation.asset_operation_shift_goal_count
                setShiftGoal(operation.asset_operation_shift_goal_count)
            }
            let progressPercent = (operation.overridden_good_count/shiftGoal) * 100
            setTotalPartsProgress(progressPercent)
    
            
            let currentPartGoal = shiftGoal * currentProgress
            currentPartGoal = Math.floor(currentPartGoal)
            setTotalCurrentPartsGoal(currentPartGoal)

        }
        

    }, [operation, currentProgress])
    

    const addCommasInNumbers = (num) => {
        if(num){
            const out = num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

            // const out = num.toLocaleString();
    
            return out
        }else{
            return '0'
        }
    }

    const formatCycleTime = (value) => {
        if(value){
            if(value.includes('.'))
            {
                value = value.split('.')[0] + '.' + value.split('.')[1].slice(0, 2);
            }
            return value
        }else return 0

    }

    const handleCloseEditShiftGoalModal = () => {
        setOpenEditShiftGoalModal(false)
    }

    // console.log('operation', operation)

  return (
    <div className='mt-1.5 flex flex-col rounded-2xl bg-white dark:bg-slate-900/50 shadow-iotflows-lg overflow-hidden'>
        <div className='flex items-center'>
            <div className='bg-transparent p-1 w-full flex justify-between'>
                <div className='flex items-center w-full '>
                    <div className='ml-4'>
                        {operation && Object.keys(operation).length > 0 &&
                            <OptimizePartImage alt={operation.part_name} source={operation.part_picture_url} size='xl' />
                        }
                    </div>
                    <div className='text-gray-600 dark:text-gray-300 py-2 pr-2 pl-2 w-[150px]'>
                        <div className='flex flex-col'>
                            <p className='text-left leading-5 truncate text-lg text-gray-600 dark:text-gray-300 font-medium'>{operation.operation_name}</p>
                            <p className='text-left leading-5 truncate text-base text-gray-500 dark:text-gray-400 font-medium'>{operation.operation_description}</p>
                            <p className='text-left truncate text-sm text-gray-500 dark:text-gray-400 font-normal'>{operation.part_name}</p>
                        </div>
                    </div>
                </div>
                </div>
                <div className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 font-medium' >
                    <div className='flex mx-auto'>
                        <span className='text-xl leading-4 mx-auto text-gray-600 dark:text-gray-300 '>{`${addCommasInNumbers(operation.overridden_good_count)}`}</span>
                    </div>
                    <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400 '>Good</div>
                </div>
                <div className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 font-medium' >
                    <div className='flex mx-auto'>
                        <span className='text-xl leading-4 text-gray-600 dark:text-gray-300'>{`${addCommasInNumbers(operation.overridden_bad_count)}`}</span>
                    </div>
                    <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'>Bad</div>
                </div>
                <button 
                    onClick={() => setOpenEditShiftGoalModal(true)} 
                    className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 items-center  font-medium' 
                >
                    <span className='text-xl leading-4 text-blue-500 dark:text-blue-400'>{hrGoal}</span>
                    <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'>Hourly Goal</div>
                </button>
                <div className='flex flex-col w-full justify-center  font-medium' >
                    <div className='flex mx-auto'>
                        <span className='text-xl leading-4 text-gray-600 dark:text-gray-300'>{formatCycleTime(operation.cycle_time)}</span>
                    </div>
                    <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'>Cycle Time</div>
                </div>
        </div>
        <div className='w-full flex'>
            <div className='flex-1'>
                <ScrapsBarChart operation_uuid={operation.operation_uuid} countArray={operation.operations_made} hourlyGoal={operation.asset_operation_hourly_goal_count} dateCategories={dateCategories} height='150px'/>
            </div>
            <div >
            <ProgressGaugeLarge stringValue={`${totalPartsNum}`} unit={'made'} numericValue={totalPartsProgress} currentCount={totalPartsNum} goalValue={currentProgress} thresholdValue={totalCurrentPartsGoal} shiftGoal={shiftGoal} />
            </div>
        </div>
        {openEditShiftGoalModal &&
            <EditShiftGoalModal btnType={'hideBtn'} remoteOpenModal={openEditShiftGoalModal} operation={operation} assetOp={selectedAsset} goal={operation.asset_operation_hourly_goal_count} refreshList={getOpsList} setHrGoal={setHrGoal} handleCloseEditShiftGoalModal={handleCloseEditShiftGoalModal} />
        }
    </div>
  )
}

export default AssetOperationRow