import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { globalSelectedOrganization, globalSelectedOrganizationTimezone } from '../../atoms/orgStates';
import StackedBarChartLargeParts from '../../components/charts/PartsHistoricalReportsCharts/StackedBarChartLargeParts';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { getData } from '../../ApiCalls/DataApis';
import PartsHistoricalReportTableRow from './PartsHistoricalReportTableRow'
import StackedBarChartLargeBlue from '../../components/charts/StackedBarChartLargeBlue'
import DateRangeFilterAdvancedBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/DateRangeFilterAdvancedBtn'
import ShiftMinutesFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/ShiftMinutesFilterBtn'
import PartOperationFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/PartOperationFilterBtn'
import { LinearProgress, Switch } from '@mui/material'
import ShiftSelectionFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/ShiftSelectionFilterBtn';
import { useLocation, useSearchParams } from 'react-router-dom';
import { LightTooltip } from '../../styles/Tooltip';

function PartsHistoricalReportPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const selectedOrganizationTimeZone = useRecoilValue(globalSelectedOrganizationTimezone);
    const [opsList, setOpsList] = useState([])
    const [historicalOpsList, setHistoricalOpsList] = useState([])
    const [mainAllData, setMainAllData] = useState([])
    const [loadingDetailedData, setLoadingDetailedData] = useState(false)
    const [loadingMainChartData, setLoadingMainChartData] = useState(false)
    const [dateCategories, setDateCategories] = useState([])
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [toggleShowScrap, setToggleShowScrap] = useState(false)


    useEffect(() => {
        if(selectedOrganization){
            getDetailedOperationsData()
        }
    }, [selectedOrganization, location])

    
    const getDetailedOperationsData = () => {
        let currentSearch = location.search
        if(selectedOrganization && currentSearch){
            let fromDate = searchParams.get('from')
            let toDate = searchParams.get('to')
            let period = searchParams.get('time_period')
            // forcing to have a date in the url to pull date, this is because of how long it takes the 6 weeks to load
            if(period != null && toDate != null && fromDate != null && !loadingDetailedData){
                setDateCategories([])
                setOpsList([])
                setMainAllData([])
                setLoadingDetailedData(true)
                setLoadingMainChartData(true)

                // I'm just using this to fill out the test data REMOVE LATER
                // New API (2 Layer) that removed parts and just shows operations and machines
                
                getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/histocal_production' + currentSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            // console.log('OPS LISt ', data.data)
                            setDateCategories(data.data.categories)
                            setOpsList(data.data.operations)
                            setLoadingDetailedData(false)

                            // const [goodChartData, badChartData] = processData(data);
                            // const allData = processData(data);
                            setMainAllData(data)
                            setLoadingMainChartData(false)

                        }
                    }
                })
            
            }

        }

    }

    function processData(data) {
        let allData = [];
        if(data && data.data && data.data.operations && Object.keys(data.data.operations).length > 0){
            data.data.operations.map(operation => {
                // goodSeriesData.push({
                //     name: operation.operation_name,
                //     data: operation.data_good,
                //     pointPadding: 0,
                //     stack:'good'
                // });
                // badSeriesData.push({
                //     name: `Scrap ${operation.operation_name}`,
                //     data: operation.data_bad,
                //     pointPadding: 0,
                //     stack:'bad'
                // });
                allData.push({
                    name: operation.operation_name,
                    data: operation.data_good,
                    pointPadding: 0,
                    stack:'Good'
                },{
                    name: `Scrap ${operation.operation_name}`,
                    data: operation.data_bad,
                    pointPadding: 0,
                    color: 'rgba(220, 38, 38, 0.5)',
                    stack:'Bad'
                })
            });
            
        }
        return allData ;
        // return [goodSeriesData,badSeriesData] ;
      }

    function formatTime(date) {
        let event = new Date(date)
        let currentTime = event.getTime();

        return currentTime
      }

    const handleShowScrap = (e) => {
        setToggleShowScrap(e.target.checked)
    }


  return (
    <div className='mt-4 h-screen '>
        <div className='flex w-full '>
            <div className='flex space-x-2 items-center'>
                <PartOperationFilterBtn select='historical_report' />
                <ShiftSelectionFilterBtn select='historical_report' />
                {/* <ShiftMinutesFilterBtn select='historical_report' /> */}
            </div>
            <div className='ml-auto flex items-center'>
                <div className='mr-1'>
                    <LightTooltip title='Show scrap will display all scrap data inputted from users for the specific date range selected'>
                        <div className='flex items-center mt-2 sm:mt-0 ml-auto'>
                            <p className='text-gray-600 dark:text-gray-400 text-sm'>Show Scrap</p>
                            <Switch
                                size="small"
                                color="secondary"
                                checked={toggleShowScrap}
                                onChange={handleShowScrap}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </LightTooltip>
                </div>
                <DateRangeFilterAdvancedBtn /> 
            </div>
        </div>
        <div className='py-1'>
            <p className='text-gray-600 dark:text-gray-300 italic text-sm font-light'>{`This report was ran on the organizations time zone of ${selectedOrganizationTimeZone.name}, dates and times may vary from your local time zone.`}</p>
        </div>
        <div className='mt-2'>
            <div className='rounded-2xl bg-white dark:bg-slate-800 w-full shadow-iotflows-lg overflow-hidden'>
            {loadingMainChartData &&
                    <div>
                        {/* <LinearProgress color="primary" sx={{height:2}}/> */}
                        <div className='mt-2 flex-flex-col rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg p-4'>
                        <div className="animate-pulse flex space-x-4">
                            <div className="flex-1 space-y-4 py-1">
                            <div className="h-6 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            <div className="h-6 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            <div className="space-y-4">
                                <div className="grid grid-cols-3 gap-4">
                                <div className="h-6 bg-slate-300 dark:bg-slate-700 rounded-xl col-span-2"></div>
                                <div className="h-6 bg-slate-300 dark:bg-slate-700 rounded-xl col-span-1"></div>
                                </div>
                                <div className="h-6 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                }
                {mainAllData && Object.keys(mainAllData).length > 0 && 
                    <StackedBarChartLargeParts toggleShowScrap={toggleShowScrap} dateCategories={dateCategories} mainAllData={mainAllData} />
                }

            </div>
        </div>
        {/* Operations tables */}
        <div className='flex flex-col pb-10 mt-2'>
            <div className='rounded-full'>
                {loadingDetailedData &&
                    <div>
                        {/* <LinearProgress color="primary" sx={{height:2}}/> */}
                        <div className='mt-2 flex-flex-col rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg p-4'>
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-14 w-14"></div>
                            <div className="flex-1 space-y-6 py-1">
                            <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded-xl col-span-2"></div>
                                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded-xl col-span-1"></div>
                                </div>
                                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                }
                {!loadingDetailedData && !opsList &&
                    <div>
                        {/* <LinearProgress color="primary" sx={{height:2}}/> */}
                        <div className='mt-2 flex-flex-col rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg p-4'>
                            <h6 className='text-gray-600 dark:text-gray-300 text-2lx text-center text-light'>
                                { `(;-;)`}
                            </h6>
                            <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                                No part production data
                            </h6>
                            <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                                is available for selected time period.
                            </h6>
                        </div>
                    </div>
                }
            </div>
                {!loadingDetailedData && opsList && Object.keys(opsList).length > 0 &&
                    opsList.map((operation,index) => (
                        <PartsHistoricalReportTableRow toggleShowScrap={toggleShowScrap} operation={operation} key={operation.operation_uuid} dateCategories={dateCategories} />
                    ))
                }
        </div>
    </div>
  )
}

export default PartsHistoricalReportPage