 // Create the topic and payload according to "action"
 export function createMessagingMQTT(msg)
 {
    switch(msg.action) 
    {
        // Store a draft message for this chatroom
        case 'draft_message':
            msg.topic = `v1/iotflows/from_user/${msg.from_username}/to_chatroom/${msg.to_chatroom_uuid}/actions/${msg.action}`
            msg.payload = {
                "message_text": msg.message_text
            }
            break;    
        
        // Post a message to this chatroom
        case 'post_message':
            msg.topic = `v1/iotflows/from_user/${msg.from_username}/to_chatroom/${msg.to_chatroom_uuid}/actions/${msg.action}`
            msg.payload = {
                "message_uuid": `msg_${msg.from_username}_${Date.now()}_${parseInt(Math.random()*100000)}`,
                "message_text": msg.message_text,
                "reply_from_message_uuid": msg.reply_from_message_uuid,
                "message_file_uuid": msg.message_file_uuid,
            }
            break;
        
        // Edit a message test
        case 'edit_message':
            msg.topic = `v1/iotflows/from_user/${msg.from_username}/messages/${msg.message_uuid}/actions/${msg.action}`
            msg.payload = {
                "message_uuid": msg.message_uuid,
                "message_text": msg.message_text
            }
            break;
            
        // Delete message
        case 'delete_message':
            msg.topic = `v1/iotflows/from_user/${msg.from_username}/messages/${msg.message_uuid}/actions/${msg.action}`
            msg.payload = {
                "message_uuid": msg.message_uuid
            }
            break;
            
        // Delete message for me only
        case 'delete_message_for_me':
            msg.topic = `v1/iotflows/from_user/${msg.from_username}/messages/${msg.message_uuid}/actions/${msg.action}`
            msg.payload = {
                "message_uuid": msg.message_uuid
            }
            break;
            
        // Set emoji interaction to message
        case 'update_emoji_interaction':
            msg.topic = `v1/iotflows/from_user/${msg.from_username}/messages/${msg.message_uuid}/actions/${msg.action}`
            msg.payload = {
                "emoji_codepoints": msg.emoji_codepoints || ''
            }
            break;
            
    }
    return msg
}