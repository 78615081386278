import { Dialog, Transition, TransitionChild, DialogTitle, DialogPanel } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../../../atoms/darkModeState'
import { apiLink } from '../../../../ApiCalls/ApisVariables';
import { toast } from 'react-hot-toast';
import { PencilIcon } from "@heroicons/react/solid"
import { globalSelectedOrganization } from '../../../../atoms/orgStates';
import { useLocation, useParams } from 'react-router-dom';
import { getData } from '../../../../ApiCalls/DataApis';
import OptimizePartImage from '../../../OptimizePartImage';
import ModifyOperationCountSection from './ModifyOperationCountSection';
import OptimizeImage from '../../../OptimizeImage';




function ModifyOperationsCountsModal({btnType, remoteOpen, handleCloseModal, getOpsList}) {
  const { assetId } = useParams()
  const location = useLocation();
  const isDark = useRecoilValue(darkModeState);
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
  const [isOpen, setIsOpen] = useState(false)
  const [dateCategories, setDateCategories] = useState([])
  const [opsList, setOpsList] = useState([])
  const [isAddingOp, setIsAddingOp] = useState(false)
  const [allOpsList, setAllOpsList] = useState([])
  const [timeIntervals, setTimeIntervals] = useState([])
  const [isLoadingOps, setIsLoadingOps] = useState(false)

  
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {

      setIsOpen(false)
      getOpsList()
      if(handleCloseModal){
        handleCloseModal()
      }
  }

  useEffect(() => {
    if(remoteOpen){
      openModal()
    }

  }, [remoteOpen])


  useEffect(() => {
    if(assetId && selectedOrganization){
      try{
        let currentSearch = location.search
        if(currentSearch){
            currentSearch += '&time_interval_min=30'
        }else{
            currentSearch = '?time_interval_min=30'
        }
          if(!isLoadingOps){
            setIsLoadingOps(true)
            getData(apiLink + '/v1/assets/'+ assetId + '/operations_made_by_interval' + currentSearch  )
            .then(async response => {
                // JSON data parsed by response.json() call
                const data = await response.json();
                if(response.ok ){
                    if(data && data.data){
                        
                        setOpsList(data.data)

                        let timeCategoriesAll = []
                        let timeCategoriesTrimmed = []
                        // console.log('data.data', data.data)
                        if(data.data[0] && Object.keys(data.data[0]).length > 0 && data.data[0].operations_made && Object.keys(data.data[0].operations_made).length > 0){
                          let lastDataIndex = Object.keys(data.data[0].operations_made).length -1
                          data.data[0].operations_made.map((hourCount, index) => {
                              let time = ''
                              if(index === 0) {
                                  time = formatAMPM(hourCount.interval_timestamp)
                                  timeCategoriesTrimmed.push(time)
                              }else if(index === lastDataIndex){
                                  time = formatAMPM(hourCount.interval_timestamp)
                                  timeCategoriesTrimmed.push(time)
                              }else{
                                  timeCategoriesTrimmed.push(time)
                              }
  
                              let currentTime = formatAMPM(hourCount.interval_timestamp)
                              timeCategoriesAll.push(currentTime)
  
                          })
                        }

                        // console.log('timeCategoriesTrimmed', timeCategoriesTrimmed)
                        setDateCategories(timeCategoriesAll)
                        setIsLoadingOps(false)
    
    
                    }else{
                      setIsLoadingOps(false)
                    }
                }
            })
          }

        getData(apiLink + '/v1/assets/' + assetId + '/auto-classify-operations')
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            if(data && data.data){
              setAllOpsList(data.data)
              
            }
          }
        })

        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/shift_time_intervals' + currentSearch)
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            if(data && data.data){
              // console.log('timeIntervals',data.data)
              // const options = {
              //   year: 'numeric',
              //   month: '2-digit',
              //   day: '2-digit',
              //   hour: '2-digit',
              //   minute: '2-digit',
              //   second: '2-digit',
              // };
              // const date = new Date(data.data[0].interval_timestamp);
              // console.log('date locatime',date.toLocaleDateString('en-US', options))
              setTimeIntervals(data.data)
            }
          }
        })

      }catch(e){
        setIsLoadingOps(false)
        toast.error(e.message)
      }
       
       
    }
  }, [assetId, location, isOpen, selectedOrganization])

  


function formatAMPM(date) {
  let event = new Date(date)
  let currentTime = event.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
  
  currentTime = currentTime.replace(/\s/g, '')
  if(currentTime[0] == '0'){
      currentTime = currentTime.substring(1)
  }
  return currentTime

}


const handleAddNewOperation = (op) => {
  let list = opsList
  let opsMade = []
  
  timeIntervals.map((time) => (
    opsMade.push({
      overridden_good_count: null,
      overridden_bad_count: null,
      operation_count: 0,
      interval_timestamp: time.interval_timestamp
    })
  ))

  let newOp = op
  newOp.operations_made = opsMade
  list.unshift(newOp)
  setOpsList(list)
  setIsAddingOp(false)
}


  return (
    <>
      <div className="flex items-center justify-center">
        {btnType ==='standardBtn' &&
          <motion.button
          whileHover={{ scale: 1.05  }} 
          whileTap={{ scale:0.95 }}  
          className='btnMain disabled:opacity-50 pl-2' 
          onClick={openModal}
        >
            <PencilIcon className='btnMainIcon mr-1 ' />
            <span className='btnMainText'>Modify Counts</span>
        </motion.button>
          }
        {btnType ==='inlineIconBtn' &&
          <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto disabled:text-gray-500`} onClick={openModal}>
            <PencilIcon className={`w-5 h-5`}/>
          </button>
          }
        {btnType ==='tableBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2 disabled:text-gray-500' onClick={openModal} >
            <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
            <span className=' text-blue-600'>Modify</span>
        </motion.button>
          }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          } 
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-[90%] transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <div
                    className={`flex mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    <h3
                      className={`text-lg font-medium leading-6  ${isDark?'text-gray-100 ':'text-gray-700 '}`}
                    >
                      Modify Counts
                    </h3>
                    <div className='ml-auto'>
                      {isAddingOp ? (
                        <motion.button 
                        key='CancelAddButton'
                        whileHover={{ scale: 1.05  }} 
                        whileTap={{ scale:0.95 }}  
                        className={`text-sm cursor-pointer ${isDark?'text-blue-400 ':'text-blue-500 '} `}
                        onClick={() => setIsAddingOp(false)}
                      >
                        Cancel Add
                      </motion.button>
                      ):(
                      <motion.button 
                      key='AddOpButton'
                        whileHover={{ scale: 1.05  }} 
                        whileTap={{ scale:0.95 }}  
                        className={`text-sm cursor-pointer ${isDark?'text-blue-400 ':'text-blue-500 '} `}
                        onClick={() => setIsAddingOp(true)}
                      >
                        + Add Operation
                      </motion.button>
                      )}
                      
                    </div>
                  </div>
                  <AnimatePresence mode='wait'>
                    {!isAddingOp ? (
                      <motion.div
                          key='scrapSection'
                          initial={{ y: 10, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                        > 
                          <div className='space-y-10'>
                            {isLoadingOps  &&
                              <div>
                                <div className={`mt-2 flex-flex-col rounded-xl ${isDark ? 'bg-slate-800':'bg-white'} shadow-iotflows-lg p-4`}>
                                <div className="animate-pulse flex space-x-4">
                                    <div className={`rounded-full ${isDark ? 'bg-slate-700':'bg-slate-300'} h-14 w-14`}></div>
                                    <div className="flex-1 space-y-6 py-1">
                                    <div className={`h-8 ${isDark ? 'bg-slate-700':'bg-slate-300'} rounded-xl`}></div>
                                    <div className="space-y-3">
                                        <div className="grid grid-cols-3 gap-4">
                                        <div className={`h-8 ${isDark ? 'bg-slate-700':'bg-slate-300'} rounded-xl col-span-2`}></div>
                                        <div className={`h-8 ${isDark ? 'bg-slate-700':'bg-slate-300'} rounded-xl col-span-1`}></div>
                                        </div>
                                        <div className={`h-8 ${isDark ? 'bg-slate-700':'bg-slate-300'} rounded-xl`}></div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            }
                          {!isLoadingOps && opsList && Object.keys(opsList).length > 0 &&
                              opsList.map((operation, index) => (
                                  <ModifyOperationCountSection  index={index} operation={operation} key={operation.operation_uuid} dateCategories={dateCategories} isDark={isDark} assetId={assetId} />
                              ))
                          }
                          {!isLoadingOps && opsList && Object.keys(opsList).length == 0 &&
                              <div>
                                  {/* <LinearProgress color="primary" sx={{height:2}}/> */}
                                  <div className={`mt-2 flex-flex-col rounded-xl ${isDark ? 'bg-slate-800':'bg-white'} shadow-iotflows-lg p-4`}>
                                      <h6 className={`${isDark ? 'text-gray-300':'text-gray-600'} text-2lx text-center text-light`}>
                                          { `(;-;)`}
                                      </h6>
                                      <h6 className={`${isDark ? 'text-gray-300':'text-gray-600'} text-center text-light`}>
                                          No part production data
                                      </h6>
                                      <h6 className={`${isDark ? 'text-gray-300':'text-gray-600'} text-center text-light`}>
                                          is available for selected time period.
                                      </h6>
                                  </div>
                              </div>
                          }

                          </div>
                          <form >
                            <div className={`flex p-4 px-5 items-center mt-2 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                              <button
                                type="button"
                                className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                onClick={closeModal}
                              >
                                Close
                              </button>
                            </div>
                          </form>
                      </motion.div>
                    ): (
                      <motion.div
                        key='addOpSection'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <div className='px-2 mt-2 h-[500px] overflow-y-scroll scrollbar-hide'>
                          <button onClick={()=> setIsAddingOp(false)} >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                              className={`h-7 w-7 p-0.5 rounded-full transition duration-300 ease-in-out ${isDark?'text-gray-400 hover:bg-gray-700':'text-gray-500 hover:bg-gray-200'} hover:-translate-y-0.5 ml-[-10px] mb-1`} 
                            >
                                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                          </button>
                          {allOpsList.map((operation,index) => (
                              <button key={operation.operation_uuid} onClick={() => handleAddNewOperation(operation)} className={`border-b-[0.01em] ${isDark? `border-slate-700 hover:bg-slate-900 `:``} w-full rounded-full py-1.5  px-2 flex `} >
                                  <div className={`${isDark?'text-white':'text-gray-600'} text-sm items-center pr-2 flex`} >
                                    <div className='w-8 '>
                                        <OptimizeImage alt={operation.part_name} source={operation.part_picture_url} size='small' />
                                    </div>
                                    <div className='pl-2 flex flex-col'>
                                      <span className='mr-auto'>{operation.operation_name ? `${operation.operation_name}`: ''} {operation.operation_description ? ` - ${operation.operation_description}`: ''}</span>
                                      <span className={`${isDark?'text-gray-400':'text-gray-500'} mr-auto`}>{operation.part_name ? `${operation.part_name}`: ''}</span>
                                    </div>
                                  </div>
                              </button>

                            ))}
                        </div>
                        <form >
                            <div className={`flex p-4 px-5 items-center mt-2 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                              <button
                                type="button"
                                className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                onClick={closeModal}
                              >
                                Close
                              </button>
                            </div>
                          </form>
                        
                      </motion.div>
                    )}
                    
                  </AnimatePresence>
                 
                  
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ModifyOperationsCountsModal