
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import HighchartsReact from "highcharts-react-official";
import HighchartsStock from 'highcharts/highstock';
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import { darkModeState } from '../../atoms/darkModeState';
import ModifySingleOperationCountModal from '../modals/assetmodals/assetOperationsModals/ModifySingleOperationCountModal';

highchartsMore(Highcharts);

function ScrapsBarChart({countArray, operation_uuid, dateCategories, hourlyGoal, height}) {
    const [showModifyOperationModel, setShowModifyOperationModel] = useState(false);
    const [clickedPointTimestamp, setClickedPointTimestamp] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        credits: {enabled: false},
        chart: {
            type: 'column',
            zoomType: 'x',
            backgroundColor:'transparent',
            // height:'110px',
        },
        time: {
            useUTC: false
          },
        title:{
            text:''
        },
    })
    const isDark = useRecoilValue(darkModeState); 


    useEffect(() => {
        if(dateCategories && dateCategories.length > 0 && countArray && countArray.length > 0){
            let red = 'rgba(220, 38, 38, 1)'
            let redLight = 'rgba(220, 38, 38,1)'
            let green = '#22c55e'
            let greenLight = 'rgba(34,197,94,0.5)'
            // let sky = '#0ea5e9'
            // let skyLight = 'rgba(14, 165, 233,0.5)'
            
            if(!isDark){
                red = 'rgba(220, 38, 38, 1)' 
                redLight = 'rgba(220, 38, 38,1)'
                green = '#4ade80'
                greenLight = 'rgba(74,222,128,0.5)'
                // sky = '#60a5fa'
                // skyLight = 'rgba(96, 165, 250,0.5)'
            }



            let [goodSeries, badSeries] = formatGoodBadOps(countArray)
            // console.log(`resetting Scrap Bar chart state ${operation_uuid}`)
            
            setChartOptions({
                credits: {enabled: false},
                chart: {
                    type: 'column',
                    zoomType: 'x',
                    backgroundColor:'transparent',
                    // height:'110px',
                },
                time: {
                    useUTC: false
                    },
                title:{
                    text:''
                },
                xAxis: {
                    // categories: ['8 AM', '','','','','','5 PM'],
                    categories: dateCategories,
                    lineWidth: 0, // Remove x-axis line
                    labels: {
                        // enabled:false,
                        style: {
                            color: isDark? '#9ca3af':'#6b7280',
                            // color: isDark? '#d1d5db':'#4b5563',
                            fontSize: 11
                        }
                    },
                },
                // yAxis: [{
                //     visible: false,
                //     // max: 10
                // }],
                yAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: true, // Hide y-axis labels
                        style: {
                            color: isDark? '#9ca3af':'#6b7280', // gray 400/500
                            fontSize: 11
                        }
                    },
                    gridLineWidth: 0, // Hide default grid lines
                    plotLines: [{
                    value: 0,
                    color: isDark? '#475569':'#4b5563',
                    width: 1,
                    zIndex: 4
                    }]
                },
                legend: {
                    enabled: false 
                },
                tooltip: {
                    // formatter: function () {
                    //     // The first returned item is the header, subsequent items are the
                    //     // points
                    //     let icon = ''
                    //     return ['<b>' + this.x + '</b>'].concat(
                    //         this.points ?
                    //             this.points.map(function (point) {
                    //                 return point.series.name + ': ' + point.y + icon;
                    //             }) : []
                    //     );
                    // },
                    formatter: function () {
                        // The first returned item is the header, subsequent items are the
                        // points
                        var tooltipText = '<b>' + this.x + '</b>';
                        this.points.forEach(point => {
                            tooltipText += '<br/>' + point.series.name + ': ' + point.y ;
                        });
                        return tooltipText;
                    },
                    shared: true
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        backgroundColor:'#F3F4F6',
                        dataLabels: {
                            enabled: false,
                            style: {
                                // fontSize: '18px',
                                color: isDark ? '#fff' :'#fff',
                                // color: isDark ? '#fff' :'rgba(75, 85, 99, 0.5)',
                            },
                        },
                        borderWidth:0
                    },
                    series: {
                        negativeColor: true,
                        borderRadius: {
                            radius: 5
                        },
                        dataLabels: {
                            enabled: true,
                            // format: toggleShowPercentData ? '{y}%' : '{y}h'
                        },
                        stacking: 'normal',
                        // point: {
                        //     events: {
                        //       click: (event) => {
                        //         setClickedPointTimestamp(event.point.id); // Store clicked point data
                        //         setShowModifyOperationModel(true); // Show model component
                        //       },
                        //     },
                        //   },
                    }
                },
                series: [{
                    name: 'Good',
                    data: goodSeries,
                },{
                    name: 'Bad',
                    data: badSeries,
                    negativeColor: {
                        linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
                        stops: [
                            [0, red],
                            [1, redLight]
                        ]
                      }
                }]
            })

        }

    }, [isDark, countArray, dateCategories])

    const handleCloseModal = () => {
        // console.log('closing modal')
        setShowModifyOperationModel(false)
    }

    const formatGoodBadOps = (seriesData) => {
        let goodSeriesData = []
        let badSeriesData = []
        if(seriesData){

            seriesData.map(opsTime => {
                let goodOps = opsTime.overridden_good_count ? opsTime.overridden_good_count : 0
                let badOps = opsTime.overridden_bad_count ? -opsTime.overridden_bad_count : 0
                
                let green = '#22c55e'
                // let greenLight = 'rgba(34,197,94,0.5)'
                let greenLight = '#22c55e'
                let sky = '#0ea5e9' //sky500
                let skyLight = 'rgba(14, 165, 233,1)'
                // let sky = '#0284c7' //sky600
                // let skyLight = 'rgba(2,132,199,1)'
                
                // if(!isDark){
                //     green = '#22c55e'
                //     greenLight = 'rgba(34,197,94,0.5)'
                // }
                if(hourlyGoal && goodOps < hourlyGoal ){
                    green = 'rgba(34,197,94,0.5)'
                    greenLight = 'rgba(34,197,94,0.5)'
                    sky = 'rgba(14, 165, 233,0.5)'
                    skyLight = 'rgba(14, 165, 233,0.5)'
                    // if(!isDark){
                    //     green = 'rgba(74,222,128,0.5)'
                    //     greenLight = 'rgba(74,222,128,0.2)'
                    // }

                }

                goodSeriesData.push({
                    y: goodOps,
                    id: opsTime.interval_timestamp,
                    color: {
                        linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
                        stops: [
                            [0, skyLight],
                            [1, sky]
                        ]
                      }
                })
                badSeriesData.push({
                    y: badOps,
                    id: opsTime.interval_timestamp
                })
            })
        }
        
        return [goodSeriesData, badSeriesData]
    }

    function formatAMPM(date) {
        let event = new Date(date)
        let currentTime = event.toLocaleTimeString('en-US', { hour: '2-digit'})
        currentTime = currentTime.replace(/\s/g, '')
        if(currentTime[0] == '0'){
            currentTime = currentTime.substring(1)
        }

        return currentTime
    }


  return (
    <div className=''>
        <HighchartsReact
            constructorType={"chart"}
            containerProps={{ style: { height: height, maxWidth:'100%'} }} 
            highcharts={HighchartsStock}
            options={chartOptions}               
        />
        {/* {showModifyOperationModel && clickedPointTimestamp && (
        <ModifySingleOperationCountModal btnType='hideBtn' remoteOpen={showModifyOperationModel} operation_uuid={operation_uuid} timestamp={clickedPointTimestamp} handleCloseModal={handleCloseModal} />
      )} */}
    </div>
  )
}

export default ScrapsBarChart

