export const colorPalletLight = [
    '#60a5fa',
    '#a855f7',
    '#0ea5e9', 
    '#4f46e5', 
    '#7c3aed', 
    '#f43f5e', 
    '#d946ef', 
    '#ec4899' 
];
export const colorPalletDark = [
    '#60a5fa',
    '#d8b4fe', 
    '#7dd3fc', 
    '#a5b4fc', 
    '#c4b5fd',
    '#fda4af',
    '#f0abfc', 
    '#f9a8d4',  
]