
import { useState } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../atoms/darkModeState'
import {globalSelectedOrganization} from '../atoms/orgStates'
import IoTFlowsLogoLightTransparent from "../images/IoTFlowsLogoLightTransparent.png";



function Nav() {
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    let location = useLocation();
    const [selectedTab, setSelectedTab] = useState('');
    const [isMobile, setIsMobile] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const navigationLinks = [
        // {
        //     title: 'Home',
        //     url:'/home'
        // },
        {
            title: 'Assets',
            // url:`/${selectedOrganization}/assets`
            url:`/${selectedOrganization}/assets?select=overview&group_by=department`,
            svg:
            // <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-6 h-6' stroke="currentColor" strokeWidth='1.2px' fill='none'  viewBox="0 0 24 24">
            //     <rect  x="6.4" y="5.76" width="10.01" height="12.45" rx="1.72" ry="1.72"/>
            //     <polyline  points="18.88 12.1 18.21 18.21 5.27 18.21 3.13 11.59 .82 11.59"/>
            //     <path  d="m22.45,12.51h-3.56v-2.15c0-.16.1-.29.26-.34.28-.08.79-.18,1.52-.18s1.25.1,1.52.18c.15.04.26.18.26.34v2.15Z"/>
            //     <polygon  points="18.88 12.51 19.58 13.42 23.14 13.42 22.45 12.51 18.88 12.51"/>
            //     <rect  x="8.65" y="8.35" width="5.51" height="4.34" rx=".76" ry=".76"/>
            //     <line  x1="19.12" y1="18.24" x2="2.77" y2="18.21"/>
            //     <line  x1="1.89" y1="13.42" x2=".99" y2="13.42"/>
            // </svg>
            <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-6 h-6' stroke="currentColor" strokeWidth='1.2px' fill='none'  viewBox="0 0 24 24">
            <rect  x="6.09" y="4.65" width="10.41" height="14.67" rx="1.79" ry="1.79"/>
            <polyline  points="18.64 12.96 17.94 19.32 4.91 19.32 3.34 12.43 .93 12.43"/>
            <path  d="m22.35,13.39h-3.7v-2.24c0-.16.11-.31.27-.35.29-.08.82-.19,1.59-.19s1.3.11,1.59.19c.16.04.27.19.27.35v2.24Z"/>
            <polygon  points="18.64 13.39 19.36 14.33 23.07 14.33 22.35 13.39 18.64 13.39"/>
            <rect  x="8.43" y="7.34" width="5.73" height="4.51" rx=".79" ry=".79"/>
            <line  x1="18.89" y1="19.35" x2="2.93" y2="19.32"/>
            <line  x1="2.05" y1="14.33" x2="1.11" y2="14.33"/>
            </svg>
            // <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
            //     <path d="M42-160v-72q0-33 17-62t47-44q51-26 115-44t141-18q77 0 141 18t115 44q30 15 47 44t17 62v72q0 17-11.5 28.5T642-120H82q-17 0-28.5-11.5T42-160Zm80-40h480v-32q0-11-5.5-20T582-266q-36-18-92.5-36T362-320q-71 0-127.5 18T142-266q-9 5-14.5 14t-5.5 20v32Zm240-240q-66 0-113-47t-47-113h-10q-9 0-14.5-5.5T172-620q0-9 5.5-14.5T192-640h10q0-45 22-81t58-57v38q0 9 5.5 14.5T302-720q9 0 14.5-5.5T322-740v-54q9-3 19-4.5t21-1.5q11 0 21 1.5t19 4.5v54q0 9 5.5 14.5T422-720q9 0 14.5-5.5T442-740v-38q36 21 58 57t22 81h10q9 0 14.5 5.5T552-620q0 9-5.5 14.5T532-600h-10q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T442-600H282q0 33 23.5 56.5T362-520Zm297 144-3-14q-6-2-11.5-4.5T634-402l-12 4q-7 2-13.5 0t-10.5-9l-4-7q-4-6-2.5-13t6.5-12l10-9v-24l-10-9q-5-5-6.5-12t2.5-13l4-7q4-7 10.5-9t13.5 0l12 4q4-4 10-7t12-5l3-14q2-7 7-11.5t12-4.5h8q7 0 12 4.5t7 11.5l3 14q6 2 12 5t10 7l12-4q7-2 13.5 0t10.5 9l4 7q4 6 2.5 13t-6.5 12l-10 9v24l10 9q5 5 6.5 12t-2.5 13l-4 7q-4 7-10.5 9t-13.5 0l-12-4q-5 5-10.5 7.5T708-390l-3 14q-2 7-7 11.5t-12 4.5h-8q-7 0-12-4.5t-7-11.5Zm23-54q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm68-152-4-20q-9-3-16.5-7.5T716-620l-21 7q-9 3-18-.5T663-625l-6-10q-5-8-3.5-17.5T663-669l17-15q-2-5-2-8v-16q0-3 2-8l-17-15q-8-7-9.5-16.5T657-765l6-10q5-8 14-11.5t18-.5l21 7q6-6 13.5-10.5T746-798l4-20q2-10 9.5-16t17.5-6h10q10 0 17.5 6t9.5 16l4 20q9 3 16.5 7.5T848-780l21-7q9-3 18 .5t14 11.5l6 10q5 8 3.5 17.5T901-731l-17 15q2 5 2 8v16q0 3-2 8l17 15q8 7 9.5 16.5T907-635l-6 10q-5 8-14 11.5t-18 .5l-21-7q-6 6-13.5 10.5T818-602l-4 20q-2 10-9.5 16t-17.5 6h-10q-10 0-17.5-6t-9.5-16Zm32-68q21 0 35.5-14.5T832-700q0-21-14.5-35.5T782-750q-21 0-35.5 14.5T732-700q0 21 14.5 35.5T782-650ZM362-200Z"/>
            // </svg>
        },
        {
            title: 'Production',
            url:`/${selectedOrganization}/production?select=parts_list`,
            svg:
            <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-6 h-6' stroke="currentColor" strokeWidth='1.2px' fill='none'  viewBox="0 0 24 24">
                <g>
                    <rect  x="1.17" y="13.22" width="21.66" height="6.88" rx="3.44" ry="3.44"/>
                    <g>
                    <circle  cx="4.21" cy="16.66" r=".38"/>
                    <circle  cx="8.1" cy="16.66" r=".38"/>
                    <circle  cx="12" cy="16.66" r=".38"/>
                    <circle  cx="15.9" cy="16.66" r=".38"/>
                    <circle  cx="19.79" cy="16.66" r=".38"/>
                    </g>
                </g>
                <g>
                    <rect  x="4.21" y="4.68" width="7.79" height="8.54"/>
                    <polygon  points="9.34 7.57 8.1 6.83 6.86 7.57 6.86 4.68 9.34 4.68 9.34 7.57"/>
                    <rect  x="12" y="4.68" width="7.79" height="8.54"/>
                    <polygon  points="17.14 7.57 15.9 6.83 14.66 7.57 14.66 4.68 17.14 4.68 17.14 7.57"/>
                </g>
            </svg>
            // <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-6 h-6' stroke="currentColor" strokeWidth='1.2px' fill='none'  viewBox="0 0 24 24">
            //     <g>
            //         <rect  x="1.17" y="13.22" width="21.66" height="4.89" rx="2.45" ry="2.45"/>
            //         <g>
            //         <circle  cx="4.21" cy="15.66" r=".38"/>
            //         <circle  cx="8.1" cy="15.66" r=".38"/>
            //         <circle  cx="12" cy="15.66" r=".38"/>
            //         <circle  cx="15.9" cy="15.66" r=".38"/>
            //         <circle  cx="19.79" cy="15.66" r=".38"/>
            //         </g>
            //     </g>
            //     <g>
            //         <rect  x="5.01" y="6.23" width="6.99" height="6.99"/>
            //         <polygon  points="9.62 9.72 8.51 8.83 7.39 9.72 7.39 6.23 9.62 6.23 9.62 9.72"/>
            //         <rect  x="12" y="6.23" width="6.99" height="6.99"/>
            //         <polygon  points="16.61 9.72 15.49 8.83 14.38 9.72 14.38 6.23 16.61 6.23 16.61 9.72"/>
            //     </g>
            // </svg>
            // <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
            //     <path d="M300-410v20q0 13 8.5 21.5T330-360q13 0 21.5-8.5T360-390v-100q0-13-8.5-21.5T330-520q-13 0-21.5 8.5T300-490v20h-30q-13 0-21.5 8.5T240-440q0 13 8.5 21.5T270-410h30Zm130 0h260q13 0 21.5-8.5T720-440q0-13-8.5-21.5T690-470H430q-13 0-21.5 8.5T400-440q0 13 8.5 21.5T430-410Zm230-160h30q13 0 21.5-8.5T720-600q0-13-8.5-21.5T690-630h-30v-20q0-13-8.5-21.5T630-680q-13 0-21.5 8.5T600-650v100q0 13 8.5 21.5T630-520q13 0 21.5-8.5T660-550v-20Zm-390 0h260q13 0 21.5-8.5T560-600q0-13-8.5-21.5T530-630H270q-13 0-21.5 8.5T240-600q0 13 8.5 21.5T270-570ZM160-200q-33 0-56.5-23.5T80-280v-480q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v480q0 33-23.5 56.5T800-200H640v40q0 17-11.5 28.5T600-120H360q-17 0-28.5-11.5T320-160v-40H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z"/>
            // </svg>
        },
        {
            title: 'Devices',
            url:`/${selectedOrganization}/devices?select=management`,
            svg:
            // <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-6 h-6' stroke="currentColor" strokeWidth='1.2px' fill='none'  viewBox="0 0 24 24">
            //     <circle class="cls-1" cx="12.36" cy="18.57" r="4.53"/>
            //     <rect class="cls-1" x="11.11" y="1.42" width="2.51" height="7.41" rx="1.26" ry="1.26"/>
            //     <line class="cls-1" x1="12.36" y1="14.04" x2="12.36" y2="8.83"/>
            // </svg>
            <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960"  fill="currentColor">
                <path d="M240-160q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm480 0q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM480-560q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Z"/>
            </svg>
        },
        // {
        //     title: 'Chats',
        //     url:`/${selectedOrganization}/chats`,
        //     svg: 
        //     <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-6 h-6' stroke="currentColor" strokeWidth='1.2px'   viewBox="0 0 24 24">
        //     <g>
        //         <line fill='none' x1="6.67" y1="6.56" x2="10.85" y2="6.56"/>
        //         <line fill='none' x1="6.67" y1="8.95" x2="10.85" y2="8.95"/>
        //     </g>
        //     <path fill='none' d="m16.83,7.47c0,.22-.01.43-.03.65-.15,1.43-.79,2.7-1.76,3.67-1.1,1.11-2.63,1.79-4.32,1.79h-6.67l-3.1,2.73V7.47C.95,4.09,3.69,1.36,7.06,1.36h3.66c3.38,0,6.11,2.73,6.11,6.11Z"/>
        //     <path fill='none'  d="m22.81,14.23v8.84l-3.1-2.73h-6.67c-1.69,0-3.22-.68-4.32-1.79-1.11-1.11-1.79-2.63-1.79-4.32,0-.22.01-.43.03-.65h3.76c1.69,0,3.22-.68,4.32-1.79.97-.97,1.61-2.24,1.76-3.67,3.33.05,6.01,2.76,6.01,6.11Z"/>
        //     </svg>
        //     // <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-6 h-6' stroke="currentColor" strokeWidth='1.2px' fill='none'  viewBox="0 0 24 24">
        //     //     <path  d="m15.57,9.66c0,1.37-.56,2.62-1.46,3.52-.9.9-2.15,1.46-3.52,1.46h-6.25l-3.1,2.75v-7.73c0-2.75,2.23-4.98,4.98-4.98h4.37c2.16,0,4,1.38,4.69,3.31.19.52.29,1.09.29,1.67Z"/>
        //     //     <path  d="m22.74,12.97v7.73l-3.1-2.75h-6.25c-1.38,0-2.62-.56-3.52-1.46-.52-.52-.92-1.15-1.17-1.85h1.9c1.38,0,2.62-.56,3.52-1.46.9-.9,1.46-2.15,1.46-3.52,0-.59-.1-1.15-.29-1.67h2.47c2.75,0,4.98,2.23,4.98,4.98Z"/>
        //     //     <line  x1="6.05" y1="8.38" x2="10.23" y2="8.38"/>
        //     //     <line  x1="6.05" y1="10.77" x2="10.23" y2="10.77"/>
        //     // </svg>
        //     // <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
        //     //     <path d="M280-240q-17 0-28.5-11.5T240-280v-80h520v-360h80q17 0 28.5 11.5T880-680v503q0 27-24.5 37.5T812-148l-92-92H280Zm-40-200-92 92q-19 19-43.5 8.5T80-377v-463q0-17 11.5-28.5T120-880h520q17 0 28.5 11.5T680-840v360q0 17-11.5 28.5T640-440H240Zm360-80v-280H160v280h440Zm-440 0v-280 280Z"/>
        //     // </svg>
        // },
        // {
        //     title: 'Conversations',
        //     url:`/${selectedOrganization}/conversations`,
        //     svg:
        //     <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-6 h-6' stroke="currentColor" strokeWidth='1.2px' fill='none'  viewBox="0 0 24 24">
        //         <g>
        //         <path  d="m10.22,19.1c0,.73-.3,1.4-.78,1.88-.48.48-1.14.78-1.88.78h-3.69c-1.46,0-2.65-1.19-2.65-2.65,0-1.24.5-2.37,1.32-3.18.28-.28.59-.52.93-.71.54.65,1.34,1.06,2.25,1.06s1.71-.41,2.25-1.06c.34.2.65.44.93.71.81.81,1.32,1.94,1.32,3.18Z"/>
        //         <path  d="m8.64,13.34c0,.71-.25,1.36-.67,1.86-.54.65-1.34,1.06-2.25,1.06s-1.71-.41-2.25-1.06c-.42-.51-.67-1.16-.67-1.86,0-1.61,1.31-2.92,2.92-2.92s2.92,1.31,2.92,2.92Z"/>
        //         </g>
        //         <g>
        //         <path  d="m21.62,20.72c0,.66-.27,1.25-.7,1.68-.43.43-1.02.7-1.68.7h-3.31c-1.31,0-2.38-1.06-2.38-2.38,0-1.11.45-2.12,1.18-2.85.25-.25.53-.46.83-.64.48.58,1.21.95,2.02.95s1.54-.37,2.02-.95c.31.18.59.39.83.64.73.73,1.18,1.74,1.18,2.85Z"/>
        //         <path  d="m20.21,15.55c0,.63-.23,1.22-.6,1.67-.48.58-1.21.95-2.02.95s-1.54-.37-2.02-.95c-.38-.45-.6-1.04-.6-1.67,0-1.45,1.17-2.62,2.62-2.62s2.62,1.17,2.62,2.62Z"/>
        //         </g>
        //         <g>
        //         <rect  x="9.97" y="1.04" width="8.6" height="9.77" rx=".66" ry=".66"/>
        //         <line  x1="14.26" y1="3.44" x2="16.28" y2="3.44"/>
        //         <line  x1="12.15" y1="3.44" x2="12.15" y2="3.44"/>
        //         <line  x1="14.26" y1="5.92" x2="16.28" y2="5.92"/>
        //         <line  x1="12.15" y1="5.92" x2="12.15" y2="5.92"/>
        //         <line  x1="14.26" y1="8.4" x2="16.28" y2="8.4"/>
        //         <line  x1="12.15" y1="8.4" x2="12.15" y2="8.4"/>
        //         </g>
        //     </svg>
        //     // <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
        //     //     <path d="M192-492 92-592q-12-12-12-28t12-28l100-100q12-12 28-12t28 12l100 100q12 12 12 28t-12 28L248-492q-12 12-28 12t-28-12ZM400-80q-17 0-28.5-11.5T360-120v-160q-50-4-99-11t-98-18q-17-4-27.5-19t-5.5-32q5-17 20.5-25t32.5-4q73 17 147.5 23t149.5 6q75 0 149.5-6T777-389q17-4 32.5 4t20.5 25q5 17-5.5 32T797-309q-49 11-98 18t-99 11v160q0 17-11.5 28.5T560-80H400ZM220-576l44-44-44-44-44 44 44 44Zm260-104q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0 280q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-360q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Zm191 260-46-80q-5-9-5-20t5-20l46-80q5-10 14-15t20-5h90q11 0 20 5t14 15l46 80q5 9 5 20t-5 20l-46 80q-5 10-14 15t-20 5h-90q-11 0-20-5t-14-15Zm57-60h44l22-40-22-40h-44l-22 40 22 40Zm-508-60Zm260-180Zm270 200Z"/>
        //     // </svg>
        // },
        // {
        //     title: 'Requests',
        //     url:`/${selectedOrganization}/requests`,
        //     svg:
        //     <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-6 h-6' stroke="currentColor" strokeWidth='1.2px' fill='none'  viewBox="0 0 24 24">
        //         <g>
        //         <path  d="m12,19.18c0,.88-.36,1.67-.93,2.25-.58.58-1.37.93-2.25.93h-4.42c-1.76,0-3.18-1.42-3.18-3.18,0-1.49.6-2.84,1.58-3.81.33-.33.71-.62,1.11-.86.64.77,1.61,1.27,2.7,1.27s2.05-.49,2.7-1.27c.41.24.78.52,1.11.86.98.98,1.58,2.32,1.58,3.81Z"/>
        //         <path  d="m10.11,12.28c0,.85-.3,1.63-.8,2.23-.64.77-1.61,1.27-2.7,1.27s-2.05-.49-2.7-1.27c-.5-.61-.8-1.38-.8-2.23,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5Z"/>
        //         </g>
        //         <g>
        //         <path  d="m22.43,6.89v2.85c0,.36-.29.65-.65.65h-9.73c-.36,0-.65-.29-.65-.65v-2.85h2.61c.42,0,.79.27.91.68l.09.31c.14.45.56.76,1.03.76h1.73c.47,0,.89-.31,1.03-.76l.09-.31c.12-.4.49-.68.91-.68h2.61Z"/>
        //         <path  d="m18.7,2.01h.72c.54,0,1.03.32,1.24.82l1.76,4.07h-2.61c-.42,0-.79.27-.91.68l-.09.31c-.14.45-.56.76-1.03.76h-1.73c-.47,0-.89-.31-1.03-.76l-.09-.31c-.12-.4-.49-.68-.91-.68h-2.61l1.76-4.07c.21-.49.7-.82,1.24-.82h.72"/>
        //         <polyline  points="18.31 5.02 16.92 6.42 15.52 5.02"/>
        //         <line  x1="16.92" y1="6.42" x2="16.92" y2=".98"/>
        //         </g>
        //     </svg>
        //     // <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
        //     //     <path strokeLinecap="round" strokeLinejoin="round" d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3" />
        //     // </svg>
        // },
        // {
        //     title: 'Work Orders',
        //     url:`/${selectedOrganization}/work-orders`,
        //     svg:
        //     <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-6 h-6' stroke="currentColor" strokeWidth='1.2px' fill='none'  viewBox="0 0 24 24">
        //         <path  d="m21.04,17.18c-.05-.27-.26-.48-.53-.52-.74-.11-1.25-.8-1.15-1.54.01-.1.04-.2.08-.3.1-.25.02-.54-.19-.72-.45-.38-.97-.68-1.52-.88-.26-.1-.55-.02-.72.2-.4.52-1.11.66-1.68.37-.08-.04-.15-.09-.22-.14-.09-.07-.16-.15-.23-.23-.17-.22-.46-.3-.72-.2-.51.19-.99.46-1.42.8-.22.18-.3.47-.2.74.28.69-.05,1.48-.74,1.76-.12.05-.25.08-.39.09-.28.03-.51.24-.56.52-.06.32-.1.64-.1.97,0,.27.02.54.07.81.01.07.04.14.07.21.1.18.28.31.49.33.74.07,1.29.73,1.22,1.47-.01.15-.05.3-.12.44-.12.26-.05.57.18.75.45.37.96.67,1.51.87.07.02.14.04.22.04.21,0,.4-.1.52-.27.25-.36.66-.58,1.11-.58.43,0,.83.2,1.09.55.17.23.48.32.75.21.5-.2.97-.48,1.38-.83.21-.18.29-.47.19-.73-.29-.69.03-1.48.72-1.77.12-.05.24-.08.36-.1.28-.04.5-.25.54-.53.06-.29.09-.58.09-.88,0-.31-.03-.62-.09-.92Zm-5.1,2.87c-.71,0-1.33-.38-1.67-.95-.18-.29-.28-.64-.28-1,0-.86.56-1.59,1.34-1.85.19-.06.4-.1.61-.1,1.08,0,1.95.87,1.95,1.95s-.87,1.95-1.95,1.95Z"/>
        //         <path  d="m15.33,4.52v9.29c-.08-.04-.15-.09-.22-.14-.09-.07-.16-.15-.23-.23-.17-.22-.46-.3-.72-.2-.51.19-.99.46-1.42.8-.22.18-.3.47-.2.74.28.69-.05,1.48-.74,1.76-.12.05-.25.08-.39.09-.28.03-.51.24-.56.52-.06.32-.1.64-.1.97,0,.27.02.54.07.81.01.07.04.14.07.21h-6.75c-.7,0-1.28-.57-1.28-1.28V4.52c0-.7.57-1.28,1.28-1.28h2.82v.79h4.27v-.79h2.82c.7,0,1.28.57,1.28,1.28Z"/>
        //         <path  d="m11.24,2.73v1.3h-4.27v-1.3c0-.3.24-.54.54-.54h3.2c.3,0,.54.24.54.54Z"/>
        //         <path  d="m9.1.98h0c.42,0,.76.34.76.76v.46h-1.52v-.46c0-.42.34-.76.76-.76Z"/>
        //         <g>
        //         <g>
        //             <line  x1="12.9" y1="7.43" x2="9.24" y2="7.43"/>
        //             <polyline  points="7.45 6.43 6.11 7.78 5.61 7.28"/>
        //         </g>
        //         <g>
        //             <line  x1="12.9" y1="11.36" x2="9.24" y2="11.36"/>
        //             <polyline  points="7.45 10.36 6.11 11.71 5.61 11.21"/>
        //         </g>
        //         <g>
        //             <line  x1="12.6" y1="15.3" x2="9.24" y2="15.3"/>
        //             <polyline  points="7.45 14.29 6.11 15.64 5.61 15.14"/>
        //         </g>
        //         </g>
        //     </svg>
        //     // <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
        //     // <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z" />
        //     // </svg>
        // },
        {
            title: 'Logs',
            url:`/${selectedOrganization}/logs`,
            svg:
            <svg xmlns="http://www.w3.org/2000/svg" strokeLinecap='round' strokeLinejoin='round' className='w-6 h-6' stroke="currentColor" fill='none'  viewBox="0 0 24 24">
                <path strokeWidth='1.2px' d="m5.73,15.4V3.06c0-.85.69-1.53,1.53-1.53h8.49l5.72,5.72v13.69c0,.85-.69,1.53-1.53,1.53H7.26c-.85,0-1.53-.69-1.53-1.53h0"/>
                <path strokeWidth='1.2px' d="m21.47,7.25h-4.19c-.85,0-1.53-.69-1.53-1.53V1.53l5.72,5.72Z"/>
                <g>
                    <line strokeWidth='1.2px' x1="9.45" y1="9.98" x2="17.75" y2="9.98"/>
                    <line strokeWidth='1.2px' x1="9.45" y1="12.71" x2="17.75" y2="12.71"/>
                </g>
                <rect strokeWidth='1.2px' x="2.53" y="15.4" width="13.22" height="5.54" rx=".98" ry=".98"/>
                <g>
                    <polyline  points="5.85 19.5 4.63 19.5 4.63 16.83"/>
                    <rect  x="7.99" y="16.93" width="1.47" height="2.58" rx=".73" ry=".73"/>
                    <path  d="m13.03,17.33c-.12-.24-.37-.4-.66-.4h0c-.41,0-.73.33-.73.73v1.11c0,.41.33.73.73.73h0c.41,0,.73-.33.73-.73v-.32h-.35"/>
                </g>
                </svg>
            // <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
            //     <path d="M240-80q-50 0-85-35t-35-85v-80q0-17 11.5-28.5T160-320h80v-536q0-7 6-9.5t11 2.5l29 29q6 6 14 6t14-6l32-32q6-6 14-6t14 6l32 32q6 6 14 6t14-6l32-32q6-6 14-6t14 6l32 32q6 6 14 6t14-6l32-32q6-6 14-6t14 6l32 32q6 6 14 6t14-6l32-32q6-6 14-6t14 6l32 32q6 6 14 6t14-6l29-29q5-5 11-2.5t6 9.5v656q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h320q17 0 28.5 11.5T680-280v80q0 17 11.5 28.5T720-160ZM400-680h160q17 0 28.5 11.5T600-640q0 17-11.5 28.5T560-600H400q-17 0-28.5-11.5T360-640q0-17 11.5-28.5T400-680Zm0 120h160q17 0 28.5 11.5T600-520q0 17-11.5 28.5T560-480H400q-17 0-28.5-11.5T360-520q0-17 11.5-28.5T400-560Zm280-40q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm-40 0v-80 80Z"/>
            //     </svg>
        },
        // {
            //     title: 'Dashboard',
            //     url:`/${selectedOrganization}/dashboards`
            // },
            // {
        //     title: 'Messaging',
        //     url:`/${selectedOrganization}/messaging`
        // },
        // {
        //     title: 'Reports',
        //     url:`/${selectedOrganization}/reports`
        // },
    ]

    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
    },[])

    useEffect(()=>{
        if(selectedOrganization){
            let currentPath = location.pathname.split('/')[2]
            if(currentPath && currentPath.split('?') && currentPath.split('?')[0]){
                currentPath = currentPath.split('?')[0]
            }
            for (let i = 0; i < Object.keys(navigationLinks).length; i++) {
                let navLink = navigationLinks[i].url.split('/')[2]
                if(navLink && navLink.split('?') && navLink.split('?')[0]){
                    navLink = navLink.split('?')[0]
                }
                if(currentPath === navLink){
                    setSelectedTab(navigationLinks[i])
                    break;
                 }else {
                     setSelectedTab(
                         {
                             title: currentPath,
                             url:`/${selectedOrganization}/${currentPath}`
                         },
                     )
                 }
              }
        }
    },[location, selectedOrganization])

    

    let nonActiveStyle = {
        backgroundColor:'transparent',
        borderRadius:'50px',
        color: isDark ? '#F3F4F6':'#4b5563',
    }

    let activeStyle = {
        backgroundColor:'transparent',
        borderRadius:'50px',
        color: isDark ? '#F3F4F6':'#4b5563',
        // backgroundColor:'#2563eb',
        // color:'#fff',
        // borderRadius:'50px',
        // color:'#ffff',
      };
      
    return (
        <nav className={`${isMobile && 'hidden'} relative flex border-b dark:border-slate-700 dark:bg-slate-900 bg-white`}>
            <div className='flex px-2 pt-1 overflow-x-scroll scrollbar-hide space-x-8 ml-4'>
                {navigationLinks.map(link => (
                    
                    <NavLink
                        key={link.url}
                        to={link.url}
                        className='relative font-medium outline-none text-[13px] pt-0.5 pb-2 '
                        onClick={()=> setSelectedTab(link)}
                        // style={({ isActive }) =>
                        // isActive ? activeStyle : nonActiveStyle
                        // }
                    >
                            <motion.div
                             whileHover={{ scale: 1 }}
                             whileTap={{ scale: 0.95 }}
                             key={link.url} className={`${link.title == selectedTab.title ? 'text-blue-600 dark:text-blue-500':' text-gray-600 dark:text-gray-100 hover:opacity-80'}  flex flex-col justify-center text-center items-center bg-transparent focus:rounded-full`}>
                                <div className='pb-0'>
                                {link.svg}
                                </div>
                                <span className='' >
                                    {link.title}
                                </span>
                            </motion.div>
                            {link.title == selectedTab.title ? (
                                <motion.div className="mainNavUnderline" layoutId="mainNavUnderline" />
                            ) : null }
                    </NavLink>
                    // <NavLink
                    //     key={link.url}
                    //     to={link.url}
                    //     className='relative font-medium outline-none text-sm cursor-pointer px-2 py-0.5 rounded-full '
                    //     onClick={()=> setSelectedTab(link)}
                    //     style={({ isActive }) =>
                    //     isActive ? activeStyle : nonActiveStyle
                    //     }
                    // >
                    //     <h1 key={link.url} className='bg-transparent hover:text-gray-500 focus:rounded-full '>
                    //         {link.title}
                    //     </h1>
                    //     {link.title == selectedTab.title ? (
                    //         <motion.div transition={{ duration: 0.20 }} className="mainUnderline" layoutId="mainUnderline">{link.title}</motion.div>
                    //     ) : null }
                        
                    // </NavLink>
                  
                    
                ))}
            </div>
            {/* <div className='absolute top-0 right-0 bg-gradient-to-l from-white dark:from-slate-900 h-9 w-1/12 sm:h-0 sm:w-0' /> */}
            {/* <div className='fixed z-50 bottom-2 right-4 hidden sm:flex '>
                <img src={IoTFlowsLogoLightTransparent} alt='IoTFlows Logo Transparent' className='w-24 md:w-28 ' />
            </div> */}
            
        </nav>
    )
}

export default Nav
