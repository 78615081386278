import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import HighchartsReact from "highcharts-react-official";
import HighchartsStock from 'highcharts/highstock';
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import { darkModeState } from '../../../atoms/darkModeState';

highchartsMore(Highcharts);

function StackedBarChartDowntimes({downtimeSummary, title}) {
    const [barChartOptions, setBarChartOptions] = useState({
        credits: {enabled: false},
        chart: {
            type: 'column',
            zoomType: 'x',
            backgroundColor:'transparent',
            // height:'110px',
        },
        time: {
            useUTC: false
          },
        title:{
            text:''
        },
    })
    const isDark = useRecoilValue(darkModeState);
    
    useEffect(() => {
        
        if(downtimeSummary && Object.keys(downtimeSummary).length > 0){
              
            setBarChartOptions({
                credits: {enabled: false},
            //     colors: ['#fda4af', '#f9a8d4', '#f0abfc', '#d8b4fe', '#c4b5fd', '#a5b4fc',
            //  '#93c5fd', '#7dd3fc', '#67e8f9', '#5eead4','#6ee7b7', '#86efac', '#bef264', '#fde047', '#fcd34d', '#fdba74', '#fca5a5', '#d6d3d1'],
                chart: {
                    type: 'column',
                    zoomType: 'x',
                    backgroundColor:'transparent',
                    // height:'110px',
                },
                time: {
                    useUTC: false
                  },
                  title:{
                    text: title,
                    align: 'left',
                    style: {
                        color: isDark ? '#e5e7eb' :'rgba(75, 85, 99, 1)',
                        fontWeight: 'semibold',
                        // fontSize: 24
                    }
                },
                xAxis: {
                    categories: downtimeSummary.categories,
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    }
                },
                yAxis: [{
                    title: {
                        text: ''
                    },
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    title: '', 
                    minorTickLength: 0,
                    tickLength: 0,
                    gridLineWidth: 0,
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    },
                }],
                legend: {
                    align: 'right',
                    verticalAlign: 'top',
                    layout: 'vertical',
                    // align: 'center',
                    // verticalAlign: 'bottom',
                    // layout: 'horizontal',
                    x: 0,
                    y: 0,
                    itemStyle: {
                        color: isDark? '#d1d5db':'#4b5563',
                        fontWeight: 'light'
                    },
                    navigation: {
                        activeColor: '#3E576F',
                        animation: true,
                        arrowSize: 12,
                        inactiveColor: '#CCC',
                        style: {
                            fontWeight: 'light',
                            color: isDark? '#d1d5db':'#4b5563',
                        }
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        // backgroundColor:'#F3F4F6',
                        dataLabels: {
                            enabled: true,
                            style: {
                                // fontSize: '18px',
                                color: isDark ? '#fff' :'#fff',
                                // color: isDark ? '#fff' :'rgba(75, 85, 99, 0.5)',
                            },
                        },
                        borderWidth:0
                    },
                    series: {
                        opacity: isDark? 0.9 : 1,
                        borderRadius: {
                            radius: 10
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{y}h'
                        },
                        stacking: 'normal'
                    }
                },
                series: downtimeSummary.series,
                // tooltip: {
                //     shared: true,
                //     // split: false,
                //     // enabled: true,
                //     formatter: function() {
                //         var tooltip = '<b>' + this.x + '</b><br/>',
                //             stackValues = {};
        
                //         // Iterate through all points in the shared tooltip
                //         this.points.forEach(function(point) {
                //             var stackName = point.series.options.stack;
                //             console.log('point', point)
                //             var color = point.series.color;
                //             if (!stackValues[stackName]) {
                //                 stackValues[stackName] = 0;
                //             }
                //             tooltip += `<div style="padding: 4px 0;color:${color}">` + point.series.name + ': ' + point.y + '</div><br/>';
                //             stackValues[stackName] += point.y;
                //         });
        
                //         // Add totals for each stack
                //         for (var stack in stackValues) {
                //             console.log('stackValues', stackValues)
                //             let sumOfValues = stackValues[stack].toFixed(2)
                //             tooltip += '<div style="padding: 4px 0;"><b>Total Downtime ' + this.x + ': ' + sumOfValues + '</b></div><br/>';
                //         }
        
                //         return tooltip;
                //     }
                //   },
            })
        }else{
            setBarChartOptions({
                credits: {enabled: false},
                chart: {
                    type: 'column',
                    zoomType: 'x',
                    backgroundColor:'transparent',
                    // height:'110px',
                },
                time: {
                    useUTC: false
                  },
                title:{
                    text:''
                },
                xAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    }
                },
                series: [],
            })
        }

    }, [isDark, downtimeSummary])

    
  return (
    <div className='mb-[-5px]'>
        <HighchartsReact
            constructorType={"chart"}
            containerProps={{ style: { height: '350px', maxWidth:'100%'} }} 
            highcharts={HighchartsStock}
            options={barChartOptions}               
        />
    </div>
  )
}

export default StackedBarChartDowntimes