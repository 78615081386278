import { Menu, Transition, MenuItem, MenuItems, MenuButton } from '@headlessui/react'
import {Fragment, useState} from 'react'
import { NavLink } from 'react-router-dom'
import SignoutModal from './modals/SignoutModal'
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'
import Avatar from '@mui/material/Avatar';


function ProfileDropdown({user}) {
    const [openSignoutModal, setOpenSignoutModal] = useState(false)
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);


    const handleOpenSignoutModal = () => {
        setOpenSignoutModal(false)
    }

    

  return (
    <div>
        <Menu>
            <MenuButton as="div" className='relative'>
                {/* Delete below if Avatar works */}
                {/* {user && user.user_image_url ? (
                    <img
                        src={user.user_image_url+'?'+ String(Math.random()) }
                        // onError={onError}
                        alt=""
                        className='h-9 rounded-full cursor-pointer'
                    />
                ):(
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-gray-500 rounded-full cursor-pointer">
                    <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                  </svg>
                  
                )} */}
                <Avatar 
                    alt=''
                    src={user.user_image_url+'?'+ String(Math.random()) } 
                    sx={{ width: 36, height: 36, cursor:'pointer' }}
                />
                
                

            </MenuButton>

            <Transition
            as={Fragment}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <MenuItems className="absolute right-2 origin-top-right divide-y dark:divide-slate-500 flex flex-col mt-1 w-[200px] bg-white dark:bg-slate-700 shadow-iotflows-lg rounded-lg outline-none ">
                    <div className="px-1 py-1 ">
                        <MenuItem as="div" className='flex flex-col px-1 py-2' disabled>
                            <span className="text-sm text-gray-700 dark:text-gray-300">{user.user_first_name}&nbsp;{user.user_last_name}</span>
                            <span className="text-xs text-gray-500 dark:text-gray-400">{user.user_email}</span>
                        </MenuItem>
                    </div>
                    {selectedOrganization && 
                    <div className="px-1 py-1">
                        <MenuItem>
                            {({ active }) => (
                                <NavLink
                                to={`/${selectedOrganization}/settings/user`}
                                className={`${
                                    active ? 'bg-slate-50 dark:bg-slate-500 text-gray-600 dark:text-gray-200' : 'text-blue-600 dark:text-blue-500'
                                    } flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                // style={({ isActive }) =>
                                //   isActive ? activeStyle : undefined
                                // }
                              >
                                Profile
                              </NavLink>
                            )}
                        </MenuItem>
                        <MenuItem>
                            {({ active }) => (
                                <NavLink
                                to={`/${selectedOrganization}/settings/billing`}
                                className={`${
                                    active ? 'bg-slate-50 dark:bg-slate-500 text-gray-600 dark:text-gray-200' : 'text-blue-600 dark:text-blue-500'
                                    } flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                // style={({ isActive }) =>
                                //   isActive ? activeStyle : undefined
                                // }
                              >
                                Billing
                              </NavLink>
                            )}
                        </MenuItem>
                        {user.user_username === 'm' || user.user_username === 'ulises' ? (
                            <MenuItem>
                                {({ active }) => (
                                    <NavLink
                                    to={`/${selectedOrganization}/chats`}
                                    className={`${
                                        active ? 'bg-slate-50 dark:bg-slate-500 text-gray-600 dark:text-gray-200' : 'text-blue-600 dark:text-blue-500'
                                        } flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                >
                                    Chats
                                </NavLink>
                                )}
                            </MenuItem>
                        ):null}
                        <MenuItem>
                            {({ active }) => (
                            <button
                                className={`${
                                active ? 'bg-slate-50 dark:bg-slate-500 text-gray-600 dark:text-gray-300' : 'text-blue-600 dark:text-blue-500'
                                } flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                onClick={() => setOpenSignoutModal(true)}
                            >
                                Sign out
                            </button>
                            )}
                        </MenuItem>
                    </div>}
                </MenuItems>
            </Transition>
        </Menu>
        <SignoutModal remoteOpenModal={openSignoutModal} handleOpenModal={handleOpenSignoutModal} />
    </div>
  )
}

export default ProfileDropdown