import { Dialog, Transition, TransitionChild, DialogTitle } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { PlusSmIcon, WifiIcon } from "@heroicons/react/solid"
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../../atoms/darkModeState'
import GeneralFormInput from '../../forms/GeneralFormInput';
import TagInput from '../../forms/TagInput';
import PartOperationsInputs from '../../forms/PartOperationsInputs';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { getData, postData, putData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import ImageZoomCrop from '../ImageZoomCrop';
import { toast } from 'react-hot-toast';
import { PencilIcon } from "@heroicons/react/solid"
import { LightTooltip } from '../../../styles/Tooltip';
import SenseAI from '../../../images/SenseAI.svg'
import MqttService from '../../../core/services/MqttService';
import KnownNetworkRow from './KnownNetworkRow';
import OptimizeLargeImage from '../../OptimizeLargeImage';
import SensorNetworks from './EditSensorTabComponents/SensorNetworks';
import AnalyzeSensorData from './EditSensorTabComponents/AnalyzeSensorData';

const subNav = [
  {
      name:'Calibration',
      url:'calibration'
  },
  {
      name:'Networks',
      url:'networks' 
  },
]


function EditSensorModal({btnType, sensor, getSensorList, remoteOpen, handleCloseModal}) {
  const isDark = useRecoilValue(darkModeState);
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
  const [isOpen, setIsOpen] = useState(false)
  const [wifiRssi, setWifiRssi] = useState(null)
  const [isWifiConnected, setIsWifiConnected] = useState(false)
  const [selectedSubNav, setSelectedSubNav] = useState('calibration')
  const [selectedTab, setSelectedTab] = useState(subNav[0])

  
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    // Make sure that recording is stopped
      postData(apiLink + '/v1/nodes/' + sensor.node_uuid + '/calibration_request',
      { "should_publish_calibration": false})
          .then(async response => {
          const data = await response.json();
          if(response.ok){
              // toast.success('Stopped Publishing Data')\
              getSensorList()
          }
          })

      setIsOpen(false)
      if(handleCloseModal){
        handleCloseModal()
      }
  }

  useEffect(() => {
    if(remoteOpen){
      openModal()
    }

  }, [remoteOpen])

  useEffect(() =>{
      setSelectedSubNav(selectedTab.url)
  },[selectedTab])

  useEffect(() => {
    if(sensor.node_is_online){
      if(sensor && sensor.node_vitals && sensor.node_vitals.wifi_rssi){
        
        let rssi = parseInt(sensor.node_vitals.wifi_rssi)
        setWifiRssi(rssi)
        setIsWifiConnected(true)
      }else{
        setIsWifiConnected(false)
      }
    }else{
      setIsWifiConnected(false)
    }

  },[])






  return (
    <>
      <div className="flex items-center justify-center">
        {btnType ==='standardBtn' &&
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain disabled:text-gray-500' onClick={openModal}>
              <PlusSmIcon className='btnMainIcon' />
              <span className='btnMainText'>Edit Sensor</span>
          </motion.button>
          }
        {btnType ==='inlineIconBtn' &&
          <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto disabled:text-gray-500`} onClick={openModal}>
            <PencilIcon className={`w-5 h-5`}/>
          </button>
          }
        {btnType ==='tableBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2 disabled:text-gray-500' onClick={openModal} >
            <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
            <span className=' text-blue-600'>Edit</span>
        </motion.button>
          }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          } 
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-[80%] transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <DialogTitle
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Edit Sensor
                  </DialogTitle>
                  <AnimatePresence mode='wait'>
                    <motion.div
                          key='sensorSection'
                          initial={{ y: 10, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                        > 
                          <div className='p-4'>
                            <div className={`flex flex-col xl:flex-row`}>
                              <div className={`flex mb-4 xl:mb-0 pr-6 mr-6 xl:border-r-[0.1em] border-dotted ${isDark?' border-gray-600':'border-gray-300'}`}>
                                <div className={`ml-2 min-w-[75px]`}>
                                    <img src={SenseAI} alt={sensor.node_name} loading={'lazy'} className='h-32' />
                                </div>
                                <div className='flex grow pl-3'>
                                  <div className='mr-auto'>
                                    <div className='flex pt-2 '>
                                          <div className='w-6 h-6'>
                                            {!isWifiConnected && 
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-6 h-6 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                                              <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
                                            </svg>
                                            }
                                            {isWifiConnected && wifiRssi > -50 &&
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-6 h-6 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                                              <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"/>
                                              <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"/>
                                            </svg>
                                            }
                                            {isWifiConnected && wifiRssi > -60 && wifiRssi <= -50 &&
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-6 h-6 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                                              <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z"/>
                                            </svg>
                                            }
                                            {isWifiConnected && wifiRssi <= -60 &&
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-6 h-6 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                                                <path d="M11.046 10.454c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.708-.707z"/>
                                              </svg>
                                            }
                                              {/* <WifiIcon className={`w-6 h-6 ${isDark? 'text-gray-400' : 'text-gray-400'}`} /> */}
                                              
                                          </div>
                                          <div className='ml-6'>
                                              {sensor.node_is_online ? (
                                                  <div className="relative">
                                                      <div className="w-4 h-4 bg-transparent"></div>
                                                      <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600 animate-ping"></div>
                                                      <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600"></div>
                                                  </div>
                                              ):(
                                                  <div className="relative">
                                                      <div className="w-4 h-4 bg-transparent"></div>
                                                      <div className={`absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full ${isDark? 'bg-gray-400' : 'bg-gray-600'}`}></div>
                                                      <div className={`absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full ${isDark? 'bg-gray-400' : 'bg-gray-600'}`}></div>
                                                  </div>
                                              )}
                                          </div>
                                      </div>
                                    <div className={`text-left leading-6 truncate text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>Name: <span className={`text-base ${isDark? 'text-white' : 'text-gray-600'}`}> {sensor.node_name}</span></div>
                                    <div className={`text-left leading-6 text-wrap text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'} `}>Id: <span className={`text-base ${isDark? 'text-white' : 'text-gray-600'}`}> {sensor.node_uuid}</span></div>
                                    {sensor && sensor.node_vitals && sensor.node_vitals.wifi_ssid && 
                                      <div className={`text-left leading-6 truncate text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>WiFi SSID: <span className={`text-base ${isDark? 'text-white' : 'text-gray-600'}`}> {sensor.node_vitals.wifi_ssid}</span></div>
                                    }
                                    {sensor && sensor.node_vitals && sensor.node_vitals.wifi_local_ip && 
                                      <div className={`text-left leading-6 truncate text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>IP Address: <span className={`text-base font-medium ${isDark? 'text-white' : 'text-gray-600'}`}> {sensor.node_vitals.wifi_local_ip}</span></div>
                                    }
                                    {sensor && sensor.node_firmware_version  && 
                                      <div className={`text-left leading-6 truncate text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>Firmware Version: <span className={`text-base font-medium ${isDark? 'text-white' : 'text-gray-600'}`}> {sensor.node_firmware_version}</span></div>
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className='flex items-center' >
                                <div className={`w-fit border-4 rounded-full bg-white`}>
                                    <OptimizeLargeImage source={sensor.asset_custom_picture_url  ? sensor.asset_custom_picture_url : sensor.machine_picture_url} alt={sensor.machine_name} size='xl' />
                                </div>
                                  <div className='flex flex-col ml-2' >
                                    <div className={`text-left leading-6 truncate text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>Machine Name: <span className={`text-base ${isDark? 'text-white' : 'text-gray-600'}`}>{sensor.asset_custom_name  ? sensor.asset_custom_name :sensor.machine_name}</span></div>
                                    <div className={`text-left leading-6 truncate text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>Machine Id: <span className={`text-base ${isDark? 'text-white' : 'text-gray-600'}`}>{sensor.asset_custom_identifier ? sensor.asset_custom_identifier : sensor.asset_uuid}</span></div>
                                  </div>
                              </div>
                            </div>
                            <div className='flex flex-col' >


                              {/* ************This is where the navigation is going to be now */}
                              <div className='flex w-full mt-3 px-2'>
                                  <nav>
                                      <div className='flex overflow-x-scroll scrollbar-hide space-x-2 md:space-x-3 border-b border-blue-600 w-fit'>
                                      {subNav.map((item) => (
                                          <div
                                          key={item.url}
                                          className='relative pt-0.5 pb-1.5'
                                          onClick={() => setSelectedTab(item)}
                                          >
                                          <span className={`${item.url === selectedTab.url ? 'font-medium':' font-base'} mr-1 pb-1 ${isDark? 'text-gray-100 hover:text-gray-300' : 'text-gray-600 hover:text-gray-900 '} outline-none text-base cursor-pointer bg-transparent whitespace-nowrap`}>{`${item.name}`}</span>
                                          {item === selectedTab ? (
                                              <motion.div className="underline" layoutId="underline" />
                                          ) : null}
                                          </div>
                                      ))}
                                      </div>
                                  </nav>
                              </div>
                                {sensor &&
                                  <div className='px-2'>
                                      {subLinkPage(selectedSubNav, sensor, getSensorList)}
                                  </div>
                                }
                              {/* ************This is where the navigation is going to be now */}
                            </div>
                          </div>

                          <form >
                            <div className={`flex p-4 px-5 items-center mt-2 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                              <button
                                type="button"
                                className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                onClick={closeModal}
                              >
                                Cancel
                              </button>
                              {/* <button
                                type="submit"
                                className={`relative ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                onClick={submitEditedSensitivity}
                                disabled={loading }
                              >
                                Edit Sensor
                                {loading && 
                                    <div >
                                        <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                        </svg>
                                    </div>
                                  }
                              </button> */}
                            </div>
                          </form>
                      </motion.div>
                  </AnimatePresence>
                 
                  
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default EditSensorModal


const subLinkPage = (link,sensor, getSensorList) => {

  const allLinks = {
    calibration: { link: "calibration", content: <AnalyzeSensorData sensor={sensor} getSensorList={getSensorList} /> },
    networks: { link: "networks", content: <SensorNetworks sensor={sensor} getSensorList={getSensorList} /> },
      // history: { link: "history", content: <SelectedAssetPageHistoryTab /> },
      // insights: { link: "insights", content: <div className='text-[128px]'>🧀 🥬</div> },
  };

    return (
      <AnimatePresence mode='wait'>
      <motion.div
      key={allLinks[link].link}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {allLinks[link].content}
      </motion.div>
    </AnimatePresence>
  )  

          
}