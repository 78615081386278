import { useEffect, useState } from 'react'
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";

function ProgressGauge({stringValue, unit, numericValue, goalValue, thresholdValue, currentCount}) {
    const [pieChartOptions, setPieChartOptions] = useState({
        chart: {
            type: 'solidgauge',
            height: '100%',
            backgroundColor: 'transparent',
            reflow: true
          },
          time: {
            useUTC: false
          },
          colors: ['#dc2626','#f87171','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
          // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
          credits: {enabled: false},
          title: null,
          exporting: {
            enabled: false
          },
          pane: {
            startAngle: 0,
            endAngle: 360,
            background: [{ // Track for Move
                outerRadius: '110%',
                innerRadius: '75%',
                backgroundColor: '#f87171',
                borderWidth: 0
            }]
        },
          series: [{
            name: unit,
            data: [{
                color: '#dc2626',
                radius: '110%',
                innerRadius: '75%',
                y: 0
            }]
          },
          ],
          plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true,
            },
          },
          yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: []
        },
          legend: {
            itemDistance:0,
          },
          tooltip: {
            pointFormat: '{series.name}: {point.y}'
          }
        
    })
    const [goalPieChartOptions, setGoalPieChartOptions] = useState({
        chart: {
            type: 'solidgauge',
            height: '100%',
            backgroundColor: 'transparent',
            reflow: true
          },
        time: {
          useUTC: false
        },
        colors: ['#16a34a','#4ade80','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
        // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
        credits: {enabled: false},
        title: null,
        exporting: {
          enabled: false
        },
        pane: {
          startAngle: 0,
          endAngle: 360,
          background: [{ // Track for Move
              outerRadius: '110%',
              innerRadius: '75%',
              backgroundColor: 'transparent',
              borderWidth: 0
          }]
      },
        series: [{
          name: 'Goal',
          data: [{
              color: 'transparent',
              radius: '100%',
              innerRadius: '85%',
              y: 0.1
          }]
        },
        ],
        plotOptions: {
          solidgauge: {
              dataLabels: {
                  enabled: false
              },
              linecap: 'round',
              stickyTracking: false,
              rounded: true,
          },
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: []
      },
        legend: {
          itemDistance:0,
        },
        tooltip: {
          pointFormat: '{series.name}: '
        }
        
    })

    useEffect(() => {
      // NumericValue is a percentage for the circle to be populated
      if(numericValue){
        let primaryColor = '#dc2626'
        let secondaryColor = '#f87171'
        if(currentCount){
          if(currentCount >= thresholdValue){
            primaryColor = '#16a34a'
            secondaryColor = '#4ade80'
          }
        }else{
          if(numericValue >= thresholdValue){
            primaryColor = '#16a34a'
            secondaryColor = '#4ade80'
          }
        }
        

        setPieChartOptions({
          chart: {
            type: 'solidgauge',
            height: '100%',
            backgroundColor: 'transparent',
            reflow: true
          },
          time: {
            useUTC: false
          },
          colors: [primaryColor,secondaryColor,'rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
          // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
          credits: {enabled: false},
          title: null,
          exporting: {
            enabled: false
          },
          pane: {
            startAngle: 0,
            endAngle: 360,
            background: [{ // Track for Move
                outerRadius: '110%',
                innerRadius: '75%',
                backgroundColor: secondaryColor,
                borderWidth: 0
            }]
        },
          series: [{
            name: unit,
            data: [{
                color: primaryColor,
                radius: '110%',
                innerRadius: '75%',
                y: numericValue
            }]
          },
          ],
          plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true,
            },
          },
          yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: []
        },
          legend: {
            itemDistance:0,
          },
          tooltip: {
            pointFormat: '{series.name}: {point.y}'
          }
        })

      }

    },[numericValue, goalValue])


    useEffect(() => {
      if(goalValue){

        let goalPos = goalValue*360
        let currentPartGoal = Math.floor(thresholdValue)
        setGoalPieChartOptions({
          chart: {
            type: 'solidgauge',
            height: '100%',
            backgroundColor: 'transparent',
            reflow: true
          },
          time: {
            useUTC: false
          },
          colors: ['#16a34a','#4ade80','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
          // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
          credits: {enabled: false},
          title: null,
          exporting: {
            enabled: false
          },
          pane: {
            startAngle: goalPos,
            endAngle: 360,
            background: [{ // Track for Move
                outerRadius: '110%',
                innerRadius: '75%',
                backgroundColor: 'transparent',
                borderWidth: 0
            }]
        },
          series: [{
            name: 'Goal',
            data: [{
                color: 'rgba(255,255,255, 1)',
                radius: '100%',
                innerRadius: '85%',
                y: 0.1
            }]
          },
          ],
          plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true,
            },
          },
          yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: []
        },
          legend: {
            itemDistance:0,
          },
          tooltip: {
            pointFormat: `Current {series.name}: ${currentPartGoal}`
          }
        })

      }

    },[goalValue, thresholdValue])

    

    var centerValue = stringValue
    if(typeof stringValue == 'number'){
      if(stringValue > 1000000){
        centerValue = stringValue/1000000
        centerValue = centerValue.toFixed(1)
        centerValue = centerValue+ 'M'
      }else if(stringValue > 100000){
        centerValue = stringValue/1000
        centerValue = centerValue.toFixed(0)
        centerValue = centerValue+ 'k'
      }else if(stringValue > 10000){
        centerValue = stringValue/1000
        centerValue = centerValue.toFixed(1)
        centerValue = centerValue+ 'k'
      }

    }


    

    

    return (
        <div className='relative w-[100px] h-20 py-1 pr-1 '>
              <div className='absolute top-[25%] w-full text-center'>
                  <span className='text-lg xl:text-xl text-gray-600 dark:text-white font-medium' >{centerValue}</span>
                  {/* <span className='text-lg text-gray-600 dark:text-white font-bold' >{stringValue}</span> */}
              </div>
              <div  className={`absolute top-[45%] w-full text-center`} >
                  <span className='text-sm text-gray-400 dark:text-white font-medium' >{unit}</span>
              </div>
              <div className='mt-[-12px]'>
                  <HighchartsReact
                      highcharts={Highcharts}
                      containerProps={{ style: { height: 100, width:100} }} 
                      constructorType={'chart'}
                      options={pieChartOptions}
                  />
                  <div className='absolute top-[-10%]'>
                      <HighchartsReact
                          highcharts={Highcharts}
                          containerProps={{ style: { height: 100, width:100} }} 
                          constructorType={'chart'}
                          options={goalPieChartOptions}
                      />
                  </div>
              </div>
        </div>
    )
}

export default ProgressGauge
