import { XCircleIcon } from '@heroicons/react/solid'
import React from 'react'

function TodoCard({todo, index, id, innerRef, draggableProps, dragHandleProps}) {
  return (
    <div
        className='bg-white rounded-md space-y-2 drop-shadow-md'
        {...draggableProps}
        {...dragHandleProps}
        ref={innerRef}
    >
        <div className='flex justify-between items-center p-5'>
            <p>{todo.title}</p>
            <button className='text-red-500 hover:text-red-600'>
                <XCircleIcon className='ml-5 h-8 w-8' />
            </button>
        </div>
    </div>
  )
}

export default TodoCard