import { motion, AnimatePresence } from "framer-motion";
import MessagesSection from "./MessagesSection";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import InfoSection from "./InfoSection";
import CreateNewChatSection from "./CreateNewChatSection";
import { apiLink } from "../../ApiCalls/ApisVariables";
import { getData } from "../../ApiCalls/DataApis";
import { darkModeState } from "../../atoms/darkModeState";
import { useRecoilValue } from "recoil";
import {colorPalletLight, colorPalletDark} from '../../styles/chatColorPallet'
import { insertMessageByDate, updateOrInsertMessageByDate, removeMessage } from "../../utils/MessageDataSorting";
import PinnedMessagesListSection from "./PinnedMessagesListSection";

function ChatDetails({chatList, getChatList, userInfo, mqttMessage,allEmojis, interactionsEmojis, chatUnreadDic, setChatUnreadDic, leftColumnWidth, globalSearchMessage, setGlobalSearchMessage}) {
    const [selectedSubNav, setSelectedSubNav] = useState('messages')
    const [selectedSubSection, setSelectedSubSection] = useState('')
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentConvo, setCurrentConvo] = useState([])
    const [currentChatId, setCurrentChatId] = useState('')
    const [messages, setMessages] = useState({})
    const [isGroup, setIsGroup] = useState(false)
    const [participants, setParticipants] = useState([])
    const [chatInfo, setChatInfo] = useState([])
    const [loadNewData, setLoadNewData] = useState(false)
    const isDark = useRecoilValue(darkModeState)
    const [color, setColor] = useState('')
    const [loadingChatInfo, setLoadingChatInfo] = useState(false)
    const [loadingMembers, setLoadingMembers] = useState(false)
    const [loadingMessages, setLoadingMessages] = useState(false)
    const [showMessageData, setShowMessageData] = useState(true)
    
    
    

    useEffect(() => {

      let currentSearch = location.search
      setParticipants([])
      setChatInfo([])
      setMessages({})
      setShowMessageData(false)

      if(currentSearch){

        setLoadNewData(true)
        // Pulling to see how cards should be grouped
        let section = searchParams.get('section')
        let subSection = searchParams.get('sub_section')
        let selectedChatId = searchParams.get('chatId')


        setSelectedSubSection(subSection)
        setSelectedSubNav(section)
        setCurrentChatId(selectedChatId)

        if(selectedChatId){
          
            setLoadingChatInfo(true)
            getData(apiLink + '/v1/chats/' + selectedChatId)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                  setChatInfo(data.data[0])
                  // console.log('Chat info',data.data[0])
                  setLoadingChatInfo(false)
                }
              }
            })
          

          
            setLoadingMembers(true)
            getData(apiLink + '/v1/chats/' + selectedChatId + '/members')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data && data.data[0]){
                  // console.log('Members',data.data)
                  setParticipants(data.data[0].members)
                  setLoadingMembers(false)
                }
              }
            })
          

            
            setLoadingMessages(true)
            getData(apiLink + '/v1/chats/' + selectedChatId + '/messages')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                  // console.log('Main data', data)
                  // console.log('Messages data', data.data)
                  // console.log('Messages Length', Object.keys(data.data).length)
                  setMessages(data.data)
                  // setMessages(data.messages_array)
                  setLoadingMessages(false)
                }
              }
            })
          
          
        }
      }else {
        setCurrentChatId('')
        setSelectedSubSection('')
        setSelectedSubNav('')
        setMessages({})
      }

    },[location])


    useEffect(() => {
      if(!loadingMessages && !loadingMembers && !loadingChatInfo){
        setShowMessageData(true)
      }else{
        setShowMessageData(false)
      }

    }, [loadingMessages, loadingMembers, loadingChatInfo])

    
    useEffect(() => {
      if(mqttMessage){
        let selectedChatId = searchParams.get('chatId')
        if(mqttMessage && mqttMessage.message && selectedChatId === mqttMessage.message.chat_uuid){
          
          if(mqttMessage.action === 'delete_message'){
            const updatedMessages = removeMessage(messages, mqttMessage.message)
            setMessages({...updatedMessages})
            
          }else{
            
            // console.log('mqttMessage', mqttMessage)
            const updatedMessages = updateOrInsertMessageByDate(messages, mqttMessage.message)
            // console.log('updatedMessages', updatedMessages)
            setMessages({...updatedMessages})

          }
        }
      }

    },[mqttMessage])

    useEffect(() => {
      let selectedChatId = searchParams.get('chatId')
      // I need to make sure that all the states are properly updated before rending anything
      if(selectedChatId == currentChatId){
        if(participants && Object.keys(participants).length > 0 && chatInfo && Object.keys(chatInfo).length > 0){
          setLoadNewData(false)
        }
      }else{
        setLoadNewData(true)
      }

    }, [participants, chatInfo, currentChatId])

    useEffect(() => {
      let selectedChatId = searchParams.get('chatId')
      if(selectedChatId == currentChatId){
        for (let i = 0; i < Object.keys(chatList).length  ; i++) {
          if (chatList[i].chat_uuid == selectedChatId) {
            if(isDark){
              let arrayLength = colorPalletDark.length;
              let adjustedIndex = i % arrayLength;
              setColor(colorPalletDark[adjustedIndex])
              
  
            }else{
                let arrayLength = colorPalletLight.length;
                let adjustedIndex = i % arrayLength;
                setColor(colorPalletLight[adjustedIndex])
            }

            
          }
        }
      }

    },[isDark, currentChatId, chatList])

    
  return (
    <div className='overflow-y-scroll scrollbar-hide'>
        {selectedSubSection == 'create_new_chat' && 
            subLinkPage('createNewChat',messages, participants, userInfo, chatInfo, getChatList, color, mqttMessage,allEmojis, interactionsEmojis, setParticipants, setMessages, chatUnreadDic, setChatUnreadDic, leftColumnWidth, globalSearchMessage, setGlobalSearchMessage)
        }
        {selectedSubSection != 'create_new_chat' && showMessageData && !loadNewData &&  selectedSubNav && participants && Object.keys(participants).length > 0 && chatInfo && Object.keys(chatInfo).length > 0 &&
            subLinkPage(selectedSubNav,messages, participants, userInfo, chatInfo, getChatList, color, mqttMessage,allEmojis, interactionsEmojis, setParticipants, setMessages, chatUnreadDic, setChatUnreadDic, leftColumnWidth, globalSearchMessage, setGlobalSearchMessage)
        }
        {!selectedSubSection && !selectedSubNav && !currentChatId &&
          <div className='flex justify-center items-center mt-20'>
            <div className='px-4 py-2 bg-slate-100 dark:bg-slate-700 text-gray-500 dark:text-gray-300 rounded-xl'>
              Select chat to start messaging
            </div>
          </div>
        }
    </div>
  )
}

export default ChatDetails


const subLinkPage = (link, messages, participants, userInfo, chatInfo, getChatList, color, mqttMessage,allEmojis, interactionsEmojis, setParticipants, setMessages, chatUnreadDic, setChatUnreadDic, leftColumnWidth, globalSearchMessage, setGlobalSearchMessage) => {
  
    const allLinks = {
        messages: { link: "messages", content: <MessagesSection messages={messages} participants={participants} userInfo={userInfo} chatInfo={chatInfo} color={color} getChatList={getChatList} mqttMessage={mqttMessage} allEmojis={allEmojis} interactionsEmojis={interactionsEmojis} setMessages={setMessages} chatUnreadDic={chatUnreadDic} setChatUnreadDic={setChatUnreadDic} leftColumnWidth={leftColumnWidth} globalSearchMessage={globalSearchMessage} setGlobalSearchMessage={setGlobalSearchMessage} /> },
        info: { link: "info", content: <InfoSection  participants={participants} chatInfo={chatInfo} userInfo={userInfo} color={color} setParticipants={setParticipants} /> },
        createNewChat: { link: "createNewChat", content: <CreateNewChatSection  getChatList={getChatList} /> },
        pinnedMessagesList: { link: "pinnedMessagesList", content: <PinnedMessagesListSection  getChatList={getChatList} /> },
    };

      return (
        <AnimatePresence mode='wait'>
        <motion.div
        key={allLinks[link].link}
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {allLinks[link].content}
        </motion.div>
      </AnimatePresence>
    )  

            
}