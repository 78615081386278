import { useSearchParams } from 'react-router-dom'

function InfoSectionHeader({setIsEditingGroup}) {
    const [searchParams, setSearchParams] = useSearchParams()

    const handleNavToMessages = () => {
        let select = searchParams.getAll('select')
        let chatId = searchParams.getAll('chatId')

        setSearchParams({select,chatId, section: 'messages'})
    }
  return (
    <div className='border-b-[0.01em] border-slate-200 dark:border-slate-600 px-2 py-1.5 bg-white dark:bg-slate-900'>
        <div className='grid grid-cols-3 '>
            <ol className='flex '>
                <li className='breadcrumbsNav flex items-center' onClick={handleNavToMessages} >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    </div>
                    <span className='text-sm'>Back</span>
                </li>
            </ol>
            <div className='text-gray-600 dark:text-gray-300 text-center'>Info</div>
            <button 
                className='breadcrumbsNav text-right text-sm'
                onClick={() => setIsEditingGroup(true)}
            >
                Edit
            </button>
        </div>
    </div>
  )
}

export default InfoSectionHeader